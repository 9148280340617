<template>
<b-row class="no-gutters">
        <!--<modalImpDisc v-if="userData.isImpDiscussionClicked"></modalImpDisc>-->

  <b-col cols="12">
    <codHeader v-bind:pageTitle="pageTitle" v-bind:pageNumber="pageNumber"></codHeader>
  </b-col>
  <b-col class="page-background-gradient" cols="12">
    <b-col class="page-content lifestyle reduce-padding" cols="12">

      <b-row class="no-gutters">
        
        <!--top row-->
        <b-row v-if="!displayWeight&&!displayExercise" class="no-gutters top-row-bg" ref="toprow">
          <b-row class="top-txt-row no-gutters">
            <div class="main-heading">
              Compare Your Patient’s Current and Target A1C levels.
            </div>

            <div class="sub-text">
              Lifestyle changes may lower blood sugar. <span class="double-spacing"></span>They can also help your patient reach their target level and identify appropriate goals. <span class="double-spacing"></span>A1C is a good indicator of average
              blood sugar level over a 3 month period.
            </div>

          </b-row>
        </b-row>
        
        <!--blue section-->
        <b-row class="no-gutters blue-sec-row" :class="{'btn-clicked':displayWeight||displayExercise}">
        <div class="upper-main-row" :class="{'btn-clicked':displayWeight||displayExercise}">
        
          <!--left space-->
          <div class="left-space-div">
            
             <div v-if="!displayWeight&&!displayExercise" class=" sm-screen">
            <div @click="isInfoClicked(); scrollToInfoNav();" class="info-icon "></div>
          </div>
          
             <div v-if="displayWeight||displayExercise" class=" sm-screen">
            <div @click="isInfoClicked2(); scrollToInfoNav();" class="info-icon"></div>
          </div>
          </div>
          
          <!--center blue boxes section-->
          
          <!--blue button box-->
          <div v-if="!displayWeight&&!displayExercise" class="button-box-div">
              <b-col cols="12" :class="{'both-disabled':userData.isWeightNormal&&userData.risks.exercise==='Yes'}" class="reduce-padding button-box">
                <div :class="{'both-disabled':userData.isWeightNormal&&userData.risks.exercise==='Yes'}" class="button-heading">
                  SELECT WEIGHT LOSS OR EXERCISE:
                </div>
                <b-row class="no-gutters buttons-row">
                  <div class="btn-col">
                    <div class="btn-divs">
                      <button v-if="!userData.isWeightNormal" class="button-lifestyle" @click="displayWeight=true;setA1cChange();handleResize();">WEIGHT LOSS</button>
                      <button v-if="userData.isWeightNormal && userData.risks.exercise!=='Yes'" class="button-lifestyle disable-div">WEIGHT LOSS</button>
                      <button v-if="userData.isWeightNormal&&userData.risks.exercise==='Yes'" class="button-lifestyle both-btns-disabled">WEIGHT LOSS</button>
                    </div>
                  </div>
                  
                  <div class="btn-col">
                    <div class="btn-divs last-btn">
                      <button v-if="userData.risks.exercise!=='Yes'" class="button-lifestyle" @click="displayExercise=true;setA1cChange();handleResize();">EXERCISE</button>
                      <button v-if="!userData.isWeightNormal && userData.risks.exercise==='Yes'" class="button-lifestyle disable-div">EXERCISE</button>
                      <button v-if="userData.isWeightNormal&&userData.risks.exercise==='Yes'" class="button-lifestyle both-btns-disabled">EXERCISE</button>
                    </div>
                  </div>
                </b-row>
              </b-col>
            </div>
          
          <!--radio options box  -->
          <div v-if="displayWeight||displayExercise" class="radio-box-div">
            <b-col cols="12" class="button-box">
              <b-row class="no-gutters inner-main-row-sm">
                
                <!--weight question-->
                <b-col v-if="displayWeight && !displayExercise" cols="12" class="reduce-padding">
                  <b-row class="no-gutters">
                    <div class="weight-img"></div>
                    <div class="radio-ques-div">
                      <div class="radio-ques">
                        <div class="quest-text">Is Your Patient Willing to Lose Weight?</div>
                        <div class="italic-text"><i>(5-10% of current weight)</i></div>
                      </div>
                    </div>
                    <b-row class="no-gutters radio-lower">
                      <b-col cols="12" lg="6" class="reduce-padding">
                        <b-row class="radios no-gutters">
                          <b-row class="radiobutton no-gutters">
                            <b-col cols="6" class="reduce-padding">
                              <input type="radio" name="loseWeightYes" v-model="loseWeight" id="loseWeightYes"
                              value="loseWeightYes" @change="radioChanged">
                              <label for="loseWeightYes">
                                  <div class="radio-label">Yes</div>
                              </label>
                            </b-col>
                            
                            <b-col cols="6" class="reduce-padding">
                              <input type="radio" name="loseWeightNo" v-model="loseWeight" id="loseWeightNo" 
                              value="loseWeightNo" @change="radioChanged">
                              <label for="loseWeightNo">
                                  <div class="radio-label">No</div>
                              </label>
                            </b-col>
                          </b-row>
                        </b-row>
                      </b-col>
                      <b-col cols="12" lg="6" class="reduce-padding switch-col">
                        <div class="switch-outer">
                          <div class="switch-txt">
                            <i>Switch to:</i>
                          </div>
                          <div class="btn-divs">
                            <button v-if="userData.risks.exercise!=='Yes'" class="button-lifestyle" @click="displayExercise=true;displayWeight=false;setA1cChange();">EXERCISE</button>
                            <button v-if="userData.risks.exercise==='Yes'" class="button-lifestyle disable-div">EXERCISE</button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-row>
                </b-col>

                <!--exercise question-->
                <b-col v-if="displayExercise && !displayWeight" cols="12" class="reduce-padding">
                  <b-row class="no-gutters">
                    <div class="man-img"></div>
                    <div class="radio-ques-div">
                      <b-row class="no-gutters radio-ques">
                        <div class="quest-text">Is Your Patient Willing to Exercise Regularily?</div>
                        <div class="italic-text"><i>(≥150 min/week of moderate to vigorous-intensity aerobic exercise)</i></div>
                      </b-row>
                    </div>
                    <b-row class="no-gutters radio-lower">
                      <b-col cols="12" lg="6" class="reduce-padding">
                        <b-row class="radios right-radios no-gutters">
                          <b-row class="radiobutton no-gutters">
                            <b-col cols="6" class="reduce-padding">
                              <input type="radio" name="willingExerciseYes" v-model="willingExercise" 
                              id="willingExerciseYes" value="willingExerciseYes" @change="radioChanged">
                              <label for="willingExerciseYes">
                                  <div class="radio-label">Yes</div>
                              </label>
    
                            </b-col>
                            <b-col cols="6" class="reduce-padding">
                              <input type="radio" name="willingExerciseNo" v-model="willingExercise" 
                              id="willingExerciseNo" value="willingExerciseNo" @change="radioChanged">
                              <label for="willingExerciseNo">
                                  <div class="radio-label">No</div>
                              </label>
                            </b-col>
                          </b-row>
                        </b-row>
                      </b-col>
                      <b-col cols="12" lg="6" class="reduce-padding switch-col">
                        <div class="switch-outer">
                        <div class="switch-txt">
                          <i>Switch to:</i>
                        </div>
                        <div class="btn-divs">
                          <button v-if="!userData.isWeightNormal" class="button-lifestyle" @click="displayWeight=true;displayExercise=false;setA1cChange();">WEIGHT LOSS</button>
                          <button v-if="userData.isWeightNormal" class="button-lifestyle disable-div">WEIGHT LOSS</button>
                        </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </div>
          
          <!--info icon large screen -->
          <div v-if="!displayWeight&&!displayExercise" class="info-icon-div lg-screen">
            <div @click="isInfoClicked(); scrollToInfoNav();" class="info-icon"></div>
          </div>
          
             <div v-if="displayWeight||displayExercise" class="info-icon-div lg-screen">
            <div @click="isInfoClicked2(); scrollToInfoNav();" class="info-icon"></div>
          </div>
        </div>
        </b-row>
        
        
        <!--ruler row-->
        <b-row class="no-gutters ruler-sec-row">

        <div lg="1" cols="12" :class="{'btn-clicked':displayWeight||displayExercise}" class='align-text-center sliderlabel'>
          <div class="a1c-text">A1C</div>
          <div class="a1c-perc">(%)</div>
         </div>
         <div class='col-lg-11 col-xs-6 a1c-slider-column'>
            <a1cSlider :currentA1cValue="Number(userData.risks.bloodSugar)" :sliderDiv="'sliderBig'" 
            :targetA1cValue="7" :changeA1cValue="changebloodSugar" :willingExercise="willingExercise"
            :loseWeight="loseWeight" :isWeightNormal="userData.isWeightNormal" :exercise="userData.risks.exercise"
            :displayWeight="displayWeight" :displayExercise="displayExercise" ></a1cSlider>
          </div>
        </b-row>


      </b-row>
      
      <modalImpDisc v-if="userData.isImpDiscussionClicked"></modalImpDisc>
<modalmoreInfo v-if="userData.isInfoClicked || userData.isInfoClicked2" :dynamicMarginTop="dynamicMarginTop" :btnClicked="displayWeight||displayExercise"></modalmoreInfo>

<!--<div  v-if="userData.isInfoClicked2">-->
<!--   <transition name="modal">-->
<!--        <div class="modal-mask">-->
<!--          <div class="modal-wrapper-info">-->
<!--            <div class="modal-container-info">-->
<!--    <div  class="overlay-inner-main info-overlay">-->
<!--        <div  id="info-div" ref="infoDiv" class="overlay-inner info-overlay">-->
<!--          <div class="">-->
<!--            <div class="info-text-div overlay-txt">-->
<!--              <b>A1C reduction with weight loss is calculated from:</b>-->
<!--              <br>Wing RR, Lang W, Wadden TA, et al. Benefits of modest weight loss in improving cardiovascular risk factors in -->
<!--              overweight and obese individuals with type 2 diabetes. Diabetes Care. 2011;34:1481-1486.-->
<!--            </div>-->
<!--            <div class="info-img-div">-->
<!--              <div class="info-selected-img" @click="closeDiscussions"></div>-->
<!--            </div>-->
<!--          </div>-->
  
<!--          <div class="">-->
<!--            <div class="overlay-txt sec-para">-->
<!--              <b>A1C reduction with exercise is calculated from:</b>-->
<!--              <br>American Diabetes Association. Standards of medical care in diabetes-2020. Diabetes Care. 2020;43(suppl 1):S1-S212.-->
<!--            </div>-->
<!--            <div class="overlay-txt">-->
<!--              <i>Results may not be cumulative.</i>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
              
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </transition>-->
<!--</div>-->

    </b-col>
    <codNavigation :isValidatedPage="true" v-bind:navButtons="['info','next','back', 'discussion']" :btnClickHandler="this.handleSubmit"
    :scrollToImpDiscNav="this.scrollToTop" v-bind:nextPage="'summary'"></codNavigation>
  </b-col>
</b-row>
</template>
<script>
/* eslint-disable */
  import codHeader from '@/components/codHeader.vue';
  import codNavigation from '@/components/codNavigation.vue';
   import a1cSlider from '@/components/a1cSlider.vue';
   import modalImpDisc from '@/components/modalImpDisc.vue';
   import modalmoreInfo from '@/components/modalmoreInfo.vue';
  import codCalcs from '@/mixins/codCalcs.js';
  import scrollMixin from '../mixins/scrollMixin';
import taggingMixin from '../mixins/taggingMixin';
  import { mapGetters, mapMutations } from 'vuex';
  
export default {
  name: "lifestyle",
  components: {
      'codHeader': codHeader,
      'codNavigation' : codNavigation,
      'a1cSlider': a1cSlider,
      'modalImpDisc': modalImpDisc,
      'modalmoreInfo':modalmoreInfo
    },
  mixins: [scrollMixin, codCalcs, taggingMixin],
  
  mounted: function() {
    this.scrollToTop();
    this.userData.isGoalsPage= false;
     // listen for window size changes and update slider
      window.addEventListener('resize', this.handleResize);
      
      this.handleResize();
  },
  
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize);
  },
  
  data() {
    return {
      pageTitle: 'Consider Lifestyle Changes',
      pageNumber: '4',
      isDialogOpened: false,
      loseWeight: '',
      willingExercise: '',
      changebloodSugar: '',
      changebloodSugarW: '',
      changebloodSugarE: '',
      bmi: '',
      displayWeight: false,
      displayExercise: false,
      dynamicMarginTop: 160,
      dynamicMarginR: 100,
    };
  },
  
  computed: {
      ...mapGetters('userData', ['userData']),
  },
  
  created() {
    this.checkWeight();
    if(this.changebloodSugar === ''){
     this.changebloodSugar = this.userData.risks.bloodSugar;
    }
    this.loseWeight=this.userData.lifestyle.loseWeight;
    this.willingExercise=this.userData.lifestyle.willingExercise;

    this.radioChanged();
    
  },
    
  methods: {
     ...mapMutations('userData', ['saveUserData']),
     
     handleResize: function(event) {
      setTimeout(() => {
        this.dynamicMarginTop = document.querySelector('.blue-sec-row.btn-clicked')==undefined?
        document.querySelector('.blue-sec-row').getBoundingClientRect().top-1:document.querySelector('.blue-sec-row').getBoundingClientRect().top+5;
        if (window.innerWidth<=767) { this.dynamicMarginTop = document.querySelector('.blue-sec-row.btn-clicked')==undefined?
        this.dynamicMarginTop-1:this.dynamicMarginTop+3;}
        if (window.innerWidth<=575) { this.dynamicMarginTop = document.querySelector('.blue-sec-row.btn-clicked')==undefined?
        this.dynamicMarginTop+5:this.dynamicMarginTop-2;}
      }, 0);
    },
    
     scrollToImpDisc: function() {
       if(this.userData.isImpDiscussionClicked) {
        this.$nextTick(function(){
				// this.$refs.testDiv.scrollTop = 0;
        window.scrollTo(0, 0);
			});
			
    }
     },
     
    scrollToInfoNav: function() {
      if(this.userData.isInfoClicked) {
        this.$nextTick(function(){
  				// this.$refs.infoDiv.scrollTop = 0;
          window.scrollTo(0, 0);
  			});
      }
    },
     
     isInfoClicked: function() {
        this.userData.isInfoClicked = true;
      },
      
       isInfoClicked2: function() {
        this.userData.isInfoClicked2 = true;
      },
  
    closeDiscussions: function() {
      this.userData.isImpDiscussionClicked = false;
      this.userData.isInfoClicked2 = false;
    },
    
    checkWeight: function() {
      if(this.userData.risks.weight && this.userData.patientProfile.heightInches && this.userData.patientProfile.heightFeet) {
        var height = parseInt(this.userData.patientProfile.heightInches) + 12*(parseInt(this.userData.patientProfile.heightFeet));
        this.bmi =  (this.userData.risks.weight * 0.45359237) / Math.pow((height * 2.54) / 100, 2);
        if(this.bmi < 25) {
          this.userData.isWeightNormal = true;
        } else {
          this.userData.isWeightNormal = false;
        }
      }
      
    },
    
    radioChanged: function() {
      var willexcercise = this.willingExercise==='willingExerciseYes' ? 'YES' : 'NO';
      var willlooseWeight = this.loseWeight==='loseWeightYes' ? 'YES' : 'NO';
      this.tagLifestyle('losingWeight', willlooseWeight);
      this.tagLifestyle('willingExercise', willexcercise);
      var reduction = this.getA1cReduction(this.userData.risks.exercise, willlooseWeight, willexcercise, this.bmi);
      this.changebloodSugarW = Math.round((this.userData.risks.bloodSugar - reduction[0])*10)/10;
      this.changebloodSugarE = Math.round((this.userData.risks.bloodSugar - reduction[1])*10)/10;
      this.setA1cChange();
      this.tagLifestyle('change', this.changebloodSugarW);
    },
    
    setA1cChange: function() {
      this.changebloodSugar = this.displayExercise?this.changebloodSugarE:this.displayWeight?this.changebloodSugarW:this.userData.risks.bloodSugar;
    },
    
    saveData: function() {
          this.saveUserData({
            data: {
            loseWeight: this.loseWeight,
            willingExercise: this.willingExercise,
            changebloodSugarW: this.changebloodSugarW,
            changebloodSugarE: this.changebloodSugarE,
          },
            section: 'lifestyle'
          });
          
        },
      
      handleSubmit(e) {
        this.saveData();
        this.tagNavigation('next', 'lifestyle');
        this.$router.push('summary');
      }
  },
};
</script>

<style lang="scss" scoped>

.imp-discussions-logo {
  background-image: url("../images/icon-svg/importantdissussion_header_logo.svg");
}

.info-selected-img {
  background-image: url("../images/icon-svg/infoselected.svg");
 }
 
 .weight-img {
  background-image: url("../images/icon-svg/weight-image.svg");
  background-repeat: no-repeat;
 }
 
 .man-img {
  background-image: url("../images/icon-svg/excersise-image.svg");
  background-repeat: no-repeat;
 }
 
  label:before {
    background-image: url("../images/icon-svg/radiobutton-lifestyle-image.svg");
    background-repeat: no-repeat;
 }
 
  input[type=radio]:checked+label:before {
    background-image: url("../images/icon-svg/radioselected-lifestyle-image.svg");
    background-repeat: no-repeat;
  }
  
  .info-icon {
    background-image: url("../images/icon-svg/infoicon.svg");
    background-repeat: no-repeat;
 }
 
 .cross-img {
  background-image: url("../images/icon-svg/x_image.svg");
}
  
</style>
