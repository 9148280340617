import Vuex from 'vuex'
import UserData from '@/store/userData';

class CodStore extends Vuex.Store {

  constructor() {
    super({
      modules: {
        userData: new UserData(),
      }
    });
  }

}

export default CodStore;
