<template>
  <b-row class="no-gutters">
    <b-col cols="12">
      
        <div class="a1cslider">
        
            <!--- slider -->
           <div class="large-slider">
              <div class="slider slider-big" ref="sliderBig">

                <div class="outer-current">
                 <div :class="{'btn-clicked':displayWeight||displayExercise}" class="current-text white-text" ref="currentText">CURRENT:
                  <span v-if="currentA1cValue>=4 && currentA1cValue % 1 !== 0"> {{currentA1cValue}}%</span>
                  <span v-if="currentA1cValue>=4 && currentA1cValue % 1 === 0"> {{currentA1cValue}}.0%</span>
                  <span v-show="currentA1cValue<4"> &lt;4.0%</span></div>

                   <div :class="{'btn-clicked':displayWeight||displayExercise}" class='current-slider' id="currentSlider">  
                     
                     <svg xmlns="http://www.w3.org/2000/svg" width="645" height="70" viewBox="0 0 645 70">
                      <g id="currentPointer" transform="translate(0,0)">
                        <g v-if="currentA1cValue >8.8 && (displayWeight||displayExercise)">
                          <line style="fill:none;stroke:#e64a0e;stroke-miterlimit:10;" x1="9" y1="7" x2="9" y2="56.62"/>
                          <polygon fill="#e64a0e" points="1.52 54.43 9 67.39 16.48 54.43 1.52 54.43"/>
                        </g>
                        <g v-if="currentA1cValue >8.8 && (!displayWeight && !displayExercise)">
                          <line style="fill:none;stroke:#e64a0e;stroke-miterlimit:10;" x1="9" y1="13" x2="9" y2="56.62"/>
                          <polygon fill="#e64a0e" points="1.52 54.43 9 67.39 16.48 54.43 1.52 54.43"/>
                        </g>
                        <g v-if="currentA1cValue >7 && currentA1cValue<=8.8">
                          <line style="fill:none;stroke:#e64a0e;stroke-miterlimit:10;" x1="9" y1="34.61" x2="9" y2="56.62"/>
                          <polygon fill="#e64a0e" points="1.52 54.43 9 67.39 16.48 54.43 1.52 54.43"/>
                        </g>
                        <g v-if="currentA1cValue <=5.8 && (!displayWeight && !displayExercise)">
                          <line style="fill:none;stroke:#3F9C35;stroke-miterlimit:10;" x1="9" y1="13" x2="9" y2="56.62"/>
                          <polygon fill="#3F9C35" points="1.52 54.43 9 67.39 16.48 54.43 1.52 54.43"/>
                        </g>
                        <g v-if="currentA1cValue <=5.8 && (displayWeight||displayExercise)">
                          <line style="fill:none;stroke:#3F9C35;stroke-miterlimit:10;" x1="9" y1="7" x2="9" y2="56.62"/>
                          <polygon fill="#3F9C35" points="1.52 54.43 9 67.39 16.48 54.43 1.52 54.43"/>
                        </g>
                        <g v-if="currentA1cValue >5.8 && currentA1cValue<=7">
                          <line style="fill:none;stroke:#3F9C35;stroke-miterlimit:10;" x1="9" y1="34.61" x2="9" y2="56.62"/>
                          <polygon fill="#3F9C35" points="1.52 54.43 9 67.39 16.48 54.43 1.52 54.43"/>
                        </g>
                      </g>
                    </svg>
                   
                  </div>
                </div>
              
              </div>
              
              <div class="slider">
                <div>
                  <div :class="{'btn-clicked':displayWeight||displayExercise}" class="target-text white-text" ref="targetText">TARGET: &lt;{{targetA1cValue}}.0%</div>

                  <div class="target-slider" id="targetSlider">
                    <svg xmlns="http://www.w3.org/2000/svg" width="645" height="36" viewBox="0 0 645 36">
                      <g id="targetPointer" transform="translate(0,0)">
                        <line style="fill:none;stroke:#3F9C35;stroke-miterlimit:10;" x1="9.18" y1="7" x2="9.18" y2="24.04"/>
                        <polygon fill="#3F9C35" points="1.7 21.85 9.18 34.8 16.66 21.85 1.7 21.85"/>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
        
              <div class='scale-img'></div>
              
              <div class="slider">
                <div :class="{'btn-clicked':displayWeight||displayExercise}" class="change-slider" id="changeSlider">
                  <svg xmlns="http://www.w3.org/2000/svg" width="645" height="40" viewBox="0 0 645 40">
                    <g id="changePointer" transform="translate(0,0)">
                      <g v-if="isSummary===true">
                      <line style="fill:none;stroke:#001965;stroke-miterlimit:10;" x1="8.94" y1="37" x2="8.94" y2="12.74"/>
                      <polygon fill="#001965" points="16.42 14.93 8.94 1.97 1.46 14.93 16.42 14.93"/>
                      </g>
                      <g v-if="isSummary!==true && (displayWeight||displayExercise)">
                      <line style="fill:none;stroke:#001965;stroke-miterlimit:10;" x1="8.94" y1="59.75" x2="8.94" y2="12.74"/>
                      <polygon fill="#001965" points="16.42 14.93 8.94 1.97 1.46 14.93 16.42 14.93"/>
                      </g>
                      <g v-if="isSummary!==true && (!displayWeight && !displayExercise)">
                      <line style="fill:none;stroke:#001965;stroke-miterlimit:10;" x1="8.94" y1="37" x2="8.94" y2="12.74"/>
                      <polygon fill="#001965" points="16.42 14.93 8.94 1.97 1.46 14.93 16.42 14.93"/>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              
              
              <div class="slider-column-blue-box"> 
                <div class="row no-gutters">
                  
                    <div class="icons-div">
                      <!--weight icon-->
                      <div class="weight-img" v-if="displayWeight"></div>
                        
                      <!--man icon-->
                      <div  class="man-img" v-if="displayExercise"></div>
                    
                    </div>
                    
                      <!--blue box-->
                      <div :class="{'btn-clicked':displayWeight||displayExercise}" class="lg-ref-div" ref="blueBox">
                        
                        <!--exercises regularly and normal weight-->
                        <div class="single-line-text" v-if="(isWeightNormal && exercise=='Yes'&& isSummary!==true)">
                          YOUR PATIENT ALREADY HAS A BMI &lt;25 &amp; EXERCISES ≥150 MIN/WEEK.
                        </div>
                        <div class="single-line-text summary-both-disabled" v-if="(isWeightNormal && exercise=='Yes'&& isSummary===true && displayExercise)">
                          YOUR PATIENT ALREADY EXERCISES ≥150 MIN/WEEK.
                        </div>
                        <div class="single-line-text summary-both-disabled" v-if="(isWeightNormal && exercise=='Yes'&& isSummary===true && displayWeight)">
                          YOUR PATIENT ALREADY HAS A BMI &lt;25.
                        </div>
                        <!--exercises regularly only-->
                        <div v-if="(!isWeightNormal && exercise=='Yes')">
                          <!--no selection-->
                          <div v-if="!displayWeight">
                            <div class="single-lifestyle" v-if="!displayExercise">
                              YOUR PATIENT ALREADY EXERCISES ≥150 MIN/WEEK.<br>SELECT “WEIGHT LOSS” ABOVE.
                            </div>
                            <div class="single-lifestyle" v-if="isSummary===true && displayExercise">
                              YOUR PATIENT ALREADY EXERCISES ≥150 MIN/WEEK.<br>SELECT “WEIGHT LOSS” ABOVE.
                            </div>
                          </div>
                          
                           <!--weight loss question-->
                          <div class="lg-text" v-if="(displayWeight && loseWeight!=='loseWeightYes')">
                            SELECT “YES” TO SEE HOW A1C IS IMPACTED WITH WEIGHT LOSS.
                          </div>
                          <!--user selects Yes-->
                          <div v-if="(displayWeight && loseWeight==='loseWeightYes')">
                            <div class="a1c-lower-text">
                              WITH 5-10% <i>WEIGHT LOSS</i>, YOUR PATIENT’S A1C MAY BE LOWERED TO ABOUT:
                          </div>
                            <div class="a1c-lower-perc">
                              <span v-if="changeA1cValue % 1 === 0">{{changeA1cValue}}.0%</span>
                            <span v-if="changeA1cValue % 1 !== 0">{{changeA1cValue}}%</span>
                            </div>
                          </div>
                        </div>
                        
                        <!--normal weight only-->
                        <div v-if="(isWeightNormal && exercise!=='Yes')">
                          <!--no selection-->
                          <div class="single-line-text" v-if="!displayWeight && !displayExercise">
                            YOUR PATIENT ALREADY HAS A BMI &LT;25.  SELECT “EXERCISE” ABOVE.
                          </div>
                          <div class="single-line-text" v-if="isSummary===true && displayWeight && !displayExercise">
                            YOUR PATIENT ALREADY HAS A BMI &LT;25.  SELECT “EXERCISE” ABOVE.
                          </div>
                           <!--exercise question-->
                          <div class="lg-text" v-if="(displayExercise && willingExercise!=='willingExerciseYes')">
                            SELECT “YES” TO SEE HOW A1C IS IMPACTED WITH REGULAR EXERCISE.
                          </div>
                          <!--user selects Yes-->
                          <div v-if="(displayExercise && willingExercise==='willingExerciseYes')">
                            <div class="a1c-lower-text">
                              WITH REGULAR <i>EXERCISE</i>, YOUR PATIENT’S A1C MAY BE LOWERED TO ABOUT:
                            </div>
                            <div class="a1c-lower-perc">
                              <span v-if="changeA1cValue % 1 === 0">{{changeA1cValue}}.0%</span>
                            <span v-if="changeA1cValue % 1 !== 0">{{changeA1cValue}}%</span>
                            </div>
                          </div>
                
                        </div>
                        
                        <!--no regular exercise or normal weight-->
                        <div v-if="(isWeightNormal !== true && exercise!=='Yes')">
                          <!--none selected-->
                          <!--<div v-if="(loseWeight==='loseWeightNo') && (willingExercise==='willingExerciseNo')">-->
                          <div class="single-line-text" v-if="!displayWeight && !displayExercise">
                            SELECT “WEIGHT LOSS” OR “EXERCISE” TO SEE HOW A1C IS IMPACTED.
                          </div>
                          <!--weight loss question-->
                          <div class="lg-text" v-if="displayWeight && loseWeight!=='loseWeightYes'">
                            SELECT “YES” TO SEE HOW A1C IS IMPACTED WITH WEIGHT LOSS.
                          </div>
                          <!--user selects Yes-->
                          <div v-if="(displayWeight && loseWeight==='loseWeightYes')">
                            <div class="a1c-lower-text">
                              WITH 5-10% <i>WEIGHT LOSS</i>, YOUR PATIENT’S A1C MAY BE LOWERED TO ABOUT:
                          </div>
                            <div class="a1c-lower-perc">
                              <span v-if="changeA1cValue % 1 === 0">{{changeA1cValue}}.0%</span>
                            <span v-if="changeA1cValue % 1 !== 0">{{changeA1cValue}}%</span>
                            </div>
                          </div>
                          
                          <!--exercise question-->
                          <div class="lg-text" v-if="(displayExercise && willingExercise!=='willingExerciseYes')">
                            SELECT “YES” TO SEE HOW A1C IS IMPACTED WITH REGULAR EXERCISE.
                          </div>
                          <!--user selects Yes-->
                          <div v-if="(displayExercise && willingExercise==='willingExerciseYes')">
                            <div class="a1c-lower-text">
                              WITH REGULAR <i>EXERCISE</i>, YOUR PATIENT’S A1C MAY BE LOWERED TO ABOUT:
                            </div>
                            <div class="a1c-lower-perc">
                              <span v-if="changeA1cValue % 1 === 0">{{changeA1cValue}}.0%</span>
                            <span v-if="changeA1cValue % 1 !== 0">{{changeA1cValue}}%</span>
                            </div>
                          </div>
                        </div>
                            
                      </div>
                    
                  </div>
                </div> 
              
            </div>
            
            <!--- slider -->
           <div class="small-slider">

             <div class="row no-gutters">
              <div class="col-8 slider-column-verticle"> 
                <div class="row no-gutters">
                  <div class="col-7 small-slider-arrows">
                    <div class="slider slider-small-current-col" ref="sliderSm">
                        <div class="row no-gutters">
                       <div class="col-5 current-text white-text current-text-sm reduce-padding" ref="currentTextSm">
                         CURRENT:&nbsp;<span v-show="currentA1cValue>=4">{{currentA1cValue}}%</span>
                         <span v-show="currentA1cValue<4">&lt;4.0%</span>
                      </div>
                      <div class='col-7 current-slider-sm reduce-padding' id="currentSliderSm">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="645" viewBox="0 0 70 645">
                          <g id="currentPointerSm" transform="translate(0,0)">
                            <g v-if="currentA1cValue >7">
                              <line style="fill:none;stroke:#e64a0e;stroke-miterlimit:10;" x1="14.6" y1="9" x2="56.6" y2="9"/>
                              <polygon fill="#e64a0e" points="54.4,16.5 67.4,9 54.4,1.5 "/>
                            </g>
                            <g v-if="currentA1cValue <=7">
                              <line style="fill:none;stroke:#3F9C35;stroke-miterlimit:10;" x1="14.6" y1="9" x2="56.6" y2="9"/>
                              <polygon fill="#3F9C35" points="54.4,16.5 67.4,9 54.4,1.5 "/>
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                    </div>
                    <div class="slider">
                        <div class="row no-gutters">
                          <div class="col-8 target-text target-text-sm white-text" ref="targetTextSm">TARGET:&nbsp;&lt;{{targetA1cValue}}.0%</div>
                          <div class="col-4 target-slider-sm reduce-padding" id="targetSliderSm"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="645" viewBox="0 0 36 645">
                              <g id="targetPointerSm" transform="translate(0,0)">
                                <line style="fill:none;stroke:#3F9C35;stroke-miterlimit:10;" x1="1.8" y1="9.4" x2="23.8" y2="9.4"/>
                                <polygon fill="#3F9C35" points="21.7,16.9 34.6,9.4 21.7,1.9 "/>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  <div class="col-3 reduce-padding small-slider-column" >
                    <div class='scale-img2' ref="smSlider"></div>
                  </div>
                  <div class="col-2 small-arrow-blue">
                    <div class="slider">
                      <div class="change-slider-sm" id="changeSliderSm"> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="665" viewBox="0 0 38 645">
                          <g id="changePointerSm" transform="translate(0,0)">
                            <line style="fill:none;stroke:#001965;stroke-miterlimit:10;" x1="38.3" y1="9.3" x2="12.3" y2="9.3"/>
                            <polygon fill="#001965" points="14.5,1.9 1.6,9.3 14.5,16.8 "/>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="col-4 slider-column-blue-box"> 
                <div class="row no-gutters">
                  <div class="icons-div lg-icons">
                    
                    <!--weight icon-->
                    <div class="weight-img" v-if="displayWeight"></div>
                      
                    <!--man icon-->
                    <div  class="man-img" v-if="displayExercise"></div>
                  
                  </div>
                  <div :class="{'btn-clicked':displayWeight||displayExercise}" class="sm-ref-div" ref="blueBoxMd">
                    
                    <!--exercises regularly and normal weight-->
                    <div class="outer-text-div both-disable" v-if="(isWeightNormal && exercise=='Yes' && isSummary!==true)">
                      YOUR PATIENT ALREADY HAS A BMI &lt;25 &amp; EXERCISES ≥150 MIN/WEEK.
                    </div>
                    <div class="outer-text-div both-disable summary-both-disabled" v-if="(isWeightNormal && exercise=='Yes' && isSummary===true && displayExercise)">
                      YOUR PATIENT ALREADY EXERCISES ≥150 MIN/WEEK.
                    </div>
                    <div class="outer-text-div both-disable summary-both-disabled" v-if="(isWeightNormal && exercise=='Yes' && isSummary===true && displayWeight)">
                      YOUR PATIENT ALREADY HAS A BMI &lt;25.
                    </div>
        
                    <!--exercises regularly only-->
                    <div class="outer-text-div" v-if="(!isWeightNormal && exercise=='Yes')">
                      <div>
                        <div v-if="(displayWeight && loseWeight==='loseWeightYes')" class="perc-div-space"></div>
                        <div class="icons-div sm-icons">
                          <!--weight icon-->
                          <div class="weight-img" v-if="displayWeight"></div>
                          <!--man icon-->
                          <div  class="man-img" v-if="displayExercise"></div>
                        </div>
                        <!--no selection-->
                        <div v-if="!displayWeight">
                          <div class="sm-side-padding " v-if="!displayExercise">
                            YOUR PATIENT ALREADY EXERCISES ≥150 MIN/WEEK.<br>SELECT “WEIGHT LOSS” ABOVE.
                          </div>
                          <div class="sm-side-padding " v-if="isSummary===true && displayExercise">
                            YOUR PATIENT ALREADY EXERCISES ≥150 MIN/WEEK.<br>SELECT “WEIGHT LOSS” ABOVE.
                          </div>
                        </div>
                         <!--weight loss question-->
                        <div class="sm-side-padding" v-if="(displayWeight && loseWeight!=='loseWeightYes')">
                          <div v-if="(isSummary!==true)">
                            SELECT “YES” TO SEE HOW A1C IS IMPACTED WITH WEIGHT LOSS.
                          </div>
                        </div>
                        <!--user selects Yes-->
                        <div v-if="(displayWeight && loseWeight==='loseWeightYes')">
                          <div class="a1c-lower-text">
                            WITH 5&#8209;10% <i>WEIGHT LOSS</i>, YOUR PATIENT’S A1C MAY BE LOWERED TO ABOUT:
                        </div>
                          <div class="a1c-lower-perc">
                            <span v-if="changeA1cValue % 1 === 0">{{changeA1cValue}}.0%</span>
                            <span v-if="changeA1cValue % 1 !== 0">{{changeA1cValue}}%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <!--normal weight only-->
                    <div class="outer-text-div" v-if="(isWeightNormal && exercise!=='Yes')">
                      <div>
                        <div :class="{'exercise-text':displayExercise && willingExercise==='willingExerciseYes'}" v-if="(displayExercise && willingExercise==='willingExerciseYes')" class="perc-div-space"></div>
                        <div class="icons-div sm-icons">
                          <!--weight icon-->
                          <div class="weight-img" v-if="displayWeight"></div>
                          <!--man icon-->
                          <div  class="man-img" v-if="displayExercise"></div>
                        </div>
                        <!--no selection-->
                        <div class="sm-side-padding" v-if="!displayWeight && !displayExercise">
                          YOUR PATIENT ALREADY HAS A BMI &LT;25.  SELECT “EXERCISE” ABOVE.
                        </div>
                        <div class="sm-side-padding" v-if="isSummary===true && displayWeight && !displayExercise">
                          YOUR PATIENT ALREADY HAS A BMI &LT;25.  SELECT “EXERCISE” ABOVE.
                        </div>
                         <!--exercise question-->
                        <div class="sm-side-padding" v-if="(displayExercise && willingExercise!=='willingExerciseYes')">
                          <!--<div v-if="(isSummary===true && summaryTab==='weight')">-->
                            YOUR PATIENT ALREADY HAS A BMI &LT;25.  SELECT “EXERCISE” ABOVE.
                          <!--</div>-->
                          <!-- <div v-if="(isSummary===true && summaryTab==='exercise')">-->
                          <!--  SELECT “YES” TO SEE HOW A1C IS IMPACTED WITH REGULAR EXERCISE.-->
                          <!--</div>-->
                          <!--<div v-if="(isSummary!==true)">-->
                          <!--  SELECT “YES” TO SEE HOW A1C IS IMPACTED WITH REGULAR EXERCISE.-->
                          <!--</div>-->
                        </div>
                        <!--user selects Yes-->
                        <div v-if="(displayExercise && willingExercise==='willingExerciseYes')">
                          <div class="a1c-lower-text exercise-text">
                            WITH REGULAR <i>EXERCISE</i>, YOUR PATIENT’S A1C MAY BE LOWERED TO ABOUT:
                          </div>
                          <div :class="{'exercise-text':displayExercise && willingExercise==='willingExerciseYes'}" class="a1c-lower-perc">
                            <span v-if="changeA1cValue % 1 === 0">{{changeA1cValue}}.0%</span>
                            <span v-if="changeA1cValue % 1 !== 0">{{changeA1cValue}}%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <!--no regular exercise or normal weight-->
                    <div class="outer-text-div" v-if="(isWeightNormal !== true && exercise!=='Yes')">
                      <div>
                        <div :class="{'exercise-text':displayExercise && willingExercise==='willingExerciseYes'}" v-if="(displayWeight && loseWeight==='loseWeightYes') || (displayExercise && willingExercise==='willingExerciseYes')" 
                        class="perc-div-space"></div>
                        <div class="icons-div sm-icons">
                          <!--weight icon-->
                          <div class="weight-img" v-if="displayWeight"></div>
                          <!--man icon-->
                          <div  class="man-img" v-if="displayExercise"></div>
                        </div>
                        <!--none selected-->
                        <!--<div v-if="(loseWeight==='loseWeightNo') && (willingExercise==='willingExerciseNo')">-->
                        <div class="sm-side-padding" v-if="!displayWeight && !displayExercise">
                          SELECT “WEIGHT LOSS” OR “EXERCISE” TO SEE HOW A1C IS IMPACTED.
                        </div>
                        <!--weight loss question-->
                        <div class="sm-side-padding" v-if="(displayWeight && loseWeight!=='loseWeightYes')">
                          SELECT “YES” TO SEE HOW A1C IS IMPACTED WITH WEIGHT LOSS.
                        </div>
                        <!--user selects Yes-->
                        <div v-if="(displayWeight && loseWeight==='loseWeightYes')">
                          <div class="a1c-lower-text">
                            WITH 5&#8209;10% <i>WEIGHT LOSS</i>, YOUR PATIENT’S A1C MAY BE LOWERED TO ABOUT:
                        </div>
                          <div class="a1c-lower-perc">
                            <span v-if="changeA1cValue % 1 === 0">{{changeA1cValue}}.0%</span>
                            <span v-if="changeA1cValue % 1 !== 0">{{changeA1cValue}}%</span>
                          </div>
                        </div>
                        <!--exercise question-->
                        <div class="sm-side-padding" v-if="(displayExercise && willingExercise!=='willingExerciseYes')">
                          SELECT “YES” TO SEE HOW A1C IS IMPACTED WITH REGULAR EXERCISE.
                        </div>
                        <!--user selects Yes-->
                        <div v-if="(displayExercise && willingExercise==='willingExerciseYes')">
                          <div class="a1c-lower-text exercise-text">
                            WITH REGULAR <i>EXERCISE</i>, YOUR PATIENT’S A1C MAY BE LOWERED TO ABOUT:
                          </div>
                          <div :class="{'exercise-text':displayExercise && willingExercise==='willingExerciseYes'}" class="a1c-lower-perc">
                            <span v-if="changeA1cValue % 1 === 0">{{changeA1cValue}}.0%</span>
                            <span v-if="changeA1cValue % 1 !== 0">{{changeA1cValue}}%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>  <!--//end blue box-->
            </div>  
            </div>
        </div>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable */
export default {
  name: "a1cSlider",
  components: {},

  data() {
    return {
      sliderwidth: '',
      changeEndPos: 0,
      sliderdiv: '',
      sliderName: '',
      smallslider: '',
      currentSlider: '',
      animationdone: false
    };
  },
  
  props: {
            currentA1cValue: {
                type: Number
            } ,
            sliderDiv: {
                type: String
            } ,
            targetA1cValue: {
                type: Number
            } ,
            changeA1cValue: {
                type: Number
            },
            ispdf: {
                type: Boolean
            },
            isSummary: {
                type: Boolean
            },
            inFocusW: {
                type: Boolean
            },
            inFocusE: {
                type: Boolean
            },
            willingExercise: {
                type: String
            },
            loseWeight:  {
                type: String
            },
            isWeightNormal: {
                type: Boolean
            },
            exercise: {
                type: String
            },
            displayWeight: {
                type: Boolean
            },
            displayExercise: {
                type: Boolean
            },
            summaryTab:  {
                type: String
            },
          },
          
  mounted() {
    this.$nextTick(function () {
       
    this.sliderdiv=this.$refs[this.sliderDiv];
    this.currentSlider = d3.select('#currentPointer');
    
    // listen for window size changes and update slider
      window.addEventListener('resize', this.handleResize)
    // set initial slider values based on current window size
      this.updateSlider();
      
    //animate sliders
      if(this.ispdf===true || this.isSummary===true) {
        if(this.smallslider === true) {
          this.placeSlidersSmall();
        } else {
          this.placeSliders();  
        }
        
      } else {
        if(this.smallslider === true) {
          this.animateSlidersSmall();
        } else {
          this.animateSliders();  
        }
      }
      
    });
  },
  
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize);
  },
  

  watch: {
    changeA1cValue: function(oldVal, newVal) {
      if(oldVal !== newVal) {
        this.sliderdiv = this.$refs[this.sliderDiv];
        this.currentSlider = d3.select('#currentPointer');
        this.animationdone=false;
        this.updateSlider();
        
        if(this.smallslider === true) {
          var startChangePos = this.getTransform(d3.select("#changePointerSm") )[1];
          var endChangePos = this.calcEndpositionSm(this.changeA1cValue);
          endChangePos = endChangePos<0?0:endChangePos>627?627:endChangePos;
          var changeduration=9*(endChangePos-startChangePos);
          changeduration=changeduration<0?changeduration*-1:changeduration;
          d3.select("#changePointerSm").transition().ease(d3.easeLinear).duration(changeduration).attr("transform", "translate(0, "+endChangePos+")");
          
        } else {
          var startChangePos = this.getTransform(d3.select("#changePointer") )[0];
          var endChangePos = this.calcEndposition(this.changeA1cValue);
          var changeduration=9*(endChangePos-startChangePos);
          changeduration=changeduration<0?changeduration*-1:changeduration;
          d3.select("#changePointer").transition().ease(d3.easeLinear).duration(changeduration).attr("transform", "translate("+endChangePos+", 0)");
          
        }
        this.animationdone=true;
      }
    },
      
    inFocusW: function(oldVal, newVal) {
        console.log("==================inFocusW: old: "+oldVal+" new: "+newVal);
      if( (oldVal !== newVal) && (this.isSummary===true)  && (oldVal==true) ) {
        this.sliderdiv = this.$refs[this.sliderDiv];
        this.updateSlider();
        if(this.smallslider === true) {
          this.placeSlidersSmall();
        } else {
          this.placeSliders();
        }
      }
    },
    
    inFocusE: function(oldVal, newVal) {
       console.log("============inFocusE: old: "+oldVal+" new: "+newVal);
      if( (oldVal !== newVal) && (this.isSummary===true) && (oldVal==true) ) {
        this.sliderdiv = this.$refs[this.sliderDiv];
        this.updateSlider();
        if(this.smallslider === true) {
          this.placeSlidersSmall();
        } else {
          this.placeSliders();
        }
      }
    },
    
  },
  
  methods: {
    handleResize: function(event) {
      setTimeout(() => {
        // this.sliderdiv = this.$refs[this.sliderDiv];
        this.updateSlider();
      // if (this.isSummary===true) {
      //   if(this.smallslider === true) {
      //       this.placeSlidersSmall();
      //     } else {
      //       this.placeSliders();
      //     }
      // }
      }, 0);
    },
    
    getTransform: function(elemnt) {
      var transformString = elemnt.attr("transform").substring(elemnt.attr("transform").indexOf("(")+1, elemnt.attr("transform").indexOf(")"));
      // ie and edge fix
      if (transformString.length === 1) {
         transformString = transformString+" "+transformString;
         return transformString.split(" ");
      } else if (transformString.indexOf(",")===-1) {
        return transformString.split(" ");
      } else {
        return transformString.split(",");
      }
    },
    
    calcEndpositionSm: function(value) {
      if(value < 4) {
        value = 4;
      }
      // this.sliderwidth=this.sliderwidth<1?628:this.sliderwidth;
      console.log("sliderwidth: "+(this.sliderwidth)+" value: "+value+" end pos calc: "+Math.round((this.sliderwidth/12)*parseFloat(value-4)));
      return Math.round(((this.sliderwidth)/12)*parseFloat(value-4));
    },
    
    calcEndposition: function(value) {
        if(value < 4) {
          value = 4;
        }
        return Math.round(((this.sliderwidth)/12)*parseFloat(value-4));
      },
    
    updateSlider: function() {
      this.sliderdiv=this.$refs[this.sliderDiv];
      this.currentSlider = d3.select('#currentSlider');
      console.log("sliderdiv: "+this.sliderdiv+" clientWidth: "+this.sliderdiv.clientWidth);
      if(this.sliderdiv===undefined || this.sliderdiv.clientWidth <= 0) {
        this.sliderdiv=this.$refs.smSlider;
        this.currentSlider = d3.select('#currentSliderSm');
        this.sliderwidth = this.sliderdiv.clientHeight-56;
        console.log("----sliderdiv: "+JSON.stringify(this.sliderdiv)+" sliderwidth: "+this.sliderwidth);
        if(this.smallslider === false) {
          this.smallslider = true;
          this.placeSlidersSmall();
        } else {
          this.smallslider = true;
          if (this.animationdone || this.isSummary) {
            this.placeSlidersSmall();
          };
        }
      } else {
        this.sliderwidth = this.sliderdiv.clientWidth-19;
        if(this.smallslider === true) {
          this.smallslider = false;
          this.placeSliders();
        } else {
          this.smallslider = false;
          
        }
      }
    },
      
    placeSliders: function() {
      this.updateSlider();
      
      var endChangePos = this.calcEndposition(this.changeA1cValue);
      var elem=d3.select("#changePointer");
      d3.selectAll("#changePointer:last-of-type").attr("transform", "translate("+endChangePos+", 0)");
    
      if (this.isSummary!==true) {
        var endPos = this.calcEndposition(this.currentA1cValue);
        d3.select("#currentPointer").attr("transform", "translate("+endPos+", 0)");
        this.$refs.currentText.style.left= endPos-40+'px';
        var textColor= (this.currentA1cValue>7)?"#f15a24":"#009245";
        d3.select(".current-text").style("color", textColor);
          
        var endTargetPos = this.calcEndposition(this.targetA1cValue);
        d3.select("#targetPointer").attr("transform", "translate("+endTargetPos+", 0)");
        this.$refs.targetText.style.left= endTargetPos-30+'px';
        d3.select(".target-text").style("color", "#009245");
      }  

    },
    
    animateSliders: function() {
      setTimeout(() => {
    
      if(d3.select("#currentPointer")===undefined) {
      return true;
      } 
      
      var startPos = this.getTransform(d3.select("#currentPointer") )[0];
      var endPos = this.calcEndposition(this.currentA1cValue);
      var currduration=9*(endPos-startPos);
      d3.select("#currentPointer").transition()
      .ease(d3.easeLinear).duration(currduration).attr("transform", "translate("+endPos+", 0)");
      
      this.$refs.currentText.style.left= endPos-40+'px';
      var textColor= (this.currentA1cValue>7)?"#f15a24":"#009245";
      d3.select(".current-text").transition().duration(1000).delay(currduration).style("color", textColor);
      
      var startChangePos = this.getTransform(d3.select("#changePointer") )[0];
      var endChangePos = this.calcEndposition(this.changeA1cValue);
      var changeduration=9*(endChangePos-startChangePos);
      d3.select("#changePointer").transition().ease(d3.easeLinear).duration(changeduration).attr("transform", "translate("+endChangePos+", 0)");
      
      var startTargetPos = this.getTransform(d3.select("#targetPointer") )[0];
      var endTargetPos = this.calcEndposition(this.targetA1cValue);
      var targetduration=9*(endTargetPos-startTargetPos);
      d3.select("#targetPointer").transition().ease(d3.easeLinear).duration(targetduration)
      .delay(700).attr("transform", "translate("+endTargetPos+", 0)");
      
      this.$refs.targetText.style.left= endTargetPos-30+'px';
      d3.select(".target-text").transition().duration(1500).delay(targetduration+700).style("color", "#009245");
      this.animationdone=true; 
      }, 200); 
    },
    
    placeSlidersSmall: function() {
      // this.updateSlider();
      var endChangePos = this.calcEndpositionSm(this.changeA1cValue);
      // endChangePos = endChangePos<0?0:endChangePos>627?627:endChangePos;
      console.log("vertical change slider at: "+endChangePos);
      // if (this.displayExercise) {
      if (this.sliderwidth>0) {
      d3.selectAll("#changePointerSm:last-of-type").attr("transform", "translate(0, "+endChangePos+")");
      }
    
      // d3.selectAll("#changePointerSm:last-of-type").attr("stroke", "red");
      // }
      
      // if (this.displayWeight) {
      // d3.select("#changePointerSm").attr("transform", "translate(0, "+endChangePos+")");
      // d3.select("#changePointerSm").attr("stroke", "pink");
      // }
      // d3.selectAll("#changePointerSm:last-of-type").attr("stroke", "red");
      if (this.isSummary!==true) {
        var endPos = this.calcEndpositionSm(this.currentA1cValue);
        // endPos = endPos<0?0:endPos>627?627:endPos;
        d3.select("#currentPointerSm").attr("transform", "translate(0, "+endPos+")");
        
        var diff=this.currentA1cValue - this.targetA1cValue;
        var variation=(diff < 0 && diff > -0.5)?-5:(diff > 0 && diff < 1)?15:(diff === 0)?-11:0;
        this.$refs.currentTextSm.style.top= endPos+variation+'px';
        
        var textColor= (this.currentA1cValue>7)?"#f15a24":"#009245";
        d3.select(".current-text-sm").style("color", textColor);
        
        var endTargetPos = this.calcEndpositionSm(this.targetA1cValue);
        d3.select("#targetPointerSm").attr("transform", "translate(0, "+endTargetPos+")");
        
        var targetVariation=(diff < 0 && diff > -0.5)?7+((0.5+diff)*50)+(28-this.$refs.targetTextSm.clientHeight):
        (diff > 0 && diff < 1)?-5-((1-diff)*10)+(24-this.$refs.targetTextSm.clientHeight):(diff === 0)?25:10;
        this.$refs.targetTextSm.style.top= endTargetPos+targetVariation+'px';
        d3.select(".target-text-sm").style("color", "#009245");
      }
      
    },
    
    animateSlidersSmall: function() {
      setTimeout(() => {
    
      if(d3.select("#currentPointerSm")===undefined) {
      return true;
      } 
      
      var startPos = this.getTransform(d3.select("#currentPointerSm") )[1];
      var endPos = this.calcEndpositionSm(this.currentA1cValue);
      // endPos = endPos<0?0:endPos>627?627:endPos;
      var currduration=9*(endPos-startPos);
      d3.select("#currentPointerSm").transition().ease(d3.easeLinear).duration(currduration).attr("transform", "translate(0, "+endPos+")");
      
      var diff=this.currentA1cValue - this.targetA1cValue;
      var variation=(diff < 0 && diff > -0.5)?-5:(diff > 0 && diff < 1)?15:(diff === 0)?-11:0;
      this.$refs.currentTextSm.style.top= endPos+variation+'px';
      
      var textColor= (this.currentA1cValue>7)?"#f15a24":"#009245";
      d3.select(".current-text-sm").transition().duration(1000).delay(currduration).style("color", textColor);
      
      var startChangePos = this.getTransform(d3.select("#changePointerSm"))[1];
      var endChangePos = this.calcEndpositionSm(this.changeA1cValue);
      // endChangePos = endChangePos<0?0:endChangePos>627?627:endChangePos;
      var changeduration=9*(endChangePos-startChangePos);
      d3.select("#changePointerSm").transition().ease(d3.easeLinear).duration(changeduration).attr("transform", "translate(0, "+endChangePos+")");
      
      var startTargetPos = this.getTransform(d3.select("#targetPointerSm") )[1];
      var endTargetPos = this.calcEndpositionSm(this.targetA1cValue);
      var targetduration=9*(endTargetPos-startTargetPos);
      d3.select("#targetPointerSm").transition().ease(d3.easeLinear).duration(targetduration)
      .delay(700).attr("transform", "translate(0, "+endTargetPos+")");
      
      var targetVariation=(diff < 0 && diff > -0.5)?7+((0.5+diff)*50)+(28-this.$refs.targetTextSm.clientHeight):
        (diff > 0 && diff < 1)?-5-((1-diff)*10)+(24-this.$refs.targetTextSm.clientHeight):
        (diff === 0)?25:10;
      this.$refs.targetTextSm.style.top= endTargetPos+targetVariation+'px'; 
      d3.select(".target-text-sm").transition().duration(1500).delay(targetduration+700).style("color", "#009245");
      this.animationdone=true; 
      }, 200); 
    }
  }
};
</script>

<style lang="scss" scoped>
   
</style>