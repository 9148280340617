<template>
  <b-row class="no-gutters">
    <b-col cols="12">
    <codHeader v-bind:pageTitle="pageTitle"></codHeader>
    </b-col>
    <b-col class="page-background-gradient" cols="12">
      <b-col class="page-content references reduce-padding" cols="12">
        <b-row class = "page-content no-gutters">
        <div class="scroll">
        <b-row class="no-gutters">
          <div class="reference-list">
          <ol>
            <li class ="top-txt"> American Diabetes Association. Standards of medical care in diabetes-2020. <i>Diabetes Care</i>. 2020;43(suppl 1):S1-S212.</li>
            <li>Clarke PM, Gray AM, Briggs A, et al. A model to estimate the lifetime health outcomes of patients with type 2 diabetes: the
            United Kingdom Prospective Diabetes Study (UKPDS) Outcomes Model (UKPDS no. 68). <i>Diabetologia</i>.<br>

            2004;47(10):1747-1759.</li>
              <li>Cleveland Clinic. Cholesterol: What You Need to Know About High Blood 
              Cholesterol. https://my.clevelandclinic<wbr>.org/health/articles/17100-cholesterol-what-you-need-to-know-about-high-blood-
              cholesterol. Accessed 7.17.2020.</li>
            <li>Diabetes complications. International Diabetes Federation website. https://www.idf.org/aboutdiabetes<wbr>/complications.html.
            Accessed November 27, 2019.</li>
            <li>Davis TME. Ethnic diversity in type 2 diabetes. <i>Diabetic Medicine</i>. 2008;(Suppl 2):52-56.</li>
            <li>Garber AJ, Abrahamson MJ, Barzilay JI, et al. Consensus statement by the American Association of Clinical Endocrinologists
            and American College of Endocrinology on the comprehensive type 2 diabetes management algorithm - 2019 executive 
            summary. <i>Endocr Pract</i>. 2019;25(1):69-101.</li>
            <li>Hayes AJ, Leal J, Gray AM, Holman RR, Clark PM. UKPDS outcomes model 2: a new version of a model to simulate lifetime
            health outcomes of patients with type 2 diabetes mellitus using data from the 30 year United Kingdom prospective diabetes
            study: UKPDS 82. <i>Diabetologia</i>. 2013;56(9):1925-1933.</li>
            <li>Jensen MD, Ryan DH, Apovian CM, et al. 2013 AHA/ACC/TOS guideline for the management of overweight and obesity in
            adults: a report of the American College of Cardiology/American Heart Association task force on practice guidelines and the
            obesity society. <i>Circulation</i>. 2014;63(25 Pt B):2985-3023.</li>
            <li>NDEP. 4 steps to manage your diabetes for life. <i>National Diabetes Education Program.</i> 2016;NDEP-67:1-20.</li>
           <!--<li>`MedlinePlus. High blood cholesterol levels. https://medlineplus.gov/ency/article<wbr>/000403.htm. Accessed 7.10.2020.</li>-->
            <!--<li>Rosenson RS. Patient education: High cholesterol and lipds (Beyond the Basics). Up To Date website. -->
            <!--https://www.uptodate.com/contents<wbr>/high-cholesterol-and-lipids-beyond-the<wbr>-basics. Accessed October 29, 2019.</li>-->
            <li>UK Prospective Diabetes Study Group. UK prospective diabetes study (UKPDS). <i>Diabetologia</i>. 1991;34:887-890.</li>
            <li>Wing RR, Lang W, Wadden TA, et al. Benefits of modest weight loss in improving cardiovascular risk factors in overweight 
            and obese individuals with type 2 diabetes. <i>Diabetes Care</i>. 2011;34:1481-1486.</li>
            </ol>
          </div>
        </b-row>
        </div>
        </b-row>
      </b-col>
      <codNavigation v-bind:navButtons="['previous']"></codNavigation>
    </b-col>
  </b-row>
</template>
<script>
/* eslint-disable */
  import codHeader from '../components/codHeader.vue';
  import codNavigation from '../components/codNavigation.vue';
  import scrollMixin from '../mixins/scrollMixin';
  
export default {
  name: "references",
   mixins: [scrollMixin],
     mounted: function() {
      this.scrollToTop();
    },
  components: {
      'codHeader': codHeader,
      codNavigation : codNavigation,
    },

  data() {
    return {
      pageTitle: 'References'
    };
  }
};
</script>


<style lang="scss" scoped>
</style>
