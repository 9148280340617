/* eslint-disable */
import Vue from 'vue';
import VueRouter from 'vue-router';
import 'babel-polyfill';

import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import Vuex from 'vuex';

import Cod from '@/Cod';
import routes from "@/routes";
import "@/assets/styles/main.scss";
import CodStore from '@/store/index';
import VueConfig from '@/classes/config/index';
import 'bootstrap/dist/css/bootstrap.css';
import * as Resize from 'iframe-resizer/js/iframeResizer.contentWindow';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import 'd3/dist/d3.js';
import VueTheMask from 'vue-the-mask';
import vueScrollto from 'vue-scrollto';


export function main(el, apiKey, serviceUrl, locationId) {
  Vue.use(VueRouter);
  Vue.use(Vuex);
  Vue.use(BootstrapVue);
  Vue.use(Vuelidate);
  Vue.use(Resize);
  Vue.use(vueScrollto);
  Vue.use(VueTheMask)
  Vue.component('VueSlider', VueSlider)

  Vue.use(VueConfig, {
    config: {
      apiKey: apiKey,
      serviceUrl: serviceUrl,
      locationId: locationId
    }
  });

  const router = new VueRouter({
    routes
  });

  new Vue({
    render: h => h(Cod),
    router: router,
    store: new CodStore()
  }).$mount(el);

}
