<template>
<b-row class="no-gutters">
  <b-col cols="12">
    <codHeader v-bind:pageTitle="pageTitle"></codHeader>
  </b-col>
  <b-col class="page-background-gradient" cols="12">
    <b-col class="page-content email reduce-padding" cols="12">
      
        <div class="page-content">
          <div class="envelop-img email-img"></div>
          <div class="main-txt">Complete the fields below to email a link and brief description of the <i>Complications of Type 2 Diabetes</i> tool to colleagues who may be interested in this tool (the results are not shared).<span class="double-spacing"></span>Click “Submit” to continue or “Back” to return to your
            patient’s results.</div>
            <form @submit.prevent="handleSubmit">
          <div class="email-sec cmn-sec">
            <div class="txt bold">Destination Email Address: </div>
            <b-row class="no-gutters">
              <b-row class="form-group no-gutters row-width" :class="{'haserror': submitted && ((!$v.emailiD.required) || (!$v.emailiD.email)) }">
                <b-col lg="6" md="8" sm="12" class="reduce-padding field-size">

                  <input class="common-field form-control" type="email" id="emailiD" name="emailiD" v-model="emailiD" placeholder="Type Here..." :class="{ 'is-invalid': submitted && $v.emailiD.$error }">

                </b-col>
                <b-col  lg="6" md="4" sm="12" class="reduce-padding  ">
                  <div class="error" v-if="submitted && (!$v.emailiD.required)">Valid entry required.</div>
                  <div class="error" v-if="submitted && !$v.emailiD.email">Email is invalid.</div>
                </b-col>
              </b-row>

            </b-row>
          </div>
          <div class="name-sec cmn-sec">
            <div class="txt"><span class="bold">Name</span> (to let colleagues know who is sending this email):</div>
            <b-row class="no-gutters">
              <b-row class="form-group no-gutters row-width" :class="{'haserror': submitted && (!$v.friendName.required  || !$v.friendName.alpha)}">
                <b-col lg="6" md="8" sm="12" class="reduce-padding field-size">
                  <input class="common-field form-control" type="text" v-model="friendName" placeholder="Type Here..." :class="{ 'is-invalid': submitted && $v.friendName.$error }">
                </b-col>
                <!--<b-col v-if="submitted && (!$v.friendName.required  || !$v.friendName.alpha)" lg="6" md="4" sm="12"-->
                <!--class="reduce-padding error">-->
                <!--  Valid entry required.-->
                <!--  </b-col>-->
                    <b-col  lg="6" md="4" sm="12" class="reduce-padding  ">
                  <div class="error" v-if="submitted && (!$v.friendName.required  || !$v.friendName.alpha)">Valid entry required.</div>
                </b-col>
              </b-row>
            </b-row>
          </div>
          <div class="form-group submit submit-class">
            <button @click.prevent="handleSubmit($event, emailiD, friendName)" class="submit-button">
              <span class="btn-text">SUBMIT</span>
            </button>
          </div>
          </form>
          <div class="note"><span class="bold">Please Note:</span><span class="double-spacing"></span>We will not use the information you have provided here for any purpose other than to send this email.</div>
        </div>
      
    </b-col>
    <codNavigation v-bind:navButtons="['previous']"></codNavigation>
  </b-col>
</b-row>
</template>
<script>
  /* eslint-disable */
  import codHeader from '../components/codHeader.vue';
  import codNavigation from '../components/codNavigation.vue';
  import { required, email, minLength, alpha } from "vuelidate/lib/validators";
  import scrollMixin from '../mixins/scrollMixin';
import taggingMixin from '../mixins/taggingMixin';
  import axios from 'axios';

  export default {
    name: "email",
    mixins: [scrollMixin, taggingMixin],
    mounted: function() {
      this.scrollToTop();
    },
    components: {
      'codHeader': codHeader,
      'codNavigation': codNavigation,
    },

    data() {
      return {
        pageTitle: 'Share a Link Via Email',
        emailiD: "",
        friendName: "",
        submitted: false
      };
    },
    validations: {
      friendName: {
        required,
        alpha
      },
      emailiD: {
        required,
        email
      },
    },

    methods: {
      handleSubmit(e, email, name) {
        console.log(arguments);
        this.submitted = true;
        // stop here if form is invalid
        //this.$v.$touch();
        if (this.$v.$invalid) {
          this.scrollToError();
          return;
        }
        else {
          const config = this.$config.config;
          const serviceUrl = config.serviceUrl;
          const locationId = parseInt(config.locationId);
          axios({
              method: 'post',
              url: `${serviceUrl}/mail/v1/share`,
              data: {
                appId: config.apiKey,
                name: name,
                to: email,
                location: locationId
              },
              headers: { 'X-App-ID': config.apiKey }
            })
            .then(response => {
              this.tagNavigation('submit', 'email');
              this.$router.go(-1);
            })
            .catch(e => {
              // TODO display error on page.
              console.log(e);
            })
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .envelop-img {
    background-image: url("../images/icon-svg/envolop_image.svg");

  }
</style>
