/* eslint-disable */

export default {
    methods: {
      scrollToTop: function() {
        setTimeout(() => {
        if ('parentIFrame' in window) {
          parentIFrame.scrollToOffset(0, 0);  
        }
        window.document.getElementById('cod').scrollIntoView();
      }, 50);
      },
      scrollToError: function() {
        this.$nextTick(function () {
          document.querySelector('.haserror').scrollIntoView();
          // scroll to error for iphones
          if ('parentIFrame' in window) {
             var elmTop = document.querySelector('.haserror').getBoundingClientRect().top;
              if (elmTop > 0) {
                  parentIFrame.scrollToOffset(0, elmTop-200); 
              }
          }
        });
      },
      
      
    }
  };