/* eslint-disable */

export default {
    data() {
        return {
        	calcsConst: {
			    MALE: 'male',
			    FEMALE: 'female',
			    YesNo: {
				    YES : 'YES',
				    NO: 'NO'
			    },
				YES: 'YES', 
				NO: 'NO',
				BMI_CHANGE_CUTOFF: 25
        	},
        	
            	        /* IHD	   MI	  CHF     STRK	  AMP	  BLND	  RENAL */
            LAMBDA   : [-5.310, -4.977, -8.018, -7.163, -8.718, -6.464, -10.016] ,
            LAMBDASE : [ 0.174,  0.16,   0.408,  0.342,  0.613,  0.326, 0.939] ,
            RHO      : [ 1.150,  1.257,  1.711,  1.497,	1.451,	1.154, 	1.865] ,
            RHOSE 	 : [ 0.067,  0.06,  0.158,  0.126,	0.232,	0.121, 	0.387] ,
            AGE 	 : [ 0.031,  0.055, 0.093,  0.085, 	  0.0, 	0.069, 	  0.0] ,
            AGESE 	 : [ 0.008,  0.006, 0.016,  0.014, 	  0.0, 	0.014, 	  0.0] ,
            RACE     : [ 0.0,   -1.312,   0.0,  0.0,    0.0,	  0.0,	  0.0] ,
            RACESE   : [ 0.0,    0.341,  0.0,    0.0,    0.0,	  0.0,	  0.0] ,
            FEMALE   : [-0.471, -0.826,   0.0,  -0.516,    0.0,	  0.0,	  0.0] ,
            FEMALESE : [ 0.143,  0.103,   0.0,  0.171,    0.0,	  0.0,	  0.0] ,
            SMOKE 	 : [ 0.0,    0.346,   0.0,  0.355,	  0.0,	  0.0,	  0.0] ,
            SMOKESE  : [ 0.0,    0.097,   .0,    0.179,	  0.0,	  0.0,	  0.0] ,
            BMI		 : [ 0.0,    0.0,    0.066,   0.0,	  0.0,	  0.0,	  0.0] ,
            BMISE	 : [ 0.0,    0.0,    0.017,   0.0,	  0.0,	  0.0,	  0.0] ,	
            A1C		 : [ 0.125,  0.118,  0.157,  0.128,	0.435,	0.221,	  0.0] ,
            A1CSE	 : [ 0.035,  0.025,  0.057,  0.042,	0.066,	 0.05,	  0.0] ,
            SBP		 : [ 0.098,  0.101,  0.114,  0.276,	0.228,	  0.0,	0.404] ,
            SBPSE	 : [ 0.037,  0.026,  0.056,  0.042,	0.075,	  0.0,	0.106] ,
            HDL		 : [ 0.0,    0.0,     0.0,   0.133,    0.0,	  0.0,	  0.0] ,
            HDLSE	 : [ 0.0,    0.0,     0.0,   0.025,    0.0,	  0.0,	  0.0] ,
            LN_HDL	 : [ 1.498,  1.190,    0.0,	  0.0,	  0.0,	  0.0,	  0.0] ,
            LN_HDLSE : [ 0.202,  0.169,    0.0,	  0.0,	  0.0,	  0.0,	  0.0] ,
            PVD		 : [ 0.0,    0.0,     0.0,	  0.0,	2.436,	  0.0,	  0.0] ,
            PVDSE	 : [ 0.0,    0.0,     0.0,	  0.0,	0.521,	  0.0,	  0.0] ,
            AF	  	 : [ 0.0,    0.0,     0.0,    1.428,  0.0,	  0.0,	  0.0] ,
            AFSE	 : [ 0.0,    0.0,     0.0,    0.472,  0.0,	  0.0,	  0.0] ,
            IHD		 : [ 0.0,   0.914,    0.0,	  0.0,	  0.0,	  0.0,	  0.0] ,
            IHDSE	 : [ 0.0,   0.15,     0.0,	  0.0,	  0.0,	  0.0,	  0.0] ,
            CHF		 : [ 0.0,   1.558,    0.0,  1.742,	  0.0,	  0.0,	  0.0] ,
            CHFSE	 : [ 0.0,   0.202,    0.0,  0.287,	  0.0,	  0.0,	  0.0] ,
            BLIND	 : [ 0.0,    0.0,     0.0,	  0.0,	1.812,	  0.0,	2.082] ,
            BLINDSE  : [ 0.0,    0.0,     0.0,	  0.0,	0.462,	  0.0,	0.551] ,	
					 /*  IHD	  MI	  CHF	  STRK	  AMP	  BLND	RENAL */
        };
    },
    
    methods: {
      	getRiskAtYear: function( age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression,  years ,ismod) {
	  
	  var yearsRisk = [];
	  var yearsRisktoFuture = [];
	  var yearsRiskplusFuture = [];
	  var pEvent = [];
	  var expVal= [];
	  var rho = 0.0;
	  var timesincedx = age-diagnonsisage;
	  
	  expVal[0] =  this.getRiskAtYearWithOtherCodition(age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression, 1, years, ismod);
	  expVal[1]=  this.getRiskAtYearWithOtherCodition(age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression, 3, years, ismod);
	  expVal[2]=  this.getRiskAtYearWithOtherCodition(age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression, 5, years, ismod);
	  expVal[3]=  this.getRiskAtYearWithOtherCodition(age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression, 6, years, ismod);
	  expVal[4]=  this.getRiskAtYearWithOtherCodition(age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression, 4, years, ismod);
	  
	  //Heart Attack
	  rho =  this.RHO[1];
	  yearsRisk[0] = expVal[0] * Math.pow(timesincedx , rho);
	  yearsRisktoFuture[0] = expVal[0] * Math.pow((timesincedx+years) , rho);
	  yearsRiskplusFuture[0] = Math.exp((yearsRisk[0] - yearsRisktoFuture[0]));
	  pEvent[0] = parseFloat((1 - yearsRiskplusFuture[0])*100).toFixed(1);
		
	  //STROKE
	  rho =  this.RHO[3];
	  yearsRisk[1] = expVal[1] * Math.pow(timesincedx , rho);
	  yearsRisktoFuture[1] = expVal[1] * Math.pow((timesincedx+years) , rho);
	  yearsRiskplusFuture[1] = Math.exp((yearsRisk[1] - yearsRisktoFuture[1]));
	  pEvent[1] = parseFloat((1 - yearsRiskplusFuture[1])*100).toFixed(1);
		
	  //BLINDNESS
	  rho =  this.RHO[5];
	  yearsRisk[2] = expVal[2] * Math.pow(timesincedx , rho);
	  yearsRisktoFuture[2] = expVal[2] * Math.pow((timesincedx+years) , rho);
	  yearsRiskplusFuture[2] = Math.exp((yearsRisk[2] - yearsRisktoFuture[2]));
	  pEvent[2] = parseFloat((1 - yearsRiskplusFuture[2])*100).toFixed(1);
		
	  //Kidney Failure
	  rho =  this.RHO[6];
	  yearsRisk[3] = expVal[3] * Math.pow(timesincedx , rho);
	  yearsRisktoFuture[3] = expVal[3] * Math.pow((timesincedx+years) , rho);
	  yearsRiskplusFuture[3] = Math.exp((yearsRisk[3] - yearsRisktoFuture[3]));
	  pEvent[3] = parseFloat((1 - yearsRiskplusFuture[3])*100).toFixed(1);
		
	  //Amputation
	  rho =  this.RHO[4];
	  yearsRisk[4] = expVal[4] * Math.pow(timesincedx , rho);
	  yearsRisktoFuture[4] = expVal[4] * Math.pow((timesincedx+years) , rho);
	  yearsRiskplusFuture[4] = Math.exp((yearsRisk[4] - yearsRisktoFuture[4]));
	  pEvent[4] = parseFloat((1 - yearsRiskplusFuture[4])*100).toFixed(1);
		
	  return pEvent;
	},
	
	getRiskAtYearWithOtherCodition: function( age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression, type,  years,  isMod) {
		
		//Consts::
		var lambda 			=  this.LAMBDA[type];
		var ageConst 		=  this.AGE[type];
		var genderConst     =  this.FEMALE[type];
		var smokeConst 	    =  this.SMOKE[type];
		var bmiConst 		=  this.BMI[type];
		var a1cConst 		=  this.A1C[type];
		var sbpConst 		=  this.SBP[type];
		var hdlConst 		=  this.HDL[type];
		var lnHdlConst 	    =  this.LN_HDL[type];
		var pvdConst 		=  this.PVD[type];
		var afConst 		=  this.AF[type];
		var ihdConst 		=  this.IHD[type];
		var chfConst 		=  this.CHF[type];
		var blindConst 	    =  this.BLIND[type];
		
		
		var totCholval 	= isMod ? adjtotalChl : totalChl;
		
		var isSmoke = smoking=== 'Yes'? 1.0 : 0.0;
		var adjisSmoke = adjsmoking ==='Yes' ? 1.0 : 0.0;
		
		var smokeVal	= isMod ?adjisSmoke: isSmoke;
		
		var bmiVal 		= this.getBmi(height, weight) - 27.77;
		var a1cVal 		= (isMod ? adjbloodSugar : bloodSugar) - 7.09;
		var sbpVal 		= ((isMod ? adjsbp: sbp) - 135.09) / 10;

     	var hdlChol = isMod ? adjhdl : hdl;
		
		//check user input:
		if (diagnonsisage< 0 || (gender=== undefined)|| totalChl < 0.0 || hdl < 0.0 || this.getBmi(height,weight) < 0.0 || bloodSugar < -7.0 || sbp < - 13.0) {
			return -1.0;
		}
				
		var ageDaig 	= diagnonsisage;
		var ageVal 	= ageDaig - 52.59;
		var genderVal 	= gender === this.calcsConst.MALE ? 0 : 1;
		var pvdVal 		= vasculardisease ? 1 : 0;
		var afVal 		= arterialfibrillation ? 1 : 0;
		var ihdVal 		= heartdisease ? 1 : 0;
		var chfVal 		= heartfailure ? 1 : 0;
		var blindVal 	= blindness ? 1 : 0;
		
		// smoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression,
		
		
		if (hdlChol === 0.0) {
			hdlChol = 1.0;
		}
		
		var hdlVal 		= (totCholval / hdlChol) - 5.23;
		var lnHdlVal 	= hdlVal > 1 ? Math.log(hdlVal) : 0;
		
		var valToExp = lambda + 
							  (ageConst * ageVal) +
							  (genderConst * genderVal) +
							  (smokeConst * smokeVal) +
							  (bmiConst * bmiVal) +
							  (a1cConst * a1cVal) +
							  (sbpConst * sbpVal) +
							  (hdlConst * hdlVal) +
							  (lnHdlConst * lnHdlVal) +
							  (pvdConst * pvdVal) +
							  (afConst * afVal) +
							  (ihdConst * ihdVal) +
							  (chfConst * chfVal) +
							  (blindConst * blindVal);
		var expVal = Math.exp(valToExp);
		
		return expVal;
 		
	},
	
	getConfidenceIntervals: function(age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression,  years ,ismod) {
		var confInterval = [];
		confInterval[0] = this.calcConfidenceInterval(age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression, 1, years, ismod);
    confInterval[1] =  this.calcConfidenceInterval(age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression, 3, years, ismod);
    confInterval[2] =  this.calcConfidenceInterval(age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression, 5, years, ismod);
    confInterval[3] =  this.calcConfidenceInterval(age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression, 6, years, ismod);
	  confInterval[4] =  this.calcConfidenceInterval(age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression, 4, years, ismod);
		
		return confInterval;
	},
	
	calcConfidenceInterval: function( age, height, weight, gender,  bloodSugar, sbp, totalChl, hdl, diagnonsisage,  smoking, adjbloodSugar, adjsbp, adjtotalChl, adjhdl,  adjsmoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression, type,  years,  isMod) {
		
		//Consts::
		var lambda 			=  this.LAMBDA[type];
		var rho 			  =  this.RHO[type];
		var ageConst 		=  this.AGE[type];
		var raceConst   =  this.RACE[type];
		var genderConst =  this.FEMALE[type];
		var smokeConst  =  this.SMOKE[type];
		var bmiConst 		=  this.BMI[type];
		var a1cConst 		=  this.A1C[type];
		var sbpConst 		=  this.SBP[type];
		var hdlConst 		=  this.HDL[type];
		var lnHdlConst 	=  this.LN_HDL[type];
		var pvdConst 		=  this.PVD[type];
		var afConst 		=  this.AF[type];
		var ihdConst 		=  this.IHD[type];
		var chfConst 		=  this.CHF[type];
		var blindConst 	=  this.BLIND[type];
		var lambdase 		  =  this.LAMBDASE[type];
		var rhose 			  =  this.RHOSE[type];
		var ageConstse 		=  this.AGESE[type];
		var raceConstse   =  this.RACESE[type];
		var genderConstse =  this.FEMALESE[type];
		var smokeConstse  =  this.SMOKESE[type];
		var bmiConstse 		=  this.BMISE[type];
		var a1cConstse 		=  this.A1CSE[type];
		var sbpConstse 		=  this.SBPSE[type];
		var hdlConstse 		=  this.HDLSE[type];
		var lnHdlConstse 	=  this.LN_HDLSE[type];
		var pvdConstse 		=  this.PVDSE[type];
		var afConstse 		=  this.AFSE[type];
		var ihdConstse 		=  this.IHDSE[type];
		var chfConstse 		=  this.CHFSE[type];
		var blindConstse 	=  this.BLINDSE[type];
		
		var totCholval 	= isMod ? adjtotalChl : totalChl;
		
		var isSmoke = smoking=== 'Yes'? 1.0 : 0.0;
		var adjisSmoke = adjsmoking ==='Yes' ? 1.0 : 0.0;
		
		var smokeVal	= isMod ?adjisSmoke: isSmoke;
		
		var bmiVal 		= this.getBmi(height, weight) - 27.77;
		var a1cVal 		= (isMod ? adjbloodSugar : bloodSugar) - 7.09;
		var sbpVal 		= ((isMod ? adjsbp: sbp) - 135.09) / 10;

    var hdlChol = isMod ? adjhdl : hdl;
		
		//check user input:
		if (diagnonsisage< 0 || (gender=== undefined)|| totalChl < 0.0 || hdl < 0.0 || this.getBmi(height,weight) < 0.0 || bloodSugar < -7.0 || sbp < - 13.0) {
			return -1.0;
		}
				
		var ageDaig 	= diagnonsisage;
		var ageVal 	= ageDaig - 52.59;
		var genderVal 	= gender === this.calcsConst.MALE ? 0 : 1;
		var pvdVal 		= vasculardisease ? 1 : 0;
		var afVal 		= arterialfibrillation ? 1 : 0;
		var ihdVal 		= heartdisease ? 1 : 0;
		var chfVal 		= heartfailure ? 1 : 0;
		var blindVal 	= blindness ? 1 : 0;
		var ageatdx   = age - diagnonsisage;
		
		// smoking, exercise,  heartdisease, heartfailure,  arterialfibrillation, blindness,  vasculardisease,  hypoglycemia,  depression,
		if (hdlChol === 0.0) {
			hdlChol = 1.0;
		}
		
		var hdlVal 		= (totCholval / hdlChol) - 5.23;
		var lnHdlVal 	= hdlVal > 1 ? Math.log(hdlVal) : 0;
		var pEventsim = [];
		for(var i=0; i<1000; i++) {
			var lambdaConstval = this.normsInv(Math.random(), lambda, lambdase);
			var rhoConstval = this.normsInv(Math.random(), rho, rhose);
			var ageConstval = this.normsInv(Math.random(), ageConst, ageConstse);
			var genderConstval = this.normsInv(Math.random(), genderConst, genderConstse);
		  var raceConstval = this.normsInv(Math.random(), raceConst, raceConstse);
		  var smokeConstval = this.normsInv(Math.random(), smokeConst, smokeConstse);
		  var bmiConstval = this.normsInv(Math.random(), bmiConst, bmiConstse);
		  var a1cConstval = this.normsInv(Math.random(), a1cConst, a1cConstse);
		  var sbpConstval = this.normsInv(Math.random(), sbpConst, sbpConstse);
		  var hdlConstval = this.normsInv(Math.random(), hdlConst, hdlConstse);
		  var lnHdlConstval = this.normsInv(Math.random(), lnHdlConst, lnHdlConstse);
		  var pvdConstval = this.normsInv(Math.random(), pvdConst, pvdConstse);
		  var afConstval = this.normsInv(Math.random(), afConst, afConstse);
		  var ihdConstval = this.normsInv(Math.random(), ihdConst, ihdConstse);
		  var chfConstval = this.normsInv(Math.random(), chfConst, chfConstse);
		  var blindConstval = this.normsInv(Math.random(), blindConst, blindConstse);
		  
			var valToExp = lambdaConstval + 
								  (ageConstval * ageVal) +
								  (genderConstval * genderVal) +
								  (raceConstval * 0.0) +
								  (smokeConstval * smokeVal) +
								  (bmiConstval * bmiVal) +
								  (a1cConstval * a1cVal) +
								  (sbpConstval * sbpVal) +
								  (hdlConstval * hdlVal) +
								  (lnHdlConstval * lnHdlVal) +
								  (pvdConstval * pvdVal) +
								  (afConstval * afVal) +
								  (ihdConstval * ihdVal) +
								  (chfConstval * chfVal) +
								  (blindConstval * blindVal);
			
			var expVal = Math.exp(valToExp);
			var riskatyear = expVal * Math.pow(ageatdx, rhoConstval);
			var riskatyearinfuture = expVal * Math.pow((ageatdx + years), rhoConstval);
			var riskatyearplusfuture = Math.exp((riskatyear - riskatyearinfuture));
		  pEventsim.push(parseFloat(1-riskatyearplusfuture).toFixed(4));
		}
		
	  pEventsim.sort(function (a, b) { 
    	return a - b;
		});
		
		return [pEventsim[24], pEventsim[974]];
 		
	},	
	
		
	 getBmi: function( height,  weight) {
		
		if ( (height === 0.0) || (weight === 0.0) ) {
			return -1.0;
		}
		
		var bmi = 0.0;			
	
		// convert inches to meters
		height = height * 0.0254;
		
		// perform bmi calculation on the height in meters and weight in kilograms
		bmi = weight * 0.45359237/ Math.pow(height,2);			
			
		bmi = Math.round(bmi * 10) / 10;
		
		return bmi;
	},
	
	 getA1cReduction: function(exercise, willLoseWeight, willExercise, bmi) {
		
		var a1cReductionW = 0.0;
		var a1cReductionE = 0.0;
		
		if ((bmi >= this.calcsConst.BMI_CHANGE_CUTOFF) && (willLoseWeight !== undefined) && (willLoseWeight === this.calcsConst.YES)) {
			a1cReductionW = 0.5;
		}
		
		if ((exercise !== undefined) && (exercise === 'No') && (willExercise !== undefined) && (willExercise === this.calcsConst.YES)) {
			a1cReductionE = 0.7;
		
		}
		return [a1cReductionW, a1cReductionE];
	},
	
	getA1cCalcGoal: function( curA1c,  a1cReduction) {
		return curA1c - a1cReduction;
	},
	
	
	getWeightLossAmt: function( curWeight,  bmi) {
		if (curWeight < 0 || bmi < 0) {
			return -1.0;
		}
		else if (bmi >= 25) {
			return curWeight * 0.1;
		}
		else {
			return 0.0;
		}
	},
	
	getWeightLossGoal: function( curWeight,  rcmdWeightLoss) {
		if (curWeight < 0) {
			return -1.0;
		}
		return curWeight - rcmdWeightLoss;
	},
	
	normsInv: function(p, mu, sigma)
	{
    if (p < 0 || p > 1)
    {
        console.log('The probality p must be bigger than 0 and smaller than 1');
    }
    if (sigma < 0)
    {
        console.log('The standard deviation sigma must be positive');
    }
 
    if (p === 0)
    {
        return -Infinity;
    }
    if (p === 1)
    {
        return Infinity;
    }
    if (sigma === 0)
    {
        return mu;
    }
 
    var q, r, val;
 
    q = p - 0.5;
 
    /*-- use AS 241 --- */
    /* double ppnd16_(double *p, long *ifault)*/
    /*      ALGORITHM AS241  APPL. STATIST. (1988) VOL. 37, NO. 3

            Produces the normal deviate Z corresponding to a given lower
            tail area of P; Z is accurate to about 1 part in 10**16.
    */
    if (Math.abs(q) <= 0.425)
    {/* 0.075 <= p <= 0.925 */
        r = 0.180625 - q * q;
        val =
               q * (((((((r * 2509.0809287301226727 +
                          33430.575583588128105) * r + 67265.770927008700853) * r +
                        45921.953931549871457) * r + 13731.693765509461125) * r +
                      1971.5909503065514427) * r + 133.14166789178437745) * r +
                    3.387132872796366608) / 
                    (((((((r * 5226.495278852854561 +
                        28729.085735721942674) * r + 39307.89580009271061) * r +
                      21213.794301586595867) * r + 5394.1960214247511077) * r +
                    687.1870074920579083) * r + 42.313330701600911252) * r + 1);
    }
    else
    { /* closer than 0.075 from {0,1} boundary */
 
        /* r = min(p, 1-p) < 0.075 */
        if (q > 0) {
            r = 1 - p;
        } else {
          r = p;
        }
 
        r = Math.sqrt(-Math.log(r));
        /* r = sqrt(-log(r))  <==>  min(p, 1-p) = exp( - r^2 ) */
 
        if (r <= 5)
        { /* <==> min(p,1-p) >= exp(-25) ~= 1.3888e-11 */
            r += -1.6;
            val = (((((((r * 7.7454501427834140764e-4 +
                       0.0227238449892691845833) * r + 0.24178072517745061177) *
                     r + 1.27045825245236838258) * r +
                    3.64784832476320460504) * r + 5.7694972214606914055) *
                  r + 4.6303378461565452959) * r +
                 1.42343711074968357734) / 
                 (((((((r *
                         1.05075007164441684324e-9 + 5.475938084995344946e-4) *
                        r + 0.0151986665636164571966) * r +
                       0.14810397642748007459) * r + 0.68976733498510000455) *
                     r + 1.6763848301838038494) * r +
                    2.05319162663775882187) * r + 1);
        }
        else
        { /* very close to  0 or 1 */
            r += -5;
            val = (((((((r * 2.01033439929228813265e-7 +
                       2.71155556874348757815e-5) * r +
                      0.0012426609473880784386) * r + 0.026532189526576123093) *
                    r + 0.29656057182850489123) * r +
                   1.7848265399172913358) * r + 5.4637849111641143699) *
                 r + 6.6579046435011037772) / 
                 (((((((r *
                         2.04426310338993978564e-15 + 1.4215117583164458887e-7) *
                        r + 1.8463183175100546818e-5) * r +
                       7.868691311456132591e-4) * r + 0.0148753612908506148525) * r + 
                       0.13692988092273580531) * r +
                    0.59983220655588793769) * r + 1);
        }
 
        if (q < 0.0)
        {
            val = -val;
        }
    }
 
    return mu + sigma * val;
	}
      
      
    }
  };
  
 