<template>
<b-row class="no-gutters">
  <b-col cols="12">
    <codHeader v-bind:pageTitle="pageTitle" v-bind:pageNumber="pageNumber"></codHeader>
  </b-col>
  <b-col class="page-background-gradient" cols="12">
    <b-col class="page-content questionnaire reduce-padding" cols="12">

      <!--health ridk sec-->
      <form @submit.prevent="handleSubmit">
        <b-row class="no-gutters">

          <div class="header-row">
            <div class="headings">
              The Questions below help estimate your patient’s health risks.
            </div>
          </div>

          <b-row class="body-row no-gutters">


            <b-col md="5" class="reduce-padding main-left-col">
              <div class="left-col1">

                <!--row-->
                <b-row class="no-gutters" :class="{'haserror': submitted && (!$v.userData.patientProfile.age.required || !$v.userData.patientProfile.age.numeric || 
                    !$v.userData.patientProfile.age.minLength || !$v.userData.patientProfile.age.maxLength || 
                    !$v.userData.patientProfile.age.between)}">
                  <b-col lg="6" sm="6" md="5" cols="5" class="reduce-padding label-col">
                    <div class="textfield-label">
                      Age:
                    </div>
                    <div v-if="submitted && (!$v.userData.patientProfile.age.required || !$v.userData.patientProfile.age.numeric || 
                    !$v.userData.patientProfile.age.minLength || !$v.userData.patientProfile.age.maxLength || !$v.userData.patientProfile.age.between)"
                    class="error">Enter value 25 - 90.
                    </div>

                  </b-col>

                  <b-col lg="6" sm="6" md="7" cols="7" class=" field-col">
                    <div class=""  >
                      <input  :class="{ 'input--error': $v.userData.patientProfile.age.$error}" type="text" maxLength="2" class="common-txt-field " v-model.number="userData.patientProfile.age" />
                    </div>
                  </b-col>

                </b-row>

                <!--row1-->
                <b-row class="no-gutters fields-spacing" :class="{'haserror': (submitted && (userData.patientProfile.diagnoseAge > userData.patientProfile.age)) 
                || (submitted && (!$v.userData.patientProfile.diagnoseAge.required || 
                !$v.userData.patientProfile.diagnoseAge.numeric || !$v.userData.patientProfile.diagnoseAge.minLength ||
                    !$v.userData.patientProfile.diagnoseAge.maxLength || !$v.userData.patientProfile.diagnoseAge.between) && 
                    !(isDiagnosisError))}">

                  <b-col lg="6" sm="6" md="5" cols="5" class="reduce-padding label-col">
                    <div class="textfield-label double-line-spacing">
                      Age at Diabetes Diagnosis:
                    </div>
                    <div v-if="submitted && (!$v.userData.patientProfile.diagnoseAge.required || !$v.userData.patientProfile.diagnoseAge.numeric || 
                    !$v.userData.patientProfile.diagnoseAge.minLength ||
                    !$v.userData.patientProfile.diagnoseAge.maxLength || !$v.userData.patientProfile.diagnoseAge.between)" class="error">Enter value 25 - 65.
                    </div>
                    <div v-if="submitted && (userData.patientProfile.diagnoseAge > userData.patientProfile.age) && !(!$v.userData.patientProfile.diagnoseAge.required || !$v.userData.patientProfile.diagnoseAge.numeric || 
                    !$v.userData.patientProfile.diagnoseAge.minLength ||
                    !$v.userData.patientProfile.diagnoseAge.maxLength || !$v.userData.patientProfile.diagnoseAge.between)" 
                    class="error">Valid entry required.
                    </div>
                  </b-col>

                  <b-col lg="6" sm="6" md="7" cols="7" class=" field-col">
                    <div class="">
                      <input type="text" class="common-txt-field " maxLength="2" v-model.number="userData.patientProfile.diagnoseAge" />
                    </div>
                  </b-col>

                </b-row>

                <!--row1-->
                <b-row class="no-gutters fields-spacing" :class="{'haserror': (submitted && (!$v.userData.patientProfile.heightFeet.required || !$v.userData.patientProfile.heightFeet.numeric || 
                      !$v.userData.patientProfile.heightFeet.minLength ||
                    !$v.userData.patientProfile.heightFeet.maxLength)) || (submitted && (!$v.userData.patientProfile.heightInches.required || 
                    !$v.userData.patientProfile.heightInches.numeric || !$v.userData.patientProfile.heightInches.minLength ||
                    !$v.userData.patientProfile.heightInches.maxLength)) || ((submitted && (!$v.userData.patientProfile.heightFeet.between)) || 
                     (submitted && (!$v.userData.patientProfile.heightInches.between))) }">


                  <b-col lg="6" sm="6" md="5" cols="5" class="reduce-padding label-col">

                    <div class="textfield-label">
                      Height:
                    </div>

                    <div v-if="(submitted && (!$v.userData.patientProfile.heightFeet.required || !$v.userData.patientProfile.heightFeet.numeric || 
                      !$v.userData.patientProfile.heightFeet.minLength ||
                    !$v.userData.patientProfile.heightFeet.maxLength)) || (submitted && (!$v.userData.patientProfile.heightInches.required || 
                    !$v.userData.patientProfile.heightInches.numeric || !$v.userData.patientProfile.heightInches.minLength ||
                    !$v.userData.patientProfile.heightInches.maxLength)) || ((submitted && (!$v.userData.patientProfile.heightFeet.between)) || 
                     (submitted && (!$v.userData.patientProfile.heightInches.between)))" class="error">Valid entry required.

                    </div>

                  </b-col>

                  <b-col lg="6" sm="6" md="7" cols="7" class=" field-col">
                    <b-row class="no-gutters">

                      <b-col cols="6" class="reduce-padding">

                        <div class="">
                          
                          <the-mask :maxlength="1" type="text" class="height-feet-field " v-model="userData.patientProfile.heightFeet" mask="#′"/>
                          
                        </div>

                      </b-col>

                      <b-col cols="6" class="reduce-padding">

                        <div class="">
                        <the-mask type="text" class="common-txt-field inches-field" v-model="userData.patientProfile.heightInches" :mask='[mask1,mask2]' />
                               
                        </div>
                      </b-col>

                    </b-row>

                  </b-col>

                </b-row>

                <!--row1-->
                <b-row class="no-gutters fields-spacing" :class="{'haserror': submitted && (!$v.userData.patientProfile.gender.required)}">

                  <b-col lg="6" sm="6" md="5" cols="5" class="reduce-padding label-col">
                    <div class="textfield-label">
                      Gender:
                    </div>

                    <div v-if="submitted && (!$v.userData.patientProfile.gender.required)" class="error">Valid entry required.

                    </div>
                  </b-col>

                  <b-col lg="6" sm="6" md="7" cols="7" class="field-col">
                    <b-row class="radiobutton no-gutters">
                      <b-col cols="6" class="reduce-padding">
                        <input type="radio" name="male" v-model="userData.patientProfile.gender" id="male" value="male">
                        <label for="male" class="male-radio">
                                        <div class="radio-label">Male</div>
                                    </label>

                      </b-col>

                      <b-col cols="6" class="reduce-padding">
                        <input type="radio" name="female" v-model="userData.patientProfile.gender" id="female" value="female">
                        <label for="female">
                                        <div class="radio-label">Female</div>
                                    </label>
                      </b-col>
                    </b-row>
                  </b-col>

                </b-row>

              </div>
              <hr class="goal-hr verticle-hr">


            </b-col>

            <b-col md="7" class="reduce-padding">
              <div class="right-col">
                <b-row class="no-gutters">
                  <div class="conditions-text"><b>Pre-Existing Conditions</b> (check all that apply):</div>
                  <b-col md="5" class="reduce-padding">
                    <div class="no-gutters common-checkspacing-sm">
                      <!--row-->
                      <b-row class="no-gutters">
                        <div class="checkboxes-sec cod-checkbox">
                          <input type="checkbox" name="heartDisease" v-model="userData.conditions.heartDisease" id="heartDisease" value="heartDisease">
                          <label for="heartDisease">
                                        <div class="checkbox-label">Heart Disease<sup>*</sup></div>
                                    </label>
                        </div>
                      </b-row>

                      <!--row-->
                      <b-row class="no-gutters check-spacing">
                        <div class="checkboxes-sec cod-checkbox">
                          <input type="checkbox" name="heartFailure" v-model="userData.conditions.heartFailure" id="heartFailure" value="heartFailure">
                          <label for="heartFailure">
                                        <div class="checkbox-label">Heart Failure</div>
                                    </label>
                        </div>
                      </b-row>

                      <!--row-->
                      <b-row class="no-gutters check-spacing">
                        <div class="checkboxes-sec cod-checkbox">
                          <input type="checkbox" name="artrialFibrillation" v-model="userData.conditions.artrialFibrillation" id="artrialFibrillation" value="artrialFibrillation">
                          <label for="artrialFibrillation">
                                        <div class="checkbox-label">Artrial Fibrillation</div>
                                    </label>
                        </div>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col md="7" class="reduce-padding">
                    <div class="checks-right">
                      <div class="no-gutters common-checkspacing-sm">
                        <!--row-->
                        <b-row class="no-gutters sm-check-spacing">
                          <div class="checkboxes-sec cod-checkbox">
                            <input type="checkbox" name="blindness" v-model="userData.conditions.blindness" id="blindness" value="blindness">
                            <label for="blindness">
                                        <div class="checkbox-label">Blindness in One Eye</div>
                                    </label>
                          </div>
                        </b-row>


                        <!--row-->
                        <b-row class="no-gutters check-spacing">
                          <div class="checkboxes-sec cod-checkbox">
                            <input type="checkbox" name="peripheralDisease" v-model="userData.conditions.peripheralDisease" id="peripheralDisease" value="peripheralDisease">
                            <label for="peripheralDisease">
                                        <div class="checkbox-label">Peripheral Vascular Disease</div>
                                    </label>
                          </div>
                        </b-row>
                      </div>
                      <b-row class="no-gutters ">
                        <div class="check-area-div">

                          *Heart disease includes heart attack, angina, or blocked arteries in the heart.

                        </div>
                      </b-row>

                    </div>

                  </b-col>
                </b-row>

              </div>
            </b-col>

          </b-row>

        </b-row>

        <!--risk factors-->
        <b-row class="no-gutters">


          <div class="header-row2">
            <div class="headings">
              RISK FACTORS PATIENTS CAN CHANGE:

            </div>
          </div>

          <b-row class="body-row2 no-gutters">

            <b-col md="5" class="reduce-padding main-left-col">
              <div class="left-col">

                <b-row class="no-gutters " :class="{'haserror': submitted && (!$v.userData.risks.smoking.required)}">

                  <b-col lg="6" md="5" cols="6" class="reduce-padding label-col">
                    <div class="textfield-label">
                      Smoking Status:
                    </div>

                    <div v-if="submitted && (!$v.userData.risks.smoking.required)" class="error">Valid entry required.

                    </div>
                  </b-col>

                  <b-col lg="6" md="7" cols="6" class="field-col">
                    <b-row class="radiobutton no-gutters">
                      <b-col cols="6" class="reduce-padding">
                        <input type="radio" name="yesSmoking" v-model="userData.risks.smoking" id="yesSmoking" value="Yes">
                        <label for="yesSmoking">
                                        <div class="radio-label">Yes</div>
                                    </label>
                      </b-col>
                      <b-col cols="6" class="reduce-padding">
                        <input type="radio" name="noSmoking" v-model="userData.risks.smoking" id="noSmoking" value="No">
                        <label for="noSmoking">
                                        <div class="radio-label">No</div>
                                    </label>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <!--row-->
                <b-row class="no-gutters fields-spacing2" :class="{'haserror': submitted && (!$v.userData.risks.weight.required || !$v.userData.risks.weight.numeric || 
                    !$v.userData.risks.weight.minLength || !$v.userData.risks.weight.maxLength || 
                           !$v.userData.risks.weight.between)}">
                  <b-col lg="6" md="5" cols="6" class="reduce-padding label-col">
                    <div class="textfield-label">
                      Weight &nbsp;<span class="sub-heading">(lbs.):</span>
                    </div>
                    <div v-if="submitted && (!$v.userData.risks.weight.required || !$v.userData.risks.weight.numeric || 
                    !$v.userData.risks.weight.minLength || !$v.userData.risks.weight.maxLength || 
                           !$v.userData.risks.weight.between)" class="error">Valid entry required.
                    </div>
                  </b-col>
                  <b-col lg="6" md="7" cols="6" class=" field-col">
                    <div class="">
                      <input type="text" class="common-txt-field " maxLength="3" v-model="userData.risks.weight" />
                    </div>
                  </b-col>

                </b-row>


                <!--row1-->
                <b-row class="no-gutters fields-spacing2" :class="{'haserror': submitted && (!$v.userData.risks.exercise.required)}">

                  <b-col lg="6" md="5" cols="6" class="reduce-padding label-col exercise-label">
                    <div class="textfield-label">
                      Exercise?
                      <div class="sub-heading"><i>(150 Min/Week)</i></div>
                    </div>

                    <div v-if="submitted && (!$v.userData.risks.exercise.required)" class="error">Valid entry required.

                    </div>
                  </b-col>

                  <b-col lg="6" md="7" cols="6" class="field-col">
                    <b-row class="radiobutton no-gutters">
                      <b-col cols="6" class="reduce-padding">
                        <input type="radio" name="yesExercise" v-model="userData.risks.exercise" id="yesExercise" value="Yes">
                        <label for="yesExercise">
                                        <div class="radio-label">Yes</div>
                                    </label>

                      </b-col>

                      <b-col cols="6" class="reduce-padding">
                        <input type="radio" name="noExercise" v-model="userData.risks.exercise" id="noExercise" value="No">
                        <label for="noExercise">
                                        <div class="radio-label">No</div>
                                    </label>

                      </b-col>
                    </b-row>
                  </b-col>

                </b-row>

              </div>
              <hr class="risk-hr verticle-hr">


            </b-col>

            <b-col md="7" class="reduce-padding">
              <div class="sliders-right">

                <!--row-->
                <b-row class="no-gutters" :class="{'haserror': submitted && (!$v.userData.risks.bloodSugar.required 
                || !$v.userData.risks.bloodSugar.decimal || !$v.userData.risks.bloodSugar.maxLength || 
                           !$v.userData.risks.bloodSugar.between)}">
                  <b-col lg="5" class="reduce-padding">
                    <div class="textfield-label">
                      A1C Blood Sugar:
                    </div>
                    <div v-if="submitted && (!$v.userData.risks.bloodSugar.required || !$v.userData.risks.bloodSugar.decimal || 
                        !$v.userData.risks.bloodSugar.maxLength || 
                           !$v.userData.risks.bloodSugar.between)" class="error div-lg">Enter value 2 - 16.
                    </div>
                  </b-col>

                  <b-col lg="7" class="reduce-padding">

                    <!--<b-row class="no-gutters">-->
                    <!--slider-->

                    <b-row class="slider-input-row no-gutters">
                      <b-col class="slider-row sugar-slider reduce-padding" cols="9">
                        <div class="slider-label left-label inline-sections">2</div>
                        <div class="slider-container cod-slider sugar-slider inline-sections">
                          <vue-slider v-model="userData.risks.bloodSugar" :min="2" :max="16" :tooltip="'none'" :interval="0.1" @change="getMaskedVal(userData.risks.bloodSugar)">
                            <template v-slot:dot>
                                    <div class="custom-handle"></div>
                                  </template>
                          </vue-slider>
                        </div>
                        <div class="slider-label right-label inline-sections">16</div>
                      </b-col>
                      <b-col class="reduce-padding" cols="3">
                        <input class="risk-input" type="text" maxLength="4" v-model.number="userData.risks.bloodSugar" @change="getMaskedVal(userData.risks.bloodSugar)" />
                      </b-col>
                    </b-row>

                    <div v-if="submitted && (!$v.userData.risks.bloodSugar.required || !$v.userData.risks.bloodSugar.decimal || 
                        !$v.userData.risks.bloodSugar.maxLength || 
                           !$v.userData.risks.bloodSugar.between)" class="error error-sm div-sm">Enter value 2 - 16.
                    </div>
                    <!--</b-row>-->

                  </b-col>

                </b-row>


                <!--row-->
                <b-row class="no-gutters top-margin-sliders" :class="{'haserror': submitted && (!$v.userData.risks.bloodPressure.required || !$v.userData.risks.bloodPressure.numeric || 
                      !$v.userData.risks.bloodPressure.maxLength || 
                           !$v.userData.risks.bloodPressure.between)}">
                  <b-col lg="5" class="reduce-padding">
                    <div class="textfield-label">
                      Systolic Blood Pressure:
                    </div>
                    <div v-if="submitted && (!$v.userData.risks.bloodPressure.required || !$v.userData.risks.bloodPressure.numeric || 
                      !$v.userData.risks.bloodPressure.maxLength || 
                           !$v.userData.risks.bloodPressure.between)" class="error div-lg">Enter value 60 - 250.
                    </div>
                  </b-col>

                  <b-col lg="7" class="reduce-padding">

                    <!--<b-row class="no-gutters">-->
                    <!--slider-->
                    <b-row class="slider-input-row no-gutters">
                      <b-col class="slider-row sugar-slider reduce-padding" cols="9">
                        <div class="slider-label left-label inline-sections">60</div>
                        <div class="slider-container cod-slider blood-pressure-slider inline-sections">
                          <vue-slider v-model="userData.risks.bloodPressure" :min="60" :max="250" :tooltip="'none'">
                            <template v-slot:dot>
                                    <div class="custom-handle"></div>
                                  </template>
                          </vue-slider>
                        </div>
                        <div class="slider-label right-label inline-sections">250</div>
                      </b-col>
                      <b-col class="reduce-padding" cols="3">
                        <input class="risk-input" type="text" maxLength="3" v-model.number="userData.risks.bloodPressure" />
                      </b-col>
                    </b-row>

                    <div v-if="submitted && (!$v.userData.risks.bloodPressure.required || !$v.userData.risks.bloodPressure.numeric || 
                      !$v.userData.risks.bloodPressure.maxLength || 
                           !$v.userData.risks.bloodPressure.between)" class="error error-sm div-sm">Enter value 60 - 250.
                    </div>

                    <!--</b-row>-->

                  </b-col>

                </b-row>

                <!--row-->
                <b-row class="no-gutters top-margin-sliders" :class="{'haserror': submitted && (!$v.userData.risks.totalCholestrol.required || !$v.userData.risks.totalCholestrol.numeric ||
                        !$v.userData.risks.totalCholestrol.minLength || 
                        !$v.userData.risks.totalCholestrol.maxLength || 
                           !$v.userData.risks.totalCholestrol.between)}">
                  <b-col lg="5" class="reduce-padding">
                    <div class="textfield-label">
                      Total Cholesterol:
                    </div>


                    <div v-if="submitted && (!$v.userData.risks.totalCholestrol.required || !$v.userData.risks.totalCholestrol.numeric ||
                        !$v.userData.risks.totalCholestrol.minLength || 
                        !$v.userData.risks.totalCholestrol.maxLength || 
                           !$v.userData.risks.totalCholestrol.between)" class="error div-lg">Enter value 40 - 400.
                    </div>
                  </b-col>

                  <b-col lg="7" class="reduce-padding">

                    <!--<b-row class="no-gutters">-->
                    <!--slider-->
                    <b-row class="slider-input-row no-gutters">
                      <b-col class="slider-row sugar-slider reduce-padding" cols="9">
                        <div class="slider-label left-label inline-sections">40</div>
                        <div class="slider-container cod-slider total-cholestrol-slider inline-sections">
                          <vue-slider v-model="userData.risks.totalCholestrol" :min="40" :max="400" :tooltip="'none'">
                            <template v-slot:dot>
                                    <div class="custom-handle"></div>
                                  </template>
                          </vue-slider>
                        </div>
                        <div class="slider-label right-label inline-sections">400</div>
                      </b-col>
                      <b-col class="reduce-padding" cols="3">
                        <input class="risk-input" type="text" maxLength="3" v-model.number="userData.risks.totalCholestrol" />
                      </b-col>
                    </b-row>

                    <!--</b-row>-->
                    <div v-if="submitted && (!$v.userData.risks.totalCholestrol.required || !$v.userData.risks.totalCholestrol.numeric ||
                        !$v.userData.risks.totalCholestrol.minLength || 
                        !$v.userData.risks.totalCholestrol.maxLength || 
                           !$v.userData.risks.totalCholestrol.between)" class="error error-sm div-sm">Enter value 40 - 400.
                    </div>
                  </b-col>

                </b-row>


                <!--row-->
                <b-row class="no-gutters top-margin-sliders" :class="{'haserror': submitted &&  ((!$v.userData.risks.hdl.required || !$v.userData.risks.hdl.numeric) || 
                       (isHDLError) || !$v.userData.risks.hdl.minLength || 
                        !$v.userData.risks.hdl.maxLength || !$v.userData.risks.hdl.between)}">
                  <b-col lg="5" class="reduce-padding">
                    <div class="textfield-label good-chol-txt">
                      HDL “Good” Cholesterol:
                    </div>

                    <div v-if="submitted &&  ((!$v.userData.risks.hdl.required || !$v.userData.risks.hdl.numeric) || 
                       (userData.risks.hdl > userData.risks.totalCholestrol) || !$v.userData.risks.hdl.minLength || 
                        !$v.userData.risks.hdl.maxLength || !$v.userData.risks.hdl.between)" class="error div-lg">Enter value less than total cholesterol.
                    </div>
                  </b-col>

                  <b-col lg="7" class="reduce-padding">

                    <!--<b-row class="no-gutters">-->
                    <!--slider-->
                    <b-row class="slider-input-row no-gutters">
                      <b-col class="slider-row sugar-slider reduce-padding" cols="9">
                        <div class="slider-label left-label inline-sections">20</div>
                        <div class="slider-container vue-slider-rail-rev cod-slider hdl-slider inline-sections">
                          <vue-slider v-model="userData.risks.hdl" :min="20" :max="100" :tooltip="'none'">
                            <template v-slot:dot>
                                    <div class="custom-handle"></div>
                                  </template>
                          </vue-slider>
                        </div>
                        <div class="slider-label right-label inline-sections">100</div>
                      </b-col>
                      <b-col class="reduce-padding" cols="3">
                        <input class="risk-input" type="text" :min="20" :max="100" :minLength="1" :maxLength="3" v-model.number="userData.risks.hdl" />
                      </b-col>
                    </b-row>
                    <div v-if="submitted &&  ((!$v.userData.risks.hdl.required || !$v.userData.risks.hdl.numeric) || 
                       (userData.risks.hdl > userData.risks.totalCholestrol) || !$v.userData.risks.hdl.minLength || 
                        !$v.userData.risks.hdl.maxLength || !$v.userData.risks.hdl.between)" class="error error-sm div-sm">Enter value less than total cholesterol.
                    </div>
                    <!--</b-row>-->

                  </b-col>

                </b-row>


              </div>
            </b-col>

          </b-row>
        </b-row>


      </form>
    </b-col>
    <codNavigation :isValidatedPage="true" v-bind:navButtons="['info','next','back']" v-bind:nextPage="'goals'" :btnClickHandler="this.handleSubmit"></codNavigation>
  </b-col>
</b-row>
</template>

<script>
  /* eslint-disable */
  import codHeader from '../components/codHeader.vue';
  import codNavigation from '../components/codNavigation.vue';
  import { required, maxLength, numeric, minLength, alpha, between, decimal } from "vuelidate/lib/validators";
  import { mapGetters, mapMutations } from 'vuex';
  import scrollMixin from '../mixins/scrollMixin';
import taggingMixin from '../mixins/taggingMixin';
  
  export default {
    name: "questionnaire",
     mixins: [scrollMixin, taggingMixin],
    mounted: function() {
      this.userData.isGoalsPage = false;
      this.scrollToTop();
    },
    components: {
      'codHeader': codHeader,
      codNavigation: codNavigation,
    },
    data() {
      return {
        pageTitle: 'Questionnaire',
        pageNumber: '2',
       mask1: '##″',
        mask2: '#″',
        submitted: false,
        isDiagnosisError: false,
        isHDLError: false,
      };
    },
    
    validations: {
      userData: {
        patientProfile: {
          age: {
            required,
            numeric,
            minLength: minLength(2),
            maxLength: maxLength(2),
            between: between(25, 90)
  
          },
          diagnoseAge: {
            required,
            numeric,
            minLength: minLength(2),
            maxLength: maxLength(2),
            between: between(25, 65)
  
          },
          heightFeet: {
            required,
            numeric,
            minLength: minLength(1),
            maxLength: maxLength(1),
            between: between(1, 8)
          },
          heightInches: {
            required,
            numeric,
            minLength: minLength(1),
            maxLength: maxLength(2),
            between: between(0, 12)
          },
          gender: {
            required
          },
        },
        risks: {
          smoking: {
            required
          },
          weight: {
          required,
          numeric,
          minLength: minLength(2),
          maxLength: maxLength(3),
          between: between(80, 400)

        },
          exercise: {
          required
        },
          totalCholestrol: {
            required,
            numeric,
            minLength: minLength(2),
            maxLength: maxLength(3),
            between: between(40, 400)
  
          },
          hdl: {
            required,
            numeric,
             minLength: minLength(2),
            maxLength: maxLength(3),
            between: between(20, 100)
          },
          bloodSugar: {
            required,
            decimal,
            maxLength: maxLength(5),
            between: between(2.0, 16.0)
  
          },
          bloodPressure: {
                    required,
                    numeric,
                    minLength: minLength(2),
                    maxLength: maxLength(3),
                    between: between(60, 250)
          
                  },
        }
      }
    },
      
    computed: {
        ...mapGetters('userData', ['userData'])
        },
        
    methods: {
      ...mapMutations('userData', ['saveUserData']),
      
      getMaskedVal: function(newvalue) {

        var value = Number(newvalue);

         if (value > 1 && value < 17) {
            if (value == 2 || value == 3 || value == 4 || value == 5 || value == 6 || value == 7 || value == 8 || value == 9 || value == 10
            || value == 11 || value == 12 || value == 13 || value == 14 || value == 15 || value == 16) {
              this.userData.risks.bloodSugar = value + '.' + '0';
            }
            else {
              this.userData.risks.bloodSugar = value;
            }
          }
          else if (value > 9 && value < 17) {
            this.userData.risks.bloodSugar = value;
          }
      },
      
      
      handleSubmit(e) {
       
        this.submitted = true;
        this.$v.$touch();
        
        if((this.userData.patientProfile.diagnoseAge > this.userData.patientProfile.age)) {
            this.isDiagnosisError = true;
          }
          else {
            this.isDiagnosisError = false;
          }
          
          if(this.userData.risks.hdl > this.userData.risks.totalCholestrol) {
               this.isHDLError = true;
          }
          else {
            this.isHDLError = false;
          }
          
        if (this.$v.$invalid || this.isHDLError || this.isDiagnosisError) {
          this.scrollToError();
          return false;
        }
        this.tagUserInput('age', 'questionnaire', this.userData.patientProfile.age);
        this.tagUserInput('diagAge', 'questionnaire', this.userData.patientProfile.diagnoseAge);
        this.tagUserInput('gender', 'questionnaire', this.userData.patientProfile.gender);
        this.tagUserInput('height', 'questionnaire', this.userData.patientProfile.heightFeet+"' "+this.userData.patientProfile.heightInches+"″");
        this.tagUserInput('heartDisease', 'questionnaire', this.userData.conditions.heartDisease);
        this.tagUserInput('heartFailure', 'questionnaire', this.userData.conditions.heartFailure);
        this.tagUserInput('artrialFibrillation', 'questionnaire', this.userData.conditions.artrialFibrillation);
        this.tagUserInput('peripheralDisease', 'questionnaire', this.userData.conditions.peripheralDisease);
        this.tagUserInput('blindness', 'questionnaire', this.userData.conditions.blindness);
        this.tagUserInput('weight', 'questionnaire', this.userData.risks.weight);
        this.tagUserInput('smoking', 'questionnaire', this.userData.risks.smoking);
        this.tagUserInput('exercise', 'questionnaire', this.userData.risks.exercise);
        this.tagUserInput('hdl', 'questionnaire', this.userData.risks.hdl);
        this.tagUserInput('totalCholestrol', 'questionnaire', this.userData.risks.totalCholestrol);
        this.tagUserInput('bloodPressure', 'questionnaire', this.userData.risks.bloodPressure);
        this.tagUserInput('bloodSugar', 'questionnaire', this.userData.risks.bloodSugar);
        
        this.tagNavigation('next', 'questionnaire');
        this.$router.push('goals');
      }
    }
  };
</script>


<style lang="scss" scoped>

 label:before {
    background-image: url("../images/icon-svg/radiobutton_image.svg");
 }
 
  input[type=radio]:checked+label:before {
    background-image: url("../images/icon-svg/radioselected_image.svg");
  }
  

 .cod-checkbox label:before {
    background-image: url("../images/icon-svg/Checkbox_image.svg");
    background-color: #fff;
 }
 
 .cod-checkbox input[type='checkbox']:checked+label:before {
    background-image: url("../images/icon-svg/Checkbox_selected_image.svg");
    background-color: #fff;
  }

</style>
