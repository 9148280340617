<template>
<b-row class="no-gutters">
  <b-col cols="12 goals-header">
    <codHeader v-bind:pageTitle="pageTitle" v-bind:pageNumber="pageNumber"></codHeader>
  </b-col>
  <b-col class="page-background-gradient" cols="12">
    <b-col class="page-content goals reduce-padding" cols="12">

      <form @submit.prevent="handleSubmit">
        <b-row class="no-gutters top-row">
          <div class="txt-row">
            Use the sliders to set new goals for your patient. <span class="double-spacing"></span>Compare the impact on the graph.
          </div>
        </b-row>

        <b-row class="no-gutters body-row">

          <!--left-col-->

          <b-col md="7" lg="5" cols="12" class="reduce-padding">

            <div class="left-col">

              <!--row1-->
              <b-row class="no-gutters">

                <b-col sm="2" class="reduce-padding ques-image">
                  <div class="a1c-img common-img"></div>
                </b-col>

                <b-col sm="10" class="reduce-padding">

                  <b-row class="no-gutters question-text-area">


                    <!--toprow-ques-->
                    <b-col cols="9" sm="8" class="reduce-padding txt-area">

                      <div class="ques-txt">
                        A1C Blood Sugar.............
                      </div>
                    </b-col>


                    <b-col cols="3" sm="4" class="reduce-padding sm-float-right">
                      <div class="ques-field-row" >
                        {{userData.risks.bloodSugar}}<span class="unit-text risk-unit percent-sign">%</span>
                      </div>
                    </b-col>

                  </b-row>
                  <b-row class="slider-input-row no-gutters">
                    <b-col class="slider-row sugar-slider reduce-padding" cols="9" sm="8">
                      <div class="slider-label left-label inline-sections">GOAL:</div>
                      <div class="slider-container cod-slider sugar-slider inline-sections">
                        <vue-slider v-model="goalsBloodSugar" :min="2.0" :max="16.0" :tooltip="'none'" :interval="0.1" @change="getmodifiedRisk(); getMaskedVal('goals', goalsBloodSugar);">
                          <template v-slot:dot>

                              <div class="custom-handle"></div>
                            </template>
                        </vue-slider>
                      </div>
                    </b-col>

                    <b-col class="reduce-padding sm-float-right" cols="3" sm="4">
                      <!--isMasked {{isMasked}}-->
                     <span class="lt" :class="getltClass(goalsBloodSugar)" >&lt;</span><input class="goals-input" :maxlength="4" :class="getWidthClass(goalsBloodSugar)" v-model="goalsBloodSugar" @change="getmodifiedRisk(); getMaskedVal('goals', goalsBloodSugar);"/>
                      <span class="unit-text goal-unit percent-sign">%</span>
                    </b-col>
                  </b-row>

                </b-col>

              </b-row>
              <hr class="horizontal-hr">

              <!--row2-->
              <b-row class="no-gutters">

                <b-col sm="2" class="reduce-padding ques-image">
                  <div class="sbp-img"></div>
                </b-col>

                <b-col sm="10" class="reduce-padding">


                  <b-row class="no-gutters question-text-area">


                    <!--toprow-ques-->

                    <b-col cols="9" sm="8" class="reduce-padding txt-area">

                      <div class="ques-txt">
                        Systolic Blood Pressure...
                      </div>
                    </b-col>


                    <b-col cols="3" sm="4" class="reduce-padding sm-float-right">
                      <div class="ques-field-row">
                        {{userData.risks.bloodPressure}} <span class="unit-text ">mm Hg</span>

                      </div>
                    </b-col>

                  </b-row>


                  <b-row class="slider-input-row no-gutters">
                    <b-col class="slider-row sugar-slider reduce-padding" cols="9" sm="8">
                      <div class="slider-label left-label inline-sections">GOAL:</div>
                      <div class="slider-container cod-slider blood-pressure-slider inline-sections">
                        <vue-slider v-model="goalsBloodPressure" :min="60" :max="250" :tooltip="'none'" @change="getmodifiedRisk(); getLimits('sbp', goalsBloodPressure);">
                          <template v-slot:dot>
                              <div class="custom-handle"></div>
                            </template>
                        </vue-slider>
                      </div>
                    </b-col>
                    <b-col class="reduce-padding sm-float-right" cols="3" sm="4">
                      <!--goalsBloodPressusre{{goalsBloodPressusre % 1}}-->
                      <span class="lt"  :class="{'ltsb1':(goalsBloodPressure<10), 
                      'ltsb2':((goalsBloodPressure>9 && goalsBloodPressure<100)),
                      'ltsb3':goalsBloodPressure>99}">&lt;</span><input class="goals-input" 
                      :class="{'inputwidth1':(goalsBloodPressure<10), 
                      'inputwidth2':((goalsBloodPressure>9 && goalsBloodPressure<100)),
                      'inputwidth3':goalsBloodPressure>99}" :maxlength="3"  v-model="goalsBloodPressure" @change="getmodifiedRisk(); getLimits('sbp', goalsBloodPressure);"/>
                      <span class="unit-text goal-unit">mm Hg</span>
                    </b-col>
                  </b-row>


                </b-col>

              </b-row>
              <hr class="horizontal-hr">

              <!--row3-->
              <b-row class="no-gutters">

                <b-col sm="2" class="reduce-padding ques-image">
                  <div class="tc-img common-img"></div>
                </b-col>

                <b-col sm="10" class="reduce-padding">


                  <b-row class="no-gutters question-text-area">


                    <!--toprow-ques-->
  <b-col cols="9" sm="8" class="reduce-padding txt-area">

                      <div class="ques-txt">
                        Total Cholesterol.............
                      </div>
                    </b-col>


                    <b-col cols="3" sm="4" class="reduce-padding sm-float-right">
                      <div class="ques-field-row">
                        {{userData.risks.totalCholestrol}} <span class="unit-text ">mg/dL</span>

                      </div>
                    </b-col>

                  </b-row>


                  <b-row class="slider-input-row no-gutters">
                    <b-col class="slider-row sugar-slider reduce-padding" cols="9" sm="8">
                      <div class="slider-label left-label asterick-goal inline-sections">GOAL:<sup>*</sup></div>
                      <div class="slider-container cod-slider total-cholestrol-slider inline-sections">
                        <vue-slider v-model="goalsTotalCholestrol" :min="40" :max="400" :tooltip="'none'" @change="getmodifiedRisk(); getLimits('tcl', goalsTotalCholestrol);">
                          <template v-slot:dot>
                              <div class="custom-handle"></div>
                            </template>
                        </vue-slider>
                      </div>
                    </b-col>
                    <b-col class="reduce-padding sm-float-right" cols="3" sm="4">
                      <span class="lt" :class="{'ltts1':(goalsTotalCholestrol<10), 
                      'ltts2':((goalsTotalCholestrol>9 && goalsTotalCholestrol<100)),
                      'ltts3':goalsTotalCholestrol>99}">&lt;</span><input class="goals-input"
                      :class="{'inputwidth1':goalsTotalCholestrol<10, 
                      'inputwidth2':goalsTotalCholestrol>9 && goalsTotalCholestrol<100,
                      'inputwidth3':goalsTotalCholestrol>99}" :maxlength="3" v-model="goalsTotalCholestrol" @change="getmodifiedRisk(); getLimits('tcl', goalsTotalCholestrol);"/>

                      <span class="unit-text goal-unit">mg/dL</span>
                    </b-col>
                  </b-row>

                </b-col>

              </b-row>
              <hr class="horizontal-hr">

              <!--row4-->
              <b-row class="no-gutters">

                <b-col sm="2" class="reduce-padding ques-image">
                  <div class="hdl-img common-img"></div>
                </b-col>

                <b-col sm="10" class="reduce-padding">


                  <b-row class="no-gutters question-text-area">


                    <!--toprow-ques-->

                    <b-col cols="9" sm="8" class="reduce-padding txt-area">
                      <div class="ques-txt">
                        HDL <span class="unbold">(Good)</span> Cholesterol...
                      </div>
                    </b-col>

                    <b-col cols="3" sm="4" class="reduce-padding sm-float-right">
                      <div class="ques-field-row">
                        {{userData.risks.hdl}} <span class="unit-text ">mg/dL</span>

                      </div>
                    </b-col>
                  </b-row>

                  <b-row class="slider-input-row no-gutters">
                    <b-col class="slider-row sugar-slider reduce-padding" cols="9" sm="8">
                      <div class="slider-label left-label inline-sections">GOAL:</div>
                      <div class="slider-container vue-slider-rail-rev cod-slider hdl-slider inline-sections">
                        <vue-slider v-model="goalsHdl" :min="20" :max="100" :tooltip="'none'" @change="getmodifiedRisk(); getLimits('hdl', goalsHdl);">
                          <template v-slot:dot >
                              <div class="custom-handle"></div>
                            </template>
                        </vue-slider>
                      </div>
                    </b-col>
                    <b-col class="reduce-padding sm-float-right" cols="3" sm="4">
                      <span class="lt" :class="{'ltts1':(goalsHdl == 0), 
                      'ltts2':((goalsHdl>0 && goalsHdl<100)),
                      'ltts3':goalsHdl>99}">&gt;</span><input class="goals-input" :class="{'inputwidth1':goalsHdl == 0, 
                      'inputwidth2':goalsHdl>0 && goalsHdl<100,'inputwidth3':goalsHdl>99}" :maxlength="3"  v-model="goalsHdl" @change="getmodifiedRisk(); getLimits('hdl', goalsHdl);"/>
                      <span class="unit-text goal-unit">mg/dL</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <hr class="horizontal-hr">

              <!--row5-->
              <b-row class="no-gutters">

                <b-col sm="2" class="reduce-padding ques-image">
                  <div class="smoking-img"></div>
                </b-col>

                <b-col sm="10" class="reduce-padding">


                  <b-row class="no-gutters question-text-area">


                    <!--toprow-ques-->
                    <b-col cols="9" sm="8" class="reduce-padding txt-area">

                      <div class="ques-txt">
                        Smoking Status...............
                      </div>
                    </b-col>

                    <b-col cols="3" sm="4" class="reduce-padding sm-float-right">
                      <div class="ques-field-row">
                        {{userData.risks.smoking}}
                      </div>
                    </b-col>

                  </b-row>

                  <b-row class="slider-input-row no-gutters">
                    <b-col class="slider-row sugar-slider reduce-padding" cols="9" sm="8">
                      <div class="slider-label left-label inline-sections">GOAL:</div>
                      <div class="slider-container vue-slider-rail-rev-smoking cod-slider hdl-slider inline-sections">
                        <vue-slider v-model="goalsSmoking" :data="['No', 'Yes']" :min="0" :max="1" :tooltip="'none'" @change="getmodifiedRisk">
                          <template v-slot:dot>
                                <div class="custom-handle"></div>
                              </template>
                        </vue-slider>
                      </div>
                    </b-col>
                    <b-col class="reduce-padding sm-float-right" cols="3" sm="4">
                      <input class="goals-input smoking-width" v-model="goalsSmoking" :maxlength="3" @change="getmodifiedRisk(); getSmokingLimits(goalsSmoking)" />
                    </b-col>
                  </b-row>

                </b-col>

              </b-row>
            </div>
          
          </b-col>
          

          <!--right-col-->
          <b-col md="5" lg="7" cols="12" class="right-col">
              <hr class="verticle-hr">
            <div class="row no-gutters risk-text">Estimates of Current Health Risks Due to Type 2 Diabetic Complications Over the Next 5 or 10 Years</div>
            <div class="row no-gutters radio-div">
              <b-row class="radiobutton no-gutters">
                <b-col cols="6" class="reduce-padding">
                  <input type="radio" name="ten" v-model="goalestimate" id="ten" value="ten" @change="estimateChanged">
                  <label for="ten">
                                        <div class="radio-label">10-Year
                                  Estimate <span class="unbold">(%)</span> </div>
                                    </label>

                </b-col>

                <b-col cols="6" class="radios-padding">
                  <input type="radio" name="five" v-model="goalestimate" id="five" value="five" @change="estimateChanged">
                  <label for="five">
                                        <div class="radio-label">
                                        5-Year
                                  Estimate <span class="unbold">(%)</span> </div>
                                    </label>
                </b-col>
              </b-row>
            </div>
            <div class="graph-desc-icons-sec">

              <b-row class="full-width no-gutters">
                <b-col cols="3"  class="reduce-padding col1">

                  <div class="orange-box">
                  </div>

                  <div class="minibox-txt">
                    Current Risks
                  </div>
                </b-col>
                <!--</b-row>-->

                <!--<b-row class="no-gutters mini-margin">-->
                <b-col cols="3"  class="col2 reduce-padding mini-margin mini-margin-xs">
                  <div class="blue-box">
                  </div>

                  <div class="minibox-txt">
                    Goal Risks
                  </div>
                </b-col>
                <!--</b-row>-->

                <!--<b-row class="no-gutters mini-margin">-->
                <b-col cols="6" class=" col3 reduce-padding mini-margin">
                  <div class="interval-img">
                  </div>

                  <div class="minibox-txt-intervel">
                    95% Confidence Intervals
                  </div>
                </b-col>
              </b-row>

            </div>
            <div class="graph-container">
              <barGraph v-bind:BarValues="diabBarChartValues" v-bind:confValues="diabConfIntervaValues" v-bind:adjconfValues="diabadjConfIntervaValues" v-bind:displayBars="true" v-bind:isBlindnessSelected="userData.conditions.blindness"></barGraph>
            </div>
            <b-row class="no-gutters pre-text" v-show='userData.conditions.blindness==true'>* Pre-existing condition</b-row>
          </b-col>

        </b-row>
      </form>
    </b-col>
    <codNavigation :isValidatedPage="true" v-bind:navButtons="['info','next','back', 'goals']" v-bind:nextPage="'lifestyle'" :btnClickHandler="this.handleSubmit"></codNavigation>
  </b-col>
</b-row>
</template>
<script>
  /* eslint-disable */
  import codHeader from '@/components/codHeader.vue';
  import codNavigation from '@/components/codNavigation.vue';
  import codCalcs from '@/mixins/codCalcs.js';
  import barGraph from '@/components/barGraph.vue';
  import { mapGetters, mapMutations } from 'vuex';
  import scrollMixin from '../mixins/scrollMixin';
import taggingMixin from '../mixins/taggingMixin';
  export default {
    name: "goals",

    components: {
      'codHeader': codHeader,
      'codNavigation': codNavigation,
      'barGraph': barGraph
    },
    mixins: [codCalcs, scrollMixin, taggingMixin],
    mounted: function() {
      this.userData.isGoalsPage = true;
      this.scrollToTop();
    },
    data() {
      return {
        pageTitle: 'Goals for Modifiable Risk Factors',
        pageNumber: '3',
        isMasked: false,
        riskfiveyearsValues: [],
        risktenyearsValues: [],
        adjrisktenyearsValues: [],
        adjriskfiveyearsValues: [],
        adjconfIntervalfiveyear: [],
        adjconfIntervaltenyear: [],
        confIntervaltenyear: [],
        confIntervalfiveyear: [],
        diabBarChartValues: [],
        diabadjConfIntervaValues: [],
        diabConfIntervaValues: [],
        goalsHdl: '',
        goalsTotalCholestrol: '200',
        goalsBloodPressure: '140',
        goalsBloodSugar: '7',
        goalsSmoking: 'No',
        goalestimate: ''
      };
    },

    computed: {
      ...mapGetters('userData', ['userData']),

    },

    created() {
      this.goalestimate=this.userData.goals.goalestimate;
      if (this.userData.patientProfile.gender === 'male') {
        this.goalsHdl = '40';
      }
      else if (this.userData.patientProfile.gender === 'female') {
        this.goalsHdl = '50';
      }
      this.userData.patientProfile.height = parseInt(this.userData.patientProfile.heightInches) + 12 * (parseInt(this.userData.patientProfile.heightFeet));

      this.getmodifiedRisk();
      this.getMaskedVal('goals', 7);
    },
    
    methods: {
      ...mapMutations('userData', ['saveUserData']),

      getWidthClass(value) {
        return {
          'sugarwidth2': ((value % 1 === 0 && value > 9) || (value % 1 !== 0 && !(value > 9))),
          'sugarwidth1': (value % 1 === 0 && value < 10 || (value % 1 !== 0 && !(value < 10))),
          'sugarwidth2dec': (value % 1 !== 0 && value > 9),
          'sugarwidth1dec': (value % 1 !== 0 && value < 10)
        }
      },
      
       getltClass(value) {
        return {
          // 'sugarwidth2': ((value % 1 === 0 && value > 9) || (value % 1 !== 0 && !(value > 9))),
          'lt1': (value % 1 === 0 && value < 10 || (value % 1 !== 0 && !(value < 10))),
          'lt3': (value % 1 !== 0 && value > 9),
          'lt2': (value % 1 !== 0 && value < 10)
        }
      },
      getMaskedVal: function(name, newvalue) {

        var value = Number(newvalue);
        if (name === 'goals') {
          if (value < 2 || value > 16) {
            this.goalsBloodSugar = 7 + '.' + '0';
          }
          else if (value > 1 && value < 17) {
            if (value == 2 || value == 3 || value == 4 || value == 5 || value == 6 || value == 7 || value == 8 || value == 9 || value == 10
            || value == 11 || value == 12 || value == 13 || value == 14 || value == 15 || value == 16) {
              this.goalsBloodSugar = value + '.' + '0';
            }
            else {
              this.goalsBloodSugar = value;
            }
          }
          else if (value > 9 && value < 17) {
            this.goalsBloodSugar = value;
          }
          else {
            this.goalsBloodSugar = 7 + '.' + '0';
          }

        }
      },

      getLimits: function(name, newvalue) {

        var value = Number(newvalue);
      

        if (name === 'sbp') {
          if ((value < 60 || value > 250) || !(value >= 60 && value <= 250)) {

            this.goalsBloodPressure = 140;
          }
          else {
            this.goalsBloodPressure = value;
          }
        }
        else if (name === 'tcl') {
          if ((value < 40 || value > 400) || !(value >= 40 && value <= 400)) {
            this.goalsTotalCholestrol = 200;
          }
          else {
            this.goalsTotalCholestrol = value;
          }
        }
        else if (name === 'hdl') {
          if ((value < 20 || value > 100) || !(value >= 20 && value <= 100)) {
              if (this.userData.patientProfile.gender === 'female') {
                  this.goalsHdl = 50;
              } else {
                  this.goalsHdl = 40;
              } 
          }
            else {
              this.goalsHdl = value;
            }
        }

        },

      getSmokingLimits: function(val) {
        if (val !== 'No' && val !== 'Yes') {
          this.goalsSmoking = 'No';
        }
      },


      getmodifiedRisk: function() {

        var numericBloodSugar = Number(this.goalsBloodSugar);
        var numericBp = Number(this.goalsBloodPressure);
        var numericTcl = Number(this.goalsTotalCholestrol);
        var numericHdl = Number(this.goalsHdl);
        

        if ((numericBloodSugar > 1 && numericBloodSugar < 17) && (numericBp >= 60 && numericBp <= 250) && 
        (numericTcl >= 40 && numericTcl <= 400) && (numericHdl >= 20 && numericHdl <= 100) && (this.goalsSmoking == 'No' || this.goalsSmoking == 'Yes')) {

          this.confIntervaltenyear = this.getConfidenceIntervals(
            this.userData.patientProfile.age,
            this.userData.patientProfile.height,
            this.userData.risks.weight,
            this.userData.patientProfile.gender,
            this.userData.risks.bloodSugar,
            this.userData.risks.bloodPressure,
            this.userData.risks.totalCholestrol,
            this.userData.risks.hdl,
            this.userData.patientProfile.diagnoseAge,
            this.userData.risks.smoking,
            0,
            0,
            0,
            0,
            'No',
            this.userData.risks.exercise,
            this.userData.conditions.heartDisease,
            this.userData.conditions.heartFailure,
            this.userData.conditions.arterialFibrillation,
            this.userData.conditions.blindness,
            this.userData.conditions.vasculardisease,
            this.userData.conditions.hypoglycemia,
            this.userData.conditions.depression,
            10,
            false);


          this.confIntervalfiveyear = this.getConfidenceIntervals(
            this.userData.patientProfile.age,
            this.userData.patientProfile.height,
            this.userData.risks.weight,
            this.userData.patientProfile.gender,
            this.userData.risks.bloodSugar,
            this.userData.risks.bloodPressure,
            this.userData.risks.totalCholestrol,
            this.userData.risks.hdl,
            this.userData.patientProfile.diagnoseAge,
            this.userData.risks.smoking,
            0,
            0,
            0,
            0,
            'No',
            this.userData.risks.exercise,
            this.userData.conditions.heartDisease,
            this.userData.conditions.heartFailure,
            this.userData.conditions.arterialFibrillation,
            this.userData.conditions.blindness,
            this.userData.conditions.vasculardisease,
            this.userData.conditions.hypoglycemia,
            this.userData.conditions.depression,
            5,
            false);

          this.risktenyearsValues = this.getRiskAtYear(
            this.userData.patientProfile.age,
            this.userData.patientProfile.height,
            this.userData.risks.weight,
            this.userData.patientProfile.gender,
            this.userData.risks.bloodSugar,
            this.userData.risks.bloodPressure,
            this.userData.risks.totalCholestrol,
            this.userData.risks.hdl,
            this.userData.patientProfile.diagnoseAge,
            this.userData.risks.smoking,
            0,
            0,
            0,
            0,
            'No',
            this.userData.risks.exercise,
            this.userData.conditions.heartDisease,
            this.userData.conditions.heartFailure,
            this.userData.conditions.arterialFibrillation,
            this.userData.conditions.blindness,
            this.userData.conditions.vasculardisease,
            this.userData.conditions.hypoglycemia,
            this.userData.conditions.depression,
            10,
            false);

          this.riskfiveyearsValues = this.getRiskAtYear(
            this.userData.patientProfile.age,
            this.userData.patientProfile.height,
            this.userData.risks.weight,
            this.userData.patientProfile.gender,
            this.userData.risks.bloodSugar,
            this.userData.risks.bloodPressure,
            this.userData.risks.totalCholestrol,
            this.userData.risks.hdl,
            this.userData.patientProfile.diagnoseAge,
            this.userData.risks.smoking,
            0,
            0,
            0,
            0,
            'No',
            this.userData.risks.exercise,
            this.userData.conditions.heartDisease,
            this.userData.conditions.heartFailure,
            this.userData.conditions.arterialFibrillation,
            this.userData.conditions.blindness,
            this.userData.conditions.vasculardisease,
            this.userData.conditions.hypoglycemia,
            this.userData.conditions.depression,
            5,

            false);

          this.adjrisktenyearsValues = this.getRiskAtYear(
            this.userData.patientProfile.age,
            this.userData.patientProfile.height,
            this.userData.risks.weight,
            this.userData.patientProfile.gender,
            this.userData.risks.bloodSugar,
            this.userData.risks.bloodPressure,
            this.userData.risks.totalCholestrol,
            this.userData.risks.hdl,
            this.userData.patientProfile.diagnoseAge,
            this.userData.risks.smoking,
            this.goalsBloodSugar,
            this.goalsBloodPressure,
            this.goalsTotalCholestrol,
            this.goalsHdl,
            this.goalsSmoking,
            this.userData.risks.exercise,
            this.userData.conditions.heartDisease,
            this.userData.conditions.heartFailure,
            this.userData.conditions.arterialFibrillation,
            this.userData.conditions.blindness,
            this.userData.conditions.vasculardisease,
            this.userData.conditions.hypoglycemia,
            this.userData.conditions.depression,
            10,
            true);
          
          this.adjriskfiveyearsValues = this.getRiskAtYear(
            this.userData.patientProfile.age,
            this.userData.patientProfile.height,
            this.userData.risks.weight,
            this.userData.patientProfile.gender,
            this.userData.risks.bloodSugar,
            this.userData.risks.bloodPressure,
            this.userData.risks.totalCholestrol,
            this.userData.risks.hdl,
            this.userData.patientProfile.diagnoseAge,
            this.userData.risks.smoking,
            this.goalsBloodSugar,
            this.goalsBloodPressure,
            this.goalsTotalCholestrol,
            this.goalsHdl,
            this.goalsSmoking,
            this.userData.risks.exercise,
            this.userData.conditions.heartDisease,
            this.userData.conditions.heartFailure,
            this.userData.conditions.arterialFibrillation,
            this.userData.conditions.blindness,
            this.userData.conditions.vasculardisease,
            this.userData.conditions.hypoglycemia,
            this.userData.conditions.depression,
            5,
            true);
          
          this.adjconfIntervalfiveyear = this.getConfidenceIntervals(
            this.userData.patientProfile.age,
            this.userData.patientProfile.height,
            this.userData.risks.weight,
            this.userData.patientProfile.gender,
            this.userData.risks.bloodSugar,
            this.userData.risks.bloodPressure,
            this.userData.risks.totalCholestrol,
            this.userData.risks.hdl,
            this.userData.patientProfile.diagnoseAge,
            this.userData.risks.smoking,
            this.goalsBloodSugar,
            this.goalsBloodPressure,
            this.goalsTotalCholestrol,
            this.goalsHdl,
            this.goalsSmoking,
            this.userData.risks.exercise,
            this.userData.conditions.heartDisease,
            this.userData.conditions.heartFailure,
            this.userData.conditions.arterialFibrillation,
            this.userData.conditions.blindness,
            this.userData.conditions.vasculardisease,
            this.userData.conditions.hypoglycemia,
            this.userData.conditions.depression,
            5,
            true);
          this.adjconfIntervaltenyear = this.getConfidenceIntervals(
            this.userData.patientProfile.age,
            this.userData.patientProfile.height,
            this.userData.risks.weight,
            this.userData.patientProfile.gender,
            this.userData.risks.bloodSugar,
            this.userData.risks.bloodPressure,
            this.userData.risks.totalCholestrol,
            this.userData.risks.hdl,
            this.userData.patientProfile.diagnoseAge,
            this.userData.risks.smoking,
            this.goalsBloodSugar,
            this.goalsBloodPressure,
            this.goalsTotalCholestrol,
            this.goalsHdl,
            this.goalsSmoking,
            this.userData.risks.exercise,
            this.userData.conditions.heartDisease,
            this.userData.conditions.heartFailure,
            this.userData.conditions.arterialFibrillation,
            this.userData.conditions.blindness,
            this.userData.conditions.vasculardisease,
            this.userData.conditions.hypoglycemia,
            this.userData.conditions.depression,
            10,
            true);

          this.estimateChanged();
        }
      },

      estimateChanged: function() {
        if (this.goalestimate === 'five') {

          this.diabBarChartValues = JSON.parse(JSON.stringify([
            [this.riskfiveyearsValues[0], this.adjriskfiveyearsValues[0]],
            [this.riskfiveyearsValues[1], this.adjriskfiveyearsValues[1]],
            [this.riskfiveyearsValues[2], this.adjriskfiveyearsValues[2]],
            [this.riskfiveyearsValues[3], this.adjriskfiveyearsValues[3]],
            [this.riskfiveyearsValues[4], this.adjriskfiveyearsValues[4]],
          ]));

          this.diabadjConfIntervaValues = JSON.parse(JSON.stringify(this.adjconfIntervalfiveyear));
          this.diabConfIntervaValues = JSON.parse(JSON.stringify(this.confIntervalfiveyear));
        }
        else {
          this.diabBarChartValues = JSON.parse(JSON.stringify([
            [this.risktenyearsValues[0], this.adjrisktenyearsValues[0]],
            [this.risktenyearsValues[1], this.adjrisktenyearsValues[1]],
            [this.risktenyearsValues[2], this.adjrisktenyearsValues[2]],
            [this.risktenyearsValues[3], this.adjrisktenyearsValues[3]],
            [this.risktenyearsValues[4], this.adjrisktenyearsValues[4]],
          ]));

          this.diabConfIntervaValues = JSON.parse(JSON.stringify(this.confIntervaltenyear));
          this.diabadjConfIntervaValues = JSON.parse(JSON.stringify(this.adjconfIntervaltenyear));
        }
        this.tagUserInput('estimate', 'goals', this.goalestimate);
      },

      saveData: function() {
        this.saveUserData({
          data: {
            goalestimate: this.goalestimate,
            goalsHdl: this.goalsHdl,
            goalsTotalCholestrol: this.goalsTotalCholestrol,
            goalsBloodPressure: this.goalsBloodPressure,
            goalsBloodSugar: this.goalsBloodSugar,
            goalsSmoking: this.goalsSmoking,
          },
          section: 'goals'
        });
        
        this.saveUserData({
          data: {
            diabBarChartValues: this.diabBarChartValues,
            diabadjConfIntervaValues: this.diabadjConfIntervaValues,
            diabConfIntervaValues: this.diabConfIntervaValues,
            riskfiveyearsValues: this.riskfiveyearsValues,
            risktenyearsValues: this.risktenyearsValues,
            adjrisktenyearsValues: this.adjrisktenyearsValues,
            adjriskfiveyearsValues: this.adjriskfiveyearsValues,
            adjconfIntervalfiveyear: this.adjconfIntervalfiveyear,
            adjconfIntervaltenyear: this.adjconfIntervaltenyear,
            confIntervaltenyear: this.confIntervaltenyear,
            confIntervalfiveyear: this.confIntervalfiveyear,
          },
          section: 'barChart'
        });
      },

      handleSubmit(e) {
        this.tagUserInput('goalsHdl', 'goals', this.goalsHdl);
        this.tagUserInput('goalsTotalCholestrol', 'goals', this.userData.goals.goalsTotalCholestrol);
        this.tagUserInput('goalsBloodPressure', 'goals', this.userData.goals.goalsBloodPressure);
        this.tagUserInput('goalsBloodSugar', 'goals', this.userData.goals.goalsBloodSugar);
        this.tagUserInput('goalsSmoking', 'goals', this.userData.goals.goalsSmoking);
        
        this.saveData();
        this.$router.push('lifestyle');
      }
    },

  };
</script>


<style lang="scss" scoped>

 .interval-img {
    background-image: url("../images/icon-svg/goals_page_i.svg");
 }
 label:before {
    background-image: url("../images/icon-svg/radiobutton_image.svg");
 }
 
  input[type=radio]:checked+label:before {
    background-image: url("../images/icon-svg/radioselected_image.svg");
  }
  
.a1c-img {
  background-image: url("../images/icon-svg/a1c-image.svg");
 }
 
 .sbp-img {
  background-image: url("../images/icon-svg/spb-image.svg");
 }
 
  .tc-img {
  background-image: url("../images/icon-svg/tc-image.svg");
 }
 
  .hdl-img {
  background-image: url("../images/icon-svg/hdl-image.svg");
 }
 
  .smoking-img {
  background-image: url("../images/icon-svg/smoking-image.svg");
 }
 
</style>
