<template>
  <b-row class="cod-header head-background-gradient no-gutters">
          <!--page title-->
          <b-col class="title-sec no-gutters" lg="8" md="8">
              <div class="page-title">{{pageTitle}}</div>
          </b-col>
  
          <b-col class="no-gutters" lg="4" md="4">
              <b-row v-show="pageNumber" class="no-gutters hdr-page-num">
                PAGE {{pageNumber}} OF 5 
              </b-row>
          </b-col>
  </b-row>
</template>
<script>
export default {
  name: "codHeader",
  components: {},
  data() {
    return {};
  },
  props: {
      pageTitle: {
          type: String,
          required: true
      },
      pageNumber: {
          type: String
      }
  },
};
</script>


<style lang="scss" scoped>
</style>




