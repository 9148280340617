<template>
  <div id="cod">
    <div class="container">
      <div class="tool-border">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
 import scrollMixin from './mixins/scrollMixin';
export default {
  name: 'cod',
  mixins: [scrollMixin],
  mounted: function () {
      // reload tool from home page on refresh in deep link 
      if (this.$route.path !== '/') {
        this.scrollToTop();
        this.$router.push('/');
        
      }
      
  },
};
</script>

<style lang="scss">
  
</style>
