
<template>
<b-row class="cod-navigation no-gutters">
    <b-col cols="12">
        <b-row class="no-gutters">

            <!--left-->
            <b-col class="left-sec lg-reduce-padding order-2 order-lg-1" lg="8" cols="12">
                <router-link :to="{path: '/moreInfo'}" exact>
                    <button v-show="show(navButtons, 'info')" @click="tagNav('moreinfo')" class="more-info-btn cod-blue-background btn">
                            <span class="moreinfo-img"></span>
                            <span class="btn-text">MORE INFORMATION<sup v-if="userData.isGoalsPage">*</sup></span>
                            </button>

                </router-link>
                <div v-show="show(navButtons, 'goals')" class="optional-btn goals-nav-div">
                    <div class="goals-nav">
                        Values for pre-set goals are based on ADA & Cleveland Clinic goals. Goals should be individualized for the patient. History of hypoglycemia should be considered when setting A1C goals.
                    </div>
                </div>
                <div v-show="show(navButtons, 'discussion')" class="optional-btn discussion-div">
                    <div @click="impDiscussionClicked(); scrollToImpDiscNav();tagNavigation('impoDiscussion', 'lifestyle')" class="discussion-img"></div>
                </div>
                <div v-show="show(navButtons, 'references')" class="optional-btn reference-div">
                    <router-link :to="{path: '/references'}" exact>
                        <div @click="tagNavigation('references', 'summary')" class="refernce-link">References</div>
                    </router-link>
                </div>

                <div v-if="userData.isIntendedUse" class="intended-disclaimer div-lg">
                    <sup>*</sup> <span class="disclaimertxt">By clicking “Next,” you hereby acknowledge and affirm that 
                    you have read and understood the Intended Use & Disclaimer.
                    </span>
                </div>
            </b-col>
            <!--right-->
            <b-col class="right-sec no-gutters order-1 order-lg-2" lg="4" cols="12">

 <div v-if="userData.isIntendedUse" class="intended-disclaimer div-sm">
                    <sup>*</sup> <span class="disclaimertxt">By clicking “Next,” you hereby acknowledge and affirm that 
                    you have read and understood the Intended Use & Disclaimer.
                    </span>
                </div>

                <div class="right-btn-sec no-gutters row">
                    <b-col v-show="show(navButtons, 'back')" class="btn-div md-padding no-gutters order-sm-1 order-2" sm="6">
                        <button @click="$router.go(-1);tagNav('back');" class="left-btn cod-blue-background btn">
                                    <span class="back-icon"></span>
                                    <span class="btn-text">BACK</span>
                                </button>
                    </b-col>
                    <b-col v-show="show(navButtons, 'next')" class="btn-div md-padding no-gutters order-sm-2 order-1" sm="6">


                        <router-link v-if="!isValidatedPage" :to="{path: '/'+nextPage}" exact>
                            <button @click="tagNav('next')" class="right-btn cod-blue-background btn">
                                    <span class="btn-text">NEXT</span>
                                    <span class="next-icon"></span>
                                    </button>
                        </router-link>


                        <div v-if="isValidatedPage" @click="btnClickHandler">
                            <button class="right-btn cod-blue-background btn">
                                    <span class="btn-text">NEXT</span>
                                    <span class="next-icon"></span>
                                    </button>
                        </div>
                    </b-col>
                    <b-col v-show="show(navButtons, 'exit')" class="btn-div md-padding no-gutters order-sm-2 order-1" sm="6">

                        <button class="right-btn cod-blue-background btn" @click="refreshApp();tagNav('exit')">
                                <span class="btn-txt-summary">EXIT</span>
                                   </button>

                    </b-col>
                    <b-col v-show="show(navButtons, 'previous')" class="btn-div prev-btn-div no-gutters" cols="12">
                        <button @click="$router.go(-1);tagNav('back');" class="right-btn prev-btn cod-blue-background btn">
                                    <span class="back-icon"></span>
                                    <span class="btn-text">BACK</span>
                                    </button>
                    </b-col>
                </div>
            </b-col>
        </b-row>

    </b-col>
</b-row>
    
 </template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import taggingMixin from '../mixins/taggingMixin';
export default {

    name: 'codNavigation',
    mixins: [taggingMixin],
    components: {},
    data() {
        return {
            url:''
        };
    },
    props: {


        isValidatedPage: {
            type: Boolean
        },

        btnClickHandler: {
            type: Function
        },
        
        scrollToImpDiscNav: {
            type: Function
        },

        checkImpDiscBtnClick: {
            type: Function
        },
        navButtons: {
            type: Array
        },
        nextPage: {
            type: String
        },


    },
    computed: {
        ...mapGetters('userData', ['userData'])
    },
    methods: {
       ...mapMutations('userData', ['saveUserData']),
       
        show: function(buttonsArray, button) {
            return !(buttonsArray.indexOf(button) == -1);
        },

        impDiscussionClicked: function() {
            this.userData.isImpDiscussionClicked = true;
         
        },
        
        refreshApp: function() {
            this.url = window.location.href;
            this.url = this.url.replace(/#.*$/, '');
            window.location.href = this.url;
        },
        
        tagNav: function(action) {
            this.tagNavigation(action, this.$route.path.split("/")[1]);
        }
    
    },


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .moreinfo-img {
        background-image: url("../images/icon-svg/moreinfo.svg");
        display: inline-block;
        height: 24px;
        width: 27px;
    }
    
    .back-icon {
        background-image: url("../images/icon-svg/backicon.svg");
        display: inline-block;
        float: left;
        height: 13px;
        width: 11px;
        margin: 6px 0;
    }
    
    .next-icon {
        background-image: url("../images/icon-svg/nexticon.svg");
        display: inline-block;
        float: right;
        height: 13px;
        width: 11px;
        margin: 6px 0;
    }
    

    
</style>
 