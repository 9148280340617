<template>
<b-row class="no-gutters">
    <b-col cols="12">
        <codHeader v-bind:pageTitle="pageTitle" v-bind:pageNumber="pageNumber"></codHeader>
    </b-col>
    <b-col class="page-background-gradient" cols="12">
        <b-row class="page-content summary no-gutters">
            <b-col class="summary-heading-bar" cols="12">
                Click on the tabs to view content, then print or save your patient’s report below.
            </b-col>
            <b-col class="summary-content reduce-padding" cols="12">
                <div class="row no-gutters">
                    <b-col class="reduce-padding main-height tabsstyle" cols="12">
                        <b-tabs>
                            <b-tab title="Health Risks & Goals" active @click="changeFocus('risks')">
                                <b-row class="no-gutters">

                                    <b-col cols="12" class="reduce-padding">
                                        <b-row class="tab1-sec no-gutters">

                                            <b-col md="8" class="reduce-padding">
                                                <b-row class="left-col no-gutters">

                                                    <b-col md="4" lg="4" class="reduce-padding">

                                                        <div class="inner-txt-sec">

                                                            <div class="graph-desc-txt">
                                                                Estimates of Current Health Risks Due to Type 2 Diabetic Complications Over the Next {{selectedYears}} Years:
                                                            </div>

                                                            <div class="graph-desc-icons-sec">

                                                                <b-row class="no-gutters">
                                                                    <b-col cols="4" md="12" class="reduce-padding">

                                                                        <div class="orange-box">
                                                                        </div>

                                                                        <div class="minibox-txt">
                                                                            Current Risks
                                                                        </div>
                                                                    </b-col>
                                                                    <!--</b-row>-->

                                                                    <!--<b-row class="no-gutters mini-margin">-->
                                                                    <b-col cols="4" md="12" class="reduce-padding mini-margin mini-margin-xs">
                                                                        <div class="blue-box">
                                                                        </div>

                                                                        <div class="minibox-txt">
                                                                            Goal Risks
                                                                        </div>
                                                                    </b-col>
                                                                    <!--</b-row>-->

                                                                    <!--<b-row class="no-gutters mini-margin">-->
                                                                    <b-col cols="4" md="12" class="reduce-padding mini-margin-interval mini-margin xs-mini-margin">
                                                                        <div class="interval-img">
                                                                        </div>

                                                                        <div class="mini-txt-interval">
                                                                            95% Confidence<br> Intervals
                                                                        </div>
                                                                    </b-col>
                                                                </b-row>

                                                            </div>

                                                        </div>

                                                    </b-col>

                                                    <b-col md="8" lg="8" class="reduce-padding">

                                                        <div class="graphs-area">
                                                            <div class="graph-container">
                                                                <barGraph v-bind:BarValues="userData.barChart.diabBarChartValues" v-bind:confValues="userData.barChart.diabConfIntervaValues" v-bind:adjconfValues="userData.barChart.diabadjConfIntervaValues" v-bind:displayBars="true" v-bind:isBlindnessSelected="userData.conditions.blindness"></barGraph>
                                                            </div>
                                                        </div>
                                                         <b-row class="no-gutters pre-text" v-show='userData.conditions.blindness==true'>* Pre-existing condition</b-row>

                                                    </b-col>


                                                </b-row>
                                                <div class="vertical-hr"></div>
                                            </b-col>

                                            <b-col md="4" class="reduce-padding">

                                                <div class="calcs-area">

                                                    <b-row class="calcs-txt no-gutters">
                                                        <b-col cols="12" class=" reduce-padding">
                                                            A1C Blood Sugar
                                                        </b-col>
                                                        <b-col cols="12" class=" reduce-padding">
                                                            <span class="orange-color">Current: {{userData.risks.bloodSugar}}%, </span><span class="blue-color"> Goal: <span class="signs">&lt;</span>{{userData.goals.goalsBloodSugar}}%</span>

                                                        </b-col>

                                                    </b-row>

                                                    <b-row class="calcs-txt no-gutters extra-row-margin">
                                                        <b-col cols="12" class=" reduce-padding">
                                                            Systolic Blood Pressure
                                                        </b-col>
                                                        <b-col cols="12" class=" reduce-padding">
                                                            <span class="orange-color">Current: {{userData.risks.bloodPressure}} <span class="unbold">mm Hg,</span> </span><span class="blue-color"> Goal: <span class="signs">&lt;</span>{{userData.goals.goalsBloodPressure}} <span class="unbold">mm Hg</span></span>

                                                        </b-col>

                                                    </b-row>

                                                    <b-row class="calcs-txt no-gutters extra-row-margin">
                                                        <b-col cols="12" class=" reduce-padding">
                                                            Total Cholesterol
                                                        </b-col>
                                                        <b-col cols="12" class=" reduce-padding">
                                                            <span class="orange-color">Current: {{userData.risks.totalCholestrol}} 
                                                            <span class="unbold">mg/dL,</span> </span>
                                                            <span class="blue-color"> Goal: <span class="signs">&lt;</span>{{userData.goals.goalsTotalCholestrol}} 
                                                            <span class="unbold">mg/dL</span></span>

                                                        </b-col>

                                                    </b-row>

                                                    <b-row class="calcs-txt no-gutters extra-row-margin">
                                                        <b-col cols="12" class=" reduce-padding">
                                                            HDL (good) Cholesterol
                                                        </b-col>
                                                        <b-col cols="12" class=" reduce-padding">
                                                            <span class="orange-color">Current: {{userData.risks.hdl}} 
                                                            <span class="unbold">mm Hg,</span> </span>
                                                            <span class="blue-color"> Goal: <span class="signs">&gt;</span>{{userData.goals.goalsHdl}} 
                                                            <span class="unbold">mg/dL</span></span>

                                                        </b-col>

                                                    </b-row>

                                                    <b-row class="calcs-txt no-gutters extra-row-margin">
                                                        <b-col cols="12" class=" reduce-padding">
                                                            Smoking Status
                                                        </b-col>
                                                        <b-col cols="12" class=" reduce-padding">
                                                            <span class="orange-color">Current: {{userData.risks.smoking}}, 
                                                            </span>
                                                            <span class="blue-color"> Goal: {{userData.goals.goalsSmoking}} 
                                                         </span>

                                                        </b-col>

                                                    </b-row>


                                                </div>


                                            </b-col>



                                        </b-row>
                                    </b-col>

                                </b-row>
                            </b-tab>
                            <b-tab title="Lifestyle: Weight Loss" @click="changeFocus('weight')">
                                <div class="slider-div-summary">
                                    <div class="top-text"><span class="orange-clr">CURRENT A1C: {{userData.risks.bloodSugar}}%</span> /
                                        <span class="green-clr">TARGET A1C: &lt;7.0%</span></div>
                                    <div class="slider-sec-row row no-gutters">
                                        <b-col cols="12" lg="1" class="a1c-txt-summ"><span class="bold-txt">A1C</span> (%)</b-col>
                                        <b-col cols="12" lg="11" class="slider-outer-summary">
                                            <div class="slider-outer-summary2">
                                                <a1cSlider :currentA1cValue="Number(userData.risks.bloodSugar)" :sliderDiv="'sliderBig'" 
                                                :targetA1cValue="7" :changeA1cValue="userData.lifestyle.changebloodSugarW" 
                                                :willingExercise="userData.lifestyle.willingExercise" :loseWeight="userData.lifestyle.loseWeight"
                                                :isWeightNormal="userData.isWeightNormal" :exercise="userData.risks.exercise" 
                                                :displayWeight="true" :displayExercise="false" :inFocusW="inFocusW" :inFocusE="inFocusE" :isSummary="true" :summaryTab="'weight'"></a1cSlider>
                                            </div>
                                        </b-col>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab title="Lifestyle: Exercise" @click="changeFocus('exercise')">
                                
                                <div class="slider-div-summary">
                                    <div class="top-text"><span class="orange-clr">CURRENT A1C: {{userData.risks.bloodSugar}}%</span> /
                                        <span class="green-clr">TARGET A1C: &lt;7.0%</span></div>
                                    <div class="slider-sec-row row no-gutters">
                                        <b-col cols="12" lg="1" class="a1c-txt-summ"><span class="bold-txt">A1C</span> (%)</b-col>
                                        <b-col cols="12" lg="11" class="slider-outer-summary">
                                            <div class="slider-outer-summary2">
                                                <a1cSlider :currentA1cValue="Number(userData.risks.bloodSugar)" :sliderDiv="'sliderBig'" 
                                                :targetA1cValue="7" :changeA1cValue="userData.lifestyle.changebloodSugarE" 
                                                :willingExercise="userData.lifestyle.willingExercise" :loseWeight="userData.lifestyle.loseWeight"
                                                :isWeightNormal="userData.isWeightNormal" :exercise="userData.risks.exercise" 
                                                :displayWeight="false" :displayExercise="true" :inFocusW="inFocusW" :inFocusE="inFocusE" :isSummary="true" :summaryTab="'exercise'"></a1cSlider>
                                            </div>
                                        </b-col>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </b-col>
                    <b-col class="share-section" cols="12">
                        <div class="row no-gutters">
                            <b-col class="pdf-section reduce-padding" cols="12" lg="6">
                                <div class="share-heading">1. Get Your Custom Report</div>
                                <div class="share-content">
                                    <div class="share-text shared-email-pdf-desc">Print or save a detailed color PDF of your results.</div>
                                    <div class="pdf-btn-div">
                                        <button v-if="(!generatingPdf) && (!pdfReady)" class="pdf-button" @click="generatePdf(userData)">
                                            PRINT OR SAVE REPORT
                                        </button>
                                        <b-button class="pdf-button spinner-btn" v-if="generatingPdf" disabled>
                                            <b-spinner small></b-spinner> Generating Report...
                                        </b-button>
                                        <button class="pdf-button" v-if="pdfReady && !generatingPdf" @click="downloadaPdf()">
                                            DOWNLOAD REPORT
                                        </button>
                                    </div>
                                </div>
                                <div class="vertical-hr2"></div>
                            </b-col>

                            <b-col class="email-section reduce-padding" cols="12" lg="6">
                                <div class="row no-gutters">
                                    <b-col class="reduce-padding" cols="12" sm="10">
                                        <div class="email-div">
                                            <div class="share-heading">2. Share this Tool</div>
                                            <div class="share-content">
                                                <div class="share-text">Email a link to colleagues who may be interested in this tool.</div>
                                                <div class="share-text result-text">(Results are not shared.)</div>
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col class="email-div reduce-padding" cols="12" sm="2">
                                        <router-link :to="{path: '/email'}" exact>
                                            <div @click="tagNavigation('email', 'summary')" class="email-img"></div>
                                        </router-link>
                                    </b-col>
                                </div>

                            </b-col>
                        </div>
                    </b-col>
                </div>
            </b-col>


        </b-row>

        <codNavigation v-bind:navButtons="['info','exit','back', 'references']"></codNavigation>
    </b-col>
</b-row>
</template>
<script>
    /* eslint-disable */
    import codHeader from '@/components/codHeader.vue';
    import codNavigation from '@/components/codNavigation.vue';
    import barGraph from '@/components/barGraph.vue';
    import a1cSlider from '@/components/a1cSlider.vue';
    import { mapGetters, mapMutations } from 'vuex';
    import scrollMixin from '../mixins/scrollMixin';
import taggingMixin from '../mixins/taggingMixin';
    import LZString from 'lz-string';
    import axios from 'axios';

    export default {
        name: "summaryPage",
        mixins: [scrollMixin, taggingMixin],
        mounted: function() {
            this.scrollToTop();
            this.selectedYearsCall();
        },

        components: {
            'codHeader': codHeader,
            'codNavigation': codNavigation,
            'barGraph': barGraph,
            'a1cSlider': a1cSlider,
        },
        

        data() {
            return {
                pageTitle: 'Summary',
                pageNumber: '5',
                inFocusW: false,
                inFocusE: false,
                selectedYears: '',
                generatingPdf: false,
                pdfReady: false,
                key: ''
            };
        },
        computed: {
            ...mapGetters('userData', ['userData'])
        },
      

        methods: {
            selectedYearsCall: function() {
              if(this.userData.goals.goalestimate == 'ten') {
              this.selectedYears = '10';
          }  
          else if (this.userData.goals.goalestimate == 'five'){
              this.selectedYears = '5';
          }  
            },
            changeFocus: function(tab) {
                if (tab === 'weight') {
                    this.inFocusW = true;
                    this.inFocusE = false;
                } else if (tab === 'exercise') {
                    this.inFocusW = false;
                    this.inFocusE = true;
                } else {
                    this.inFocusW = false;
                    this.inFocusE = false;
                }
                
                // this.tagNavigation(tab, 'summary');
            },

            generatePdf: function(userData) {
                this.generatingPdf = true;
                const config = this.$config.config;

                const data = LZString.compressToEncodedURIComponent(JSON.stringify(userData));
            
                // if a URL was given in the config use that otherwise create it based on the current location
                var reportUrl = '';
                if (this.$config.reportUrl !== undefined) {
                    reportUrl = '{0}#?u={1}'.format(this.$config.reportUrl);
                } else {
                    const url = window.location.href;
                    const parts = url.split('://');
                    const schema = parts[0]
                    const uri = parts[1]
                        .replace(/#.*$/, '')
                        .replace(/\/[^\/]*$/, '');
                    reportUrl = `${schema}://${uri}/report.html#?u=${data}`;
                }
                console.log(reportUrl);

                const renderConfig = {
                    url: reportUrl,
                    version: "2.1.1",
                    resourceWait: 3000,
                    timeout: 15000,
                    viewportSize: {
                        width: 615,
                        height: 600
                    },
                    paperSize: {
                        named: {
                            orientation: 'portrait',
                            margin: { value: 0 }
                        }
                    },
                    zoomFactor: 0
                };

                axios.post(`${config.serviceUrl}/render/v1/pdf?app_id=${config.apiKey}`, renderConfig)
                .then((response) => {
                    this.generatingPdf = false;
                    this.pdfReady = true;
                  this.key = response.data.key;
                  
                })
                .catch((error) => {
                    this.generatingPdf = false;
                    alert(`Error generating PDF. ${error}`)
                });
            },
            downloadaPdf: function() {
                const config = this.$config.config;
                window.open(
                    `${config.serviceUrl}/render/v1/pdf/download/report.pdf?app_id=${config.apiKey}&id=${this.key}`,
                    '_blank');
                    this.tagNavigation('pdf', 'summary');
                    this.pdfReady = false;
            }
        }
    };
</script>


<style lang="scss" scoped>

 .interval-img {
    background-image: url("../images/icon-svg/interval-image1.svg");
 }
</style>
