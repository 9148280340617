/* eslint-disable */

export default {
    data() {
        return {
            tool: {
                'welcome': {
                    pageTitle: 'Launch Screen',
                    actions: {
                            getStarted: {
                                name: 'Get Started',
                                event: 'e_navigation'
                            },
                            privacy: {
                                name: 'PRIVACY POLICY',
                                event: 'e_navigation'
                            }
                        }
                        
                },
                
                'intendedUse': {
                    pageTitle: 'Intended Use & Disclaimer',
                    actions: {
                        back: {
                            name: 'BACK',
                            event: 'e_navigation'
                        },
                        next: {
                            name: 'NEXT',
                            event: 'e_navigation'
                        },
                        moreinfo: {
                            name: 'MORE INFORMATION',
                            event: 'e_navigation'
                        }
                    }
                },
                
                'aboutThisAssessment': {
                    pageTitle: 'About This Assessment',
                    actions: {
                        back: {
                            name: 'BACK',
                            event: 'e_navigation'
                        },
                        next: {
                            name: 'NEXT',
                            event: 'e_navigation'
                        },
                        moreinfo: {
                            name: 'MORE INFORMATION',
                            event: 'e_navigation'
                        }
                    }
                },
                
                'questionnaire': {
                    pageTitle: 'Questionnaire',
                    actions: {
                        back: {
                            name: 'BACK',
                            event: 'e_navigation'
                        },
                        next: {
                            name: 'NEXT',
                            event: 'e_navigation'
                        },
                        moreinfo: {
                            name: 'MORE INFORMATION',
                            event: 'e_navigation'
                        },
                        age: {
                            name: 'Age',
                            event:'e_userInput'
                        },
                        diagAge: {
                            name: 'Age at Diabetes Diagnosis',
                            event:'e_userInput'
                        },
                        gender: {
                            name: 'Gender',
                            event:'e_userInput'
                        },
                        height: {
                            name: 'Height',
                            event:'e_userInput'
                        },
                        weight: {
                            name: 'Weight',
                            event:'e_userInput'
                        },
                        exercise: {
                            name: 'Exercise',
                            event:'e_userInput'
                        },
                        smoking: {
                            name: 'Smoking Status',
                            event:'e_userInput'
                        },
                        heartDisease: {
                            name: 'Heart Disease',
                            event:'e_userInput'
                        },
                        heartFailure: {
                            name: 'Heart Failure',
                            event:'e_userInput'
                        },
                        blindness: {
                            name: 'Blindness in One Eye',
                            event:'e_userInput'
                        },
                        artrialFibrillation: {
                            name: 'Artrial Fibrillation',
                            event:'e_userInput'
                        },
                        peripheralDisease: {
                            name: 'Peripheral Vascular Disease',
                            event:'e_userInput'
                        },
                        bloodSugar: {
                            name: 'A1C Blood Sugar',
                            event:'e_userInput'
                        },
                        bloodPressure: {
                            name: 'Systolic Blood Pressure',
                            event:'e_userInput'
                        },
                        totalCholestrol: {
                            name: 'Total Cholesterol',
                            event:'e_userInput'
                        },
                        hdl: {
                            name: 'HDL “Good” Cholesterol',
                            event:'e_userInput'
                        }
                    }
                },
                
                'goals': {
                    pageTitle: 'Goals for Modifiable Risk Factors',
                    actions: {
                        back: {
                            name: 'BACK',
                            event: 'e_navigation'
                        },
                        next: {
                            name: 'NEXT',
                            event: 'e_navigation'
                        },
                        moreinfo: {
                            name: 'MORE INFORMATION',
                            event: 'e_navigation'
                        },
                        goalsBloodSugar: {
                            name: 'A1C Blood Sugar Goal',
                            event:'e_userInput'
                        },
                        goalsBloodPressure: {
                            name: 'Systolic Blood Pressure Goal',
                            event:'e_userInput'
                        },
                        goalsTotalCholestrol: {
                            name: 'Total Cholesterol Goal',
                            event:'e_userInput'
                        },
                        goalsHdl: {
                            name: 'HDL “Good” Cholesterol Goal',
                            event:'e_userInput'
                        },
                        goalsSmoking: {
                            name: 'Smoking Status Goal',
                            event:'e_userInput'
                        },
                        estimate: {
                            name: 'Risk Estimate Period',
                            event:'e_userInput'
                        }
                    }
                },
                
                'lifestyle': {
                    pageTitle: 'Consider Lifestyle Changes',
                    actions: {
                        back: {
                            name: 'BACK',
                            event: 'e_navigation'
                        },
                        next: {
                            name: 'NEXT',
                            event: 'e_navigation'
                        },
                        moreinfo: {
                            name: 'MORE INFORMATION',
                            event: 'e_navigation'
                        },
                        impoDiscussion: {
                            name: 'Important Discussions',
                            event: 'e_navigation'
                        },
                        losingWeight: {
                            name: 'Willing to Lose Weight',
                        },
                        willingExercise: {
                            name: 'Willing to Exercise Regularly',
                        },
                        change: {
                            name: 'change in A1C',
                        }
                        
                    }
                },
                
                'summary': {
                    pageTitle: 'Summary',
                    actions: {
                        lifestyle: {
                            name: 'Lifestyle Changes Tab',
                            event: 'e_navigation'
                        },
                        risks: {
                            name: 'Health Risks & Goals Tab',
                            event: 'e_navigation'
                        },
                        back: {
                            name: 'BACK',
                            event: 'e_navigation'
                        },
                        exit: {
                            name: 'EXIT',
                            event: 'e_navigation'
                        },
                        moreinfo: {
                            name: 'MORE INFORMATION',
                            event: 'e_navigation'
                        },
                        references: {
                            name: 'References',
                            event: 'e_navigation'
                        },
                        pdf: {
                            name: 'PRINT OR SAVE PDF',
                            event: 'e_save'
                        },
                        email: {
                            name: 'Share Via Email',
                            event: 'e_navigation'
                        }
                    }
                },
                
                'moreInfo': {
                   pageTitle: 'More Information',
                    actions: 
                        {
                            back: {
                            name: 'BACK',
                            event: 'e_navigation'
                            }
                        }
                },
                
                'references': {
                   pageTitle: 'References',
                    actions: 
                        {
                            back: {
                            name: 'BACK',
                            event: 'e_navigation'
                            }
                        }
                },
                'email': {
                   pageTitle: 'Share a Link Via Email',
                    actions: 
                        {
                            back: {
                            name: 'BACK',
                            event: 'e_navigation'
                            },
                            submit: {
                            name: 'SUBMIT',
                            event: 'e_save'
                            }
                        }
                },
            }
        };
    },
    
    methods: {
      tagNavigation: function(action, screen) {
          if (this.tool[screen] !== undefined) {
        dataLayer.push({ 
                  'event' : this.tool[screen].actions[action].event,
                  'screen' : this.tool[screen].pageTitle,
                  'buttonText' : this.tool[screen].actions[action].name,
                  'toolName' : 'COD'
                  });
            
        }
      },
      
      tagUserInput: function(action, screen, value) {
        if (this.tool[screen] !== undefined) {
        dataLayer.push({ 
                  'event' : this.tool[screen].actions[action].event,
                  'screen' : this.tool[screen].pageTitle,
                  'input' : this.tool[screen].actions[action].name,
                  'value' : value,
                  'toolName' : 'COD'
                  });
            
        }
      },
        
        tagLifestyle: function(action, value) {
            var screen='lifestyle';
            dataLayer.push({ 
              'event' : 'e_lifestyleChanges',
              'input' : this.tool[screen].actions[action].name,
              'value' : value,
              'toolName' : 'COD'
              });
          },
      
      
    }
  };