<template>
<b-row class="intended-use no-gutters">
  <b-col cols="12">
    <codHeader v-bind:pageTitle="pageTitle"></codHeader>
  </b-col>


  <b-col class="page-background-gradient" cols="12">
    <b-row class="page-content no-gutters ">

      <div class="scroll">
        <b-row class="common-sec no-gutters">

          <div class="section-heading">A Tool for Health Care Professionals:</div>
          <div class="section-dec">

            <div>
              The <i>Complications of Type 2 Diabetes</i> assessment program is designed to assist health care professionals in providing patients with information about the risk of possible complications associated with type 2 diabetes. The following
              screens enable a health care professional to enter a patient’s current health data and illustrate the potential risk for diabetes-related complications over a 5-year and 10-year period. This tool incorporates recent ADA Standards of Care
              and the need to individualize patient care.<sup>1</sup><span class="double-spacing"></span>The patient must have been diagnosed with type 2 diabetes between the ages of 25 and 65 to use this tool.
            </div>

            <div class="para-spacing">
              The results are based on the United Kingdom Prospective Diabetes Study (UKPDS), which was a multi-year study 
              that began in 1977.<sup>2</sup> <span class="double-spacing"></span>The UKPDS looked at diabetes-related complications such as heart
              attack, stroke, kidney failure, blindness, and amputation in a large population of people with newly diagnosed type 
              2 diabetes with no history of cardiovascular disease. It showed how these complications are related to risk factors such
              as high blood sugar, blood pressure, and cholesterol. Validated equations were created from rates of complications, based 
              on a 2004 published outcomes model, to estimate how often complications might occur in groups of people with similar
              risk factors.<sup>3</sup><span class="double-spacing"></span>The UKPDS no. 68 outcomes model has been used in a wide 
              variety of applications.<sup>4</sup><span class="double-spacing"></span>Both the UKPDS no. 68 and the UKPDS no. 82 models
              project future risk of diabetes complications. The UKPDS no. 68 model was selected for this application for 
              simplicity and ease of use.<span class="double-spacing"></span>Models derived from other studies may draw different conclusions.
            </div>

            <div class="para-spacing">
              Results should be explained to the patient and next steps defined by the diabetes care team. While the program is for the benefit of the patient, it should be used in partnership with a health care professional.
            </div>

          </div>

        </b-row>

        <!--////////////////////////-->
        <b-row class="common-sec section-spacing no-gutters">

          <div class="section-sub-heading">Disclaimer:</div>
          <div class="section-dec">

            <div>
              This type 2 diabetes risk assessment tool is an educational program and is not to be used as a substitute for medical advice, diagnosis, or treatment of any health condition or problem.<span class="double-spacing"></span>The UKPDS no. 68
              model on which this tool is based estimates rates of complications on a patient population level.<span class="double-spacing"></span>It does not estimate risk for an individual patient.
            </div>

            <div class="para-spacing">
              Novo Nordisk and its developers and agents make no warranties regarding the accuracy, completeness, timeliness, comparative or 
              controversial nature, or usefulness of any information contained or referenced in this diabetes risk assessment.<span
                class="double-spacing"></span>Novo Nordisk does not endorse or claim validity for the diabetes risk assessment. Health-related information changes frequently, and, therefore, information contained in this diabetes risk assessment may be outdated, incomplete, or
                incorrect.<span class="double-spacing"></span>Novo Nordisk does not assume any risk whatsoever for your use of this diabetes risk assessment or the information presented in the diabetes risk assessment. Use of this diabetes risk assessment
                does not create an expressed or implied relationship between you and Novo Nordisk.

            </div>

            <div class="para-spacing">
              In using this diabetes risk assessment, you agree that neither Novo Nordisk nor any other party is or will be liable or otherwise responsible for any decision made or any action taken or any action not taken due to your use of any information presented
              in the diabetes risk assessment.

            </div>

            <div class="para-spacing">
              Information to know about applying the UKPDS Outcomes Model in clinical practice:

              <ul>
                <li>The cumulative patient data that went into development of the UKPDS equations and used in the <i>Complications of Type 2 Diabetes</i> model is non-probabilistic (not randomly selected).</li>
                <li>Uncertainty around model projections is not captured.</li>
                <li>The risk equations only project the incidence of first events.</li>
                <li>The UKPDS no. 68 Outcomes Model (a clinical trial) was based on data from 3,642 (of the original 5,098) newly-diagnosed type 2
diabetes patients who were between the ages of 25 and 65 in the United Kingdom.</li>
                <li>The UKPDS study used 5,098 patients with the following ethnicity breakdown: 82% were white Caucasian, 10% were Indian-Asians
and 8% were Afro-Caribbean.<sup>5</sup> The UKPDS no. 68 study was used in this model and is based on 3,642 (of the original 5,098) patients,
and it is unknown if the ethnicity ratios were kept the same or similar to the original population.</li>
              </ul>

            </div>


          </div>

        </b-row>

        <!--////////////////////////-->
        <b-row class="common-sec section-spacing last-sec no-gutters">
          <div>

            <ol>
              <li> American Diabetes Association. Standards of medical care in diabetes-2020. Diabetes Care. 2020;43(suppl 1):S1-S212.</li>
              <li>UK Prospective Diabetes Study (UKPDS). Diagetologia. 1991;34:877-890.</li>
              <li>Clarke PM, Gray AM, Briggs A, et al. A model to estimate the lifetime health outcomes of patients with type 2 diabetes: the United Kingdom prospective diabetes study (UKPDS) outcomes model (UKPDS no. 68). Diabetologia. 2004;47(10):1747-1759.
              </li>
              <li>Hayes AJ, Leal J, Gray AM, Holman RR, Clark PM. UKPDS outcomes model 2: a new version of a model to simulate lifetime health outcomes of patients with type 2 diabetes mellitus using data from the 30 year United Kingdom prospective diabetes
                study: UKPDS 82. Diabetologia. 2013;56:1925-1933. </li>
              <li>Davis TME. Ethnic diversity in type 2 diabetes. Diabetic Medicine. 2008;(Suppl 2):52-56.</li>
            </ol>
          </div>
        </b-row>
      </div>


    </b-row>
    <codNavigation v-bind:navButtons="['info','next','back']" v-bind:nextPage="'aboutThisAssessment'"></codNavigation>
  </b-col>
</b-row>
</template>

<script>
  /* eslint-disable */
  import codHeader from '../components/codHeader.vue';
  import { mapState, mapGetters } from 'vuex';
  import codNavigation from '../components/codNavigation.vue';
  import scrollMixin from '../mixins/scrollMixin';
  
export default {
  name: "intendedUse",
    mixins: [scrollMixin],
     mounted: function() {
      this.scrollToTop();
        this.userData.isIntendedUse =true;
    },

  components: {
      'codHeader': codHeader,
      codNavigation : codNavigation,
    },
  data() {
    return {
      pageTitle: 'Intended Use & Disclaimer'
    };
  },
  computed: {
    ...mapState(['navigationModel', 'currentPageIndex']),
    ...mapGetters(['getCurrentPage']),
            ...mapGetters('userData', ['userData'])

  }
};
</script>


<style lang="scss" scoped>

</style>
