<template>
    <b-row class="no-gutters">
      <b-col cols="12">
      <codHeader v-bind:pageTitle="pageTitle"></codHeader>
      </b-col>
      <b-col class="page-background-gradient" cols="12">
        <b-col class="page-content more-info reduce-padding" cols="12">
          <b-row class = "page-content no-gutters">
            <div class = "scroll">
              <b-row class="no-gutters">
                <div class="paragraph-heading">
                  WHAT ARE THE ABC NUMBERS?
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="paragraph-text">
                  They refer to some of the most important numbers in a patient’s diabetes care plan.<span class="double-spacing"></span>Let your patient know what their ABC 
                  numbers are and what their targets should be.<span class="double-spacing"></span>Below are some general goals, but you may individualize them for each patient. 
                  If a patient’s numbers are not in the desired range, work with them to take the necessary next steps to improve them.
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class="a-heading sub-heading">
                  “A” is for A1C:
                </div>
              </b-row>
              <b-row class="no-gutters">  
                <div class="paragraph-text">
                  A1C gives a picture of average blood sugar (glucose) control over the past 2 to 3 months.<span class="double-spacing"></span>It is different from the blood sugar
                  checks your patient does each day.
                </div>
              </b-row>
              <b-row class= "no-gutters">
                <div class="goals-heading  black-heading">
                  
                  Goals:
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class="goals-list bullet-txt">
                  <ul>
                    <li class="bullet-spacing">For many non-pregnant adults: &lt;7.0%</li>
                    <li class="bullet-spacing">Select patients may have a goal of &lt;6.5%  (based on provider judgement and patient 
                    preference and if this can be achieved safely without significant hypoglycemia or adverse effects of treatment).</li>
                    <li class="bullet-spacing">Patients who have a history of hypoglycemia or advanced microvascular or macrovascular complications may have a goal
                    of &lt;8.0%.</li>
                  </ul>
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class="sub-heading">
                  “B” is for Blood Pressure:
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class = "bp-list bullet-txt">
                  <ul>
                    <li class="bullet-spacing">People with diabetes and hypertension at lower risk of cardiovascular disease should have a goal of &lt;140/90 mm Hg.</li>
                    <li class="bullet-spacing">Lower systolic and diastolic blood pressure targets, such as &lt;130/80 mm Hg, may be appropriate for individuals at high risk
                    of cardiovascular disease.</li>
                  </ul>
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class="sub-heading">
                  “C” is for Cholesterol:
                </div>
              </b-row>
              <b-row class="no-gutters">
                  <div class="cholesterol-txt paragraph-text">
                   Lifestyle modification is recommended by the ADA to modify diet, weight loss (if indicated) and physical activity to improve the
lipid profile in patients with diabetes.
                  </div>
              </b-row>
              <b-row class="no-gutters">
                <div class="black-heading hdl-heading">
                  HDL Cholesterol (HDL-C):
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="bullet-txt hdl-txt">
                  <ul>
                    <li>For men: &gt;40 mg/dL</li>
                    <li>For women: &gt;50 mg/dL</li>
                  </ul>
                </div>
              </b-row>

              
                <b-row class="no-gutters">
                <div class="black-heading hdl-heading">
                 Triglycerides:
                </div>
              </b-row>
              <b-row class="no-gutters">
                   <div class="ldl-txt paragraph-text">
                   &lt;150 mg/dL
                  </div>
              </b-row>

              <b-row class="no-gutters">
                <div class="black-heading ldl-heading">
                  LDL Cholesterol (LDL-C):
                </div>
              </b-row>
              <b-row class="no-gutters">
                  <div class="ldl-txt paragraph-text">
                      The ADA does not list specific LDL-C goals; healthcare providers are advised to address lifestyle interventions including weight
                      loss, increased physical activity and medical nutritional therapy which allows some patients to reduce ASCVD risk factors.
                  </div>
              </b-row>
              
                  <b-row class="no-gutters">
                <div class="black-heading ldl-heading">
                  Total Cholesterol:
                </div>
              </b-row>
              <b-row class="no-gutters">
                  <div class="ldl-txt paragraph-text">
                   The ADA does not list specific total cholesterol goals.<span class="double-spacing"></span>A goal of &lt;200 mg/dL is published by Cleveland Clinic and is a basic
recommendation for the general population and not necessarily specific to type 2 diabetes patients.
</div>
              </b-row>
              
              
              <b-row class="no-gutters">
                <div class=" paragraph-heading therapy-paragraph-spacing">
                  INDIVIDUALIZATION OF THERAPY&nbsp;-&nbsp;A1C
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="bullet-txt therapy-text">
                  <ol>
                    <li>
                    In adults with recent type 2 diabetes onset and no clinically significant CVD, an A1C 
                    of &lt;/=6.5 (if achieved without substantial hypoglycemia or other unacceptable consequences) may 
                    reduce the lifetime risk of microvascular and macrovascular complications.
                    </li>
                    <li>A broader A1C range may be considered for older patients and for those at risk for hypoglycemia.</li>
                    <li>An A1C of &gt;6.5% is appropriate for patients with a history of severe hypoglycemia, limited life expectancy, advanced renal
disease, macrovascular complications, extensive comorbidities or long-standing type 2 diabetes in which the A1C goal has
been difficult to attain despite intensive efforts.</li>
                    
                  </ol>
                </div>
              </b-row>
              
                <b-row class="no-gutters">
                <div class="paragraph-heading paragraph-spacing">
                  THE UKPDS NO. 68 DIABETES RISK MODEL
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="paragraph-text">
Although there is a more recently published UKPDS Risk Model (UKPDS no. 82), the UKPDS no. 68 model was selected for this
application for simplicity and ease of use, as it requires fewer user inputs.
                </div>
              </b-row>
              
              
              <b-row class="no-gutters">
                <div class="paragraph-heading paragraph-spacing">
                  METHOD OF SIMULATING CONFIDENCE INTERVALS
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="paragraph-text">
                The confidence intervals (CIs) around the probabilities of the diabetes complications were estimated using Monte Carlo
                simulations (as described by Clarke, et al. and detailed in Table 2), the functional form of each complication (all Weibull
                models), with the estimated value of each coefficient and parameter, and the standard error (SE) of each estimated value.
                Note that each prediction equation has its own set of coefficients corresponding to variables such as age, smoking and
                cholesterol that were included in the prediction equation.   
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="paragraph-text">
                The data in Table 2 were the inputs used for the Monte Carlo simulation of the CIs. Statistical theory tells us that each of these
values was normally distributed, so the Monte Carlo simulation proceeded as follows:   
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="paragraph-text steps-spacing">
                <b>Step 1: </b> For a particular complication, the model sampled a new value for each coefficient from a normal distribution with base
value as the mean and its standard error as the standard deviation.   
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="paragraph-text steps-spacing">
                <b>Step 2: </b> The model calculated the probability of that complication over 5 or 10 years using the sampled (new) values and the
patient's profile measurements. Since the values of the coefficients were sampled each time to generate a "new" value, the
probability of the complication changed slightly with each simulation. The model repeated this process 1000 times.  
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="paragraph-text steps-spacing">
                <b>Step 3: </b> The model ordered the 1000 probabilities of the complication and then chose the 25th and 975th so that 95% of the probabilities were contained between them. This was the 95% CI for a particular complication.
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="paragraph-text steps-spacing">
                <b>Step 4: </b> Repeat Steps 1 to 3 for each of the complications.
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="reference-txt">
                Clarke PM, Gray AM, Briggs A, et al. A model to estimate the lifetime health outcomes of patients with type 2 diabetes: the United Kingdom Prospective Diabetes Study (UKPDS) Outcomes Model (UKPDS no. 68). <i>Diabetologia.</i> 2004;47(10):1747-1759.
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class="paragraph-heading paragraph-spacing">
                  LIFESTYLE CHANGES
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class="sub-heading weight-heading">
                  Lose Weight:
                </div>
              </b-row>
              <b-row class="no-gutters">
                  <table>
                    <tr class="table-heading">
                      <th>Weight Category</th>
                      <th>BMI (kg/m<sup>2</sup>)</th>
                    </tr>
                    <tr>
                      <td>Underweight</td>
                      <td>Less than 18.5</td>
                    </tr>
                    <tr>
                      <td>Normal Weight</td>
                      <td>18.5 – 24.9</td>
                    </tr>
                    <tr>
                      <td>Overweight</td>
                      <td>25 – 29.9</td>
                    </tr>
                    <tr>
                      <td>Obesity</td>
                      <td>30 – 39.9</td>
                    </tr>
                    <tr>
                      <td>Extreme Obesity</td>
                      <td>40 or higher</td>
                    </tr>
                  </table>
              </b-row>
              <b-row class="no-gutters">
                   <div class ="paragraph-text">
                    It is not unusual for people with type 2 diabetes to be overweight or obese.<span class="double-spacing"></span>Weight loss is 
                 encouraged because of the increased risk of heart disease from excess weight. Work with your patient to determine what their weight 
loss goals should be.<span class="double-spacing"></span>Sustained weight loss has long-term benefits.<span class="double-spacing"></span>For many 
overweight or obese patients with type 2 diabetes, a weight loss of at least 5% is needed to produce beneficial outcomes in glycemic control, 
lipids and blood pressure.
                </div>
              </b-row>
              <b-row class="no-gutters">
                   <div class =" improve-txt paragraph-text">
                     Losing weight may improve a patient’s:
                </div>
              </b-row>
              <b-row class="icon-txt no-gutters">
                <b-col  sm="1" class="reduce-padding image-col sm-img-col">
                  <div class ="blood-glucose-img common-img"></div>
                </b-col>
                <b-col sm="11" class="reduce-padding sm-txt-col">
                  <div class ="blood-glucose-txt right-txt">Blood Glucose</div>
                </b-col>
              </b-row>
              <b-row class="icon-txt no-gutters">
                 <b-col sm="1" class="reduce- image-col sm-img-col">
                  <div class ="blood-pressure-img common-img"></div>
                 </b-col>
                 <b-col sm="11" class="reduce-padding sm-txt-col">
                  <div class ="blood-pressure-txt right-txt">Blood Pressure</div>
                 </b-col>
              </b-row>
              <b-row class="icon-txt last-icon-row no-gutters">
                 
                 <b-col sm="1" class="reduce-padding image-col sm-img-col">
                  <div class ="cholesterol-img common-img"></div>
                 </b-col>
                 <b-col sm="11" class="reduce-padding sm-txt-col">
                  <div class ="cholesterol-txt right-txt">Cholesterol</div>
                 </b-col>
                 
              </b-row>
              <b-row class="no-gutters">
                <div class="sub-heading">
                  Exercise Regularly and Reduce Sedentary Time:
                </div>
              </b-row>
              <b-row class="no-gutters">
                   <div class ="paragraph-text">
                     If able, adults with diabetes should:
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="bullet-txt xtra-top-margi hdl-txt">
                  <ul>
                    <li class="hdl-bullet">Perform at least 150 minutes per week of moderate-to-vigorous intensity aerobic physical activity, spread over at least 3 days/week.</li>
                    <li class="hdl-bullet">Have no more than 2 consecutive days without exercise.</li>
                    <li class="hdl-bullet">Shorter durations (75 min/week) of vigorous intensity or interval training may be sufficient for younger or more physically fit individuals.</li>
                    <li class="hdl-bullet">Recent evidence supports that all adults, particularly those with type 2 diabetes, should be 
                    encouraged to interrupt prolonged sitting every 30 minutes for blood glucose benefits.</li>
                  </ul>
                </div>
              </b-row>
              <b-row class="no-gutters">
                <div class ="paragraph-text exercise-txt">
                    <!-- Regular exercise has been shown to improve blood glucose control, reduce heart attack and stroke risk factors, contribute to -->
                    <!--weight loss and improve the patient’s well being.<span class="double-spacing"></span>Having structured exercise programs that require at least 8 weeks duration -->
                    <!--have been shown to reduce A1C by ~0.66% in people with type 2 diabetes.<span class="double-spacing"></span>Recent evidence supports that all individuals -->
                    <!--should be encouraged to reduce the amount of time spent being sedentary by breaking up extended amounts of time -->
                    <!--(no more than 30 minutes) spent sitting.-->
                    
                    Regular exercise has been shown to improve blood glucose control, reduce heart attack and stroke risk factors, contribute to
weight loss and improve the patient’s well being.<span class="double-spacing"></span>Having structured exercise programs that require at least 8 weeks duration
have been shown to reduce A1C by ~0.66% in people with type 2 diabetes.
                </div>
              </b-row>
              
            </div>
          </b-row>
        </b-col>
        <codNavigation v-bind:navButtons="['previous']"></codNavigation>
      </b-col>
    </b-row>
  </template>
<script>
  /* eslint-disable */
  import codHeader from '../components/codHeader.vue';
  import codNavigation from '../components/codNavigation.vue';
  import scrollMixin from '../mixins/scrollMixin';
  import { mapState, mapGetters } from 'vuex';

  export default {
    name: "moreInfo",
    mixins: [scrollMixin],
    mounted: function() {
      this.scrollToTop();
      this.userData.isIntendedUse = false;
    },
    components: {
      'codHeader': codHeader,
      'codNavigation': codNavigation,
    },

    computed: {
      ...mapGetters('userData', ['userData'])

    },


    data() {
      return {
        pageTitle: 'More Information'
      };
    }
  };
</script>


<style lang="scss" scoped>
  .blood-glucose-img {
    background-image: url("../images/icon-svg/glucose-image.svg");
    display: inline-block;
  }

  .blood-pressure-img {
    background-image: url("../images/icon-svg/bloodpressure-image.svg");
    display: inline-block;
  }

  .cholesterol-img {
    background-image: url("../images/icon-svg/cholesterol-image.svg");
    display: inline-block;
  }

  .losing-weight-img {
    background-image: url("../images/icon-svg/weighmachine-image.svg");
    display: inline-block;
  }
</style>
  