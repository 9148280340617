<template>
<b-row class="about-this-assessment no-gutters">
  <b-col cols="12">
    <codHeader v-bind:pageTitle="pageTitle" v-bind:pageNumber="pageNumber"></codHeader>
  </b-col>
  <b-col class="page-background-gradient" cols="12">
    <b-row class="page-content no-gutters">
      <div class="scroll">

        <div class="main-heading">
          What to Expect:
        </div>

        <div class="body-sec">

          <div class="common-row">
            <div class="about-box blue-border">
              <b-row class="no-gutters">
                <b-col lg="2" sm="1" class="img-cover reduce-padding">
                  <div class="left-img1"></div>
                </b-col>
                <b-col lg="10" sm="11" class="text-cover step1">
                  <div class="right-heading">
                    Step 1: &nbsp;&nbsp;Estimate Risks.
                  </div>

                  <div class="right-desc">
                    Answer the questions to estimate your patient’s risk of developing these complications over the next 5 and 10 years.
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>


          <div class="common-row">
            <div class="about-box lightblue-border">
              <b-row class="no-gutters">
                <b-col lg="2" sm="1" class="img-cover reduce-padding">
                  <div class="left-img2"></div>
                </b-col>
                <b-col lg="10" sm="11" class="text-cover step2 ">
                  <div class="right-heading">
                    Step 2: &nbsp;&nbsp;Set Risk Factor Goals.
                  </div>

                  <div class="right-desc step2-para-text">
                    Learn your patient’s future risks if risk factors are improved. Try raising and lowering risk factor levels to see how their macro and microvascular risks can change.
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>



          <div class="common-row">
            <div class="about-box green-border">
              <b-row class="no-gutters">
                <b-col lg="2" sm="1" class="img-cover reduce-padding">
                  <div class="left-img3"></div>
                </b-col>
                <b-col lg="10" sm="11" class="text-cover step3">
                  <div class="right-heading">
                    Step 3:&nbsp;&nbsp;&nbsp;View Reccommendations.
                  </div>

                  <div class="right-desc">
                    See what your patient can do to reach their A1C target through lifestyle changes.
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="common-row">
            <div class="about-box red-border">
              <b-row class="no-gutters">
                <b-col lg="2" sm="1" class="img-cover reduce-padding">
                  <div class="left-img4"></div>
                </b-col>
                <b-col lg="10" sm="11" class="text-cover step4">
                  <div class="right-heading">
                    Step 4:&nbsp;&nbsp;&nbsp;Discuss the Results.
                  </div>

                  <div class="right-desc">
                    Print the summary report and discuss it with your patient.
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>


        </div>

        <div class="page2">

          <div class="main-heading comp-heading">
            Types of Complications:
          </div>


          <div class="body-sec">

            <b-row class="no-gutters">

              <b-col md="8" lg="9" class="reduce-padding">
                <b-row class="icons-row no-gutters">

                  <b-col cols="12" class="reduce-padding-sm">
                    <div class="images-main-div">

                      <b-col lg="2" class="hearattack-img   common-immg-margin">
                      </b-col>

                      <b-col lg="2" class="stroke-img common-immg-margin">
                      </b-col>

                      <b-col lg="2" class="blindness-img common-immg-margin">
                      </b-col>

                      <b-col lg="2" class="kidney-img common-immg-margin xs-row2-img">
                      </b-col>

                      <b-col lg="2" class="amputation-img common-immg-margin">
                      </b-col>
                    </div>

                    <div class="images-main-div-sm">

                      <b-row class="no-gutters img-row1">
                        <b-col cols="6" class="hearattack-img   common-immg-margin">
                        </b-col>

                        <b-col cols="6" class="stroke-img common-immg-margin">
                        </b-col>
                      </b-row>

                      <b-row class="no-gutters img-row2">
                        <b-col cols="4" class="blindness-img common-immg-margin">
                        </b-col>

                        <b-col cols="4" class="kidney-img common-immg-margin xs-row2-img">
                        </b-col>

                        <b-col cols="4" class="amputation-img common-immg-margin">
                        </b-col>
                      </b-row>
                    </div>
                    <div class="Txt-cmn"><span class="bold-txt">Many of the long-term complications of diabetes are due to high
blood sugar levels.</span><span class="double-spacing"></span>That’s why the most important thing your patient can do to protect his/her long-term health is to keep blood sugar (glucose), as well as blood pressure and lipids (such as cholesterol and triglycerides), as close
                      to goal as possible. </div>
                    <div class="Txt-cmn margin-top-para">
                      <div class="bold-txt">Over time, high blood sugar levels above your target can contribute to serious health problems, such as:</div>
                      <ul>
                        <li>Heart disease and stroke</li>
                        <li>Vision problems or blindness</li>
                        <li>Kidney failure</li>
                        <li>Nerve disease</li>
                        <li>Gum disease</li>
                      </ul>

                    </div>

                  </b-col>

                </b-row>
              </b-col>


              <b-col lg="3" md="4" class="reduce-padding">

                <div class="right-box">
                  <div class="txt">To complete this assessment, you need to know your patient’s:</div>
                  <div class="cmn-sec top-spacing">
                    <div class="a-image cmn-image"></div>
                    <div class="txt right-box-txt txt-sm">A1C blood sugar</div>
                  </div>
                  <div class="cmn-sec spacing">
                    <div class="b-image cmn-image"></div>
                    <div class="txt b-txt">Blood Pressure
                      <div class="brackett-text">(Systolic Only)</div>
                    </div>
                  </div>
                  <div class="cmn-sec spacing">
                    <div class="c-image cmn-image"></div>
                    <div class="txt right-box-txt">Cholesterol
                      <div class="brackett-text">(Total & HDL)</div>
                    </div>
                  </div>
                  <div class="green-txt">Remind your patient about the importance of knowing these values.</div>
                </div>
              </b-col>

            </b-row>

          </div>

        </div>

      </div>
    </b-row>
    <codNavigation v-bind:navButtons="['info','next','back']" v-bind:nextPage="'questionnaire'"></codNavigation>
  </b-col>
</b-row>
</template>

<script>
 /* eslint-disable */
  import codHeader from '../components/codHeader.vue';
  import codNavigation from '../components/codNavigation.vue';
  import scrollMixin from '../mixins/scrollMixin';
  import { mapGetters } from 'vuex';

export default {
  name: "home",
  components: {
      'codHeader': codHeader,
      codNavigation : codNavigation,
    },
    
  data() {
    return {
      pageTitle: 'About This Assessment',
      pageNumber: '1'
    };
  },
  
   computed: {
        ...mapGetters('userData', ['userData'])
    },
   mixins: [scrollMixin],
     mounted: function() {
      this.scrollToTop();
            this.userData.isIntendedUse =false;

    },
};
</script>


<style lang="scss" scoped>
  .left-img1 {
    background-image: url("../images/icon-svg/estimaterisk_logo.svg");
    background-repeat: no-repeat;
  }

  .left-img2 {
    background-image: url("../images/icon-svg/riskfactor_logo.svg");
    background-repeat: no-repeat;
  }


  .left-img3 {
    background-image: url("../images/icon-svg/recommendations_logo.svg");
    background-repeat: no-repeat;
  }


  .left-img4 {
    background-image: url("../images/icon-svg/doctor_logo.svg");
    background-repeat: no-repeat;
  }

  .hearattack-img {
    background-image: url("../images/icon-svg/heartattack_image.svg");
    background-repeat: no-repeat;
    display: inline-block;

  }

  .stroke-img {
    background-image: url("../images/icon-svg/stroke_image.svg");
    background-repeat: no-repeat;
    display: inline-block;

  }

  .blindness-img {
    background-image: url("../images/icon-svg/blindness_image.svg");
    background-repeat: no-repeat;
    display: inline-block;

  }

  .kidney-img {
    background-image: url("../images/icon-svg/kidneyfailure_image.svg");
    background-repeat: no-repeat;
    display: inline-block;

  }

  .amputation-img {
    background-image: url("../images/icon-svg/amputation_image.svg");
    background-repeat: no-repeat;
    display: inline-block;

  }

  .a-image {
    background-image: url("../images/icon-svg/a_image.svg");
    background-repeat: no-repeat;
  }

  .b-image {
    background-image: url("../images/icon-svg/b_image.svg");
    background-repeat: no-repeat;
  }

  .c-image {
    background-image: url("../images/icon-svg/c_image.svg");
    background-repeat: no-repeat;
  }
</style>
