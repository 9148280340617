<template>
 <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper-info2" :class="{'radio-display':btnClicked}" ref="infowrapper">
            <div class="modal-container-info">
              <div  class="overlay-inner-main2 info-overlay">
                  <div  id="info-div" ref="infoDiv" class="overlay-inner overlay-inner2 info-overlay">
                    <div class="">
                      <div class="info-text-div overlay-txt">
                        <b>A1C reduction with weight loss is calculated from:</b>
                        <br>Wing RR, Lang W, Wadden TA, et al. Benefits of modest weight loss in improving cardiovascular risk factors in 
                        overweight and obese individuals with type 2 diabetes. Diabetes Care. 2011;34:1481-1486.
                      </div>
                      <div class="info-img-div">
                        <div class="info-selected-img" @click="closeDiscussions"></div>
                      </div>
                    </div>
            
                    <div class="">
                      <div class="overlay-txt">
                        <b>A1C reduction with exercise is calculated from:</b>
                        <br>American Diabetes Association. Standards of medical care in diabetes-2020. Diabetes Care. 2020;43(suppl 1):S1-S212.
                      </div>
                      <div class="overlay-txt">
                        <i>Results may not be cumulative.</i>
                      </div>
                    </div>
                  </div>
                </div>
              
            </div>
          </div>
        </div>
      </transition>

</template>
<script>
/* eslint-disable */
 
  import scrollMixin from '../mixins/scrollMixin';
import taggingMixin from '../mixins/taggingMixin';
  import { mapGetters, mapMutations } from 'vuex';
  
export default {
  name: "modalmoreInfo",
  components: {
    },
  mixins: [scrollMixin, taggingMixin],
   mounted: function() {
      this.scrollToTop();
      this.userData.isGoalsPage= false;
     // listen for window size changes and update slider
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize);
  },
  
  data() {
    return {
     
    };
  },
  
  props: {
    dynamicMarginTop: {
        type: Number
    } ,
    btnClicked: {
      type: Boolean
    }
  },
  
  computed: {
      ...mapGetters('userData', ['userData']),
  },
  
  methods: {
     ...mapMutations('userData', ['saveUserData']),
        closeDiscussions: function() {
      this.userData.isInfoClicked = false;
      this.userData.isInfoClicked2 = false;
    },
    
    handleResize: function(event) {
      setTimeout(() => {
      this.$refs.infowrapper.style.marginTop= this.dynamicMarginTop+'px';
      }, 0);
    },

}
};
</script>

<style lang="scss" scoped>
.info-selected-img {
  background-image: url("../images/icon-svg/infoselected.svg");
 }
</style>
