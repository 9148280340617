<template>
  <b-row class="no-gutters">
    <b-col cols="12">
    
      <div class="graph-rec">
        <svg class="graph-dims" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 -5 472 277" style="enable-background:new 0 0 463 276;" xml:space="preserve">
        
        <!--graph border-->
        <path style="fill:#fff;stroke:#009FDA;stroke-width:0.9281;stroke-miterlimit:10;" d="M417.88,2.19h-406c-5.52,0-10,4.48-10,10v223
        h406c5.52,0,10-4.48,10-10V2.19z"/>
        
        <!--y-axis labels-->
        <g class="y-labels">
        <text style="fill:#001965; font-family:Sans,Verdana; font-weight:700; font-size:14px;" v-for="(line,index) in ylabelpos" v-bind:key="index" :y="line.y" :x="line.x">{{line.value}}%</text>
        </g>
        
        <!--graph grid lines-->
        <line v-for="(line,index) in gridlines" v-bind:key="index" :x1="line.x1" :x2="line.x2" :y1="line.y1" :y2="line.y2" style="fill:none;stroke:#E6E6E6;stroke-width:0.9866;stroke-miterlimit:10;"></line>
        
        <g class="x-labels">
        <text style="fill:#001965;font-family:Sans,Verdana;font-weight:700;font-size:14px;" 
        v-for="(line,index) in xlabelpos" v-bind:key="index" :y="line.y" :x="line.x" text-anchor="middle">
          {{line.value}}</text>
        </g>
        
        <g>
          <clipPath v-for="(bar,index) in adjBars" v-bind:key="index" :id="'clipgoals'+index">
            <rect :y="bar.y" :x="bar.x-1" :width="bar.width+2" :height="bar.height" />
          </clipPath>
        </g>
        <g>
          <clipPath v-for="(bar,index) in origBars" v-bind:key="index" :id="'cliprisk'+index">
            <rect :y="bar.y" :x="bar.x-1" :width="bar.width+2" :height="bar.height" />
          </clipPath>
        </g>
        <g>
        <rect v-for="(bar,index) in origBars" v-bind:key="index" :y="bar.y" :x="bar.x" :width="bar.width" :height="bar.height+15" style="fill:#f15a24;" rx="3px" ry="3px" :clip-path="'url(#cliprisk'+index+')'"/>
        </g>  
        <g >
          <rect  v-for="(bar,index) in adjBars" v-bind:key="index" :y="bar.y" :x="bar.x" :width="bar.width" :height="bar.height+15" style="fill:#1aa0d8;" rx="3px" ry="3px" :clip-path="'url(#clipgoals'+index+')'"/>
        </g>
        <g class="asterisk">
        <text v-if="isBlindnessSelected==true" :y="asterikLocation.y+1" :x="asterikLocation.x-1" text-anchor="middle" style="stroke:red;font-size:22px;">{{asterikLocation.value}}</text>
      </g>
        
         <g class="interval">
          <line stroke="#000" v-for="(l,index) in adjconfidenceIntervals" v-bind:key="index" :x1="l.x1" :x2="l.x2" :y1="l.y1" :y2="l.y2"></line>
         </g>
         <g class="interval">
            <line stroke="#000" v-for="(l,index) in adjconfidenceIntervals" v-bind:key="index" :x1="l.x1-5" :x2="l.x1+5" :y1="l.y1" :y2="l.y1"></line>
         </g>
         <g class="interval">
            <line stroke="#000" v-for="(l,index) in adjconfidenceIntervals" v-bind:key="index" :x1="l.x1-5" :x2="l.x1+5" :y1="l.y2" :y2="l.y2"></line>
        </g>      
         <g class="interval">
          <line stroke="#000" v-for="(l,index) in confidenceIntervals" v-bind:key="index" :x1="l.x1" :x2="l.x2" :y1="l.y1" :y2="l.y2"></line>
         </g>
         <g class="interval">
           <line stroke="#000" v-for="(l,index) in confidenceIntervals" v-bind:key="index" :x1="l.x1-5" :x2="l.x1+5" :y1="l.y1" :y2="l.y1"></line>
         </g>
         <g class="interval">
           <line stroke="#000" v-for="(l,index) in confidenceIntervals" v-bind:key="index" :x1="l.x1-5" :x2="l.x1+5" :y1="l.y2" :y2="l.y2"></line>
        </g>
        
        </svg>
      </div>
    
    </b-col>
  </b-row>
</template>
<script>
/* eslint-disable */

export default {
  name: "barGraph",
  components: {
    },

  data() {
    return {
      gridlines: [],
      xlabelpos: [],
      ylabelpos: [],
      asterikLocation: {},
      lblStartY: 0,
      lblEndY: 50,
      lblSkipY: 10,
      lblValuesX: ['Heart Attack', 'Stroke', 'Blindness', 'Kidney Failure', 'Amputation'],
      graphWidth: 416,
      graphHeight: 234.5,
      maxValue: 0,
      showcenter: false,
      adjconfidenceIntervals:[],
      confidenceIntervals: [],
      origBars: [],
      adjBars: [],
      lblMargin: 9,
    };
  },
  
  props: {
            BarValues: {
                type: Array
            },
            confValues: {
                type: Array
            },
            adjconfValues: {
                type: Array
            },
            displayBars: {
                type: Boolean
            },
            isBlindnessSelected: {
                type: Boolean
            }
          },
          
  watch: {
      BarValues: function(newVal, oldVal) {
        if(oldVal!==newVal) {
          this.generateYRange();
          this.createLabelsY();
          this.drawBars();
          }
        }
    },
  mounted() {
    this.generateYRange();
    this.createLabelsY();
    if(this.lblValuesX !== null) {
      this.createLabelsX();         
    }
    if(this.BarValues !== undefined){
      this.drawBars();
    }
  },
  methods: {
    generateYRange: function() {
        this.maxValue = parseFloat(this.BarValues[0][0]);
        for(var i=0; i<this.BarValues.length; i++) {
          for(var j=0; j<2; j++) {
            if(j === 0) {
              if(parseFloat(this.BarValues[i][j]) > this.maxValue) {
                this.maxValue = parseFloat(this.BarValues[i][j]);
              } 
              if(parseFloat(this.confValues[i][1]*100) > this.maxValue) {
                this.maxValue = parseFloat(this.confValues[i][1]*100);
              }
            } else {
              if(parseFloat(this.BarValues[i][j]) > this.maxValue) {
                this.maxValue = parseFloat(this.BarValues[i][j]);
              } 
              if (parseFloat(this.adjconfValues[i][1]*100)> this.maxValue) {
                this.maxValue = parseFloat(this.adjconfValues[i][1]*100);
              }
            }
          }
        }
          
        this.lblEndY = Math.ceil(this.maxValue / 10) * 10;
        
        if (this.maxValue <= 10) {
          this.lblEndY = 10;
          this.lblSkipY = 2;
        } else if(this.maxValue <= 20) {
          this.lblEndY = 20;
          this.lblSkipY = 5;
        } else if(this.maxValue <= 30) {
          this.lblEndY = 30;
          this.lblSkipY = 6;
        } else {
          this.lblSkipY = 10;
          if(this.maxValue > (this.lblEndY)) {
            this.lblEndY = this.lblEndY+this.lblSkipY;
          }
        }
      },
      
    createLabelsY: function() {
      this.ylabelpos=[];
        this.gridlines = [];
        var height = this.graphHeight;
        
        // no of y labels
        var l = (this.lblEndY - this.lblStartY) / this.lblSkipY;
        
        var labelHeight = (height) / (l);
        var y=this.graphHeight+2;
        for(var i=0; i<=l; i++) {
          var value = this.lblStartY + (i * this.lblSkipY);

          this.ylabelpos.push({
              x:this.graphWidth+this.lblMargin,
              y:y+4,
              value:value
              });
           
          if(value !== parseInt(this.lblEndY) && value !== parseInt(this.lblStartY)) {
            this.gridlines.push({
              x1:2, 
              x2:this.graphWidth, 
              y1:y, 
              y2:y
              });
          }
          y = y - labelHeight;
          
        }
    },
    
    createLabelsX: function() {
        
        var width = this.graphWidth;
        this.xlabelpos=[];
        
        var labelWidth= width/this.lblValuesX.length;
        var x=labelWidth/2;
        // Loop through the Labels 
        for(var i=0; i<this.lblValuesX.length; i++) {
          var spaceindex = this.lblValuesX[i].indexOf(' '); 
            if(spaceindex > 0) {
              this.xlabelpos.push({
                x:x,
                y:this.graphHeight+20,
                value:this.lblValuesX[i].substring(0,spaceindex)
                });
              this.xlabelpos.push({
                x:x,
                y:this.graphHeight+35,
                value:this.lblValuesX[i].substring(spaceindex,this.lblValuesX[i].length)
                });
            } else {
              this.xlabelpos.push({
                x:x,
                y:this.graphHeight+20,
                value:this.lblValuesX[i]
                });
            }
          x = x+labelWidth;     
        }
      },
    
    drawBars: function() {
      //        total graph width - 20 * no. of bars
      var width = this.graphWidth - (27 * (this.BarValues.length+1));
      var height = this.graphHeight;
      this.origBars = [];
      this.adjBars = [];
      this.confidenceIntervals = [];
      this.adjconfidenceIntervals = [];        
      var sectionWidth= this.graphWidth/(this.BarValues.length);
      var barWidth= 27;
      var sectionSpace=sectionWidth-2*barWidth;
      var y=this.graphHeight;
      var x = 1+sectionSpace/2;
      this.showcenter = false;
       
        //Loop through the Y values and create the bars 
        for(var i=0; i<this.BarValues.length; i++) {
          for(var j=0; j<2; j++) {
            var cHeight = 0;
                   
            if(!isNaN(this.BarValues[i][j])) {
              cHeight = ( this.BarValues[i][j] - this.lblStartY) * height / (this.lblEndY - this.lblStartY);
            }
            
            // upper limit chk    
            if(cHeight > this.graphHeight) {
              cHeight = this.graphHeight;
            }
            // lower limit chk
            if(cHeight < 0) {
              cHeight = 0;
            }
            
                   
            if (j===0) {
            
              //for confidence intervals
              var lowerbound = this.confValues[i][0]*100;
              var upperbound = this.confValues[i][1]*100;
              var lowery = ( lowerbound - this.lblStartY) * height / (this.lblEndY - this.lblStartY);
              var uppery = ( upperbound - this.lblStartY) * height / (this.lblEndY - this.lblStartY);
              
              if( i===2  && this.isBlindnessSelected === true) {
                this.displayAsterik = true;
                this.asterikLocation = {
                x:x+26,
                y:this.graphHeight,
                value:'*'
                };
              } else {
                this.origBars.push({
                x: x,
                y: (this.graphHeight-cHeight)<2.75?2.75:(this.graphHeight-cHeight),
                height: cHeight>231.8?231.8:cHeight,
                width: barWidth
                });
                this.confidenceIntervals.push(
                  {
                    x1: x+barWidth/2,
                    x2: x+barWidth/2,
                    y1: this.graphHeight-lowery>234?234:this.graphHeight-lowery<4?4:this.graphHeight-lowery,
                    y2: this.graphHeight-uppery>234?234:this.graphHeight-uppery<4?4:this.graphHeight-uppery
                  });
              }
            } else {
             
              //for confidence intervals
              var lowerboundadj = this.adjconfValues[i][0]*100;
              var upperboundadj = this.adjconfValues[i][1]*100;
              var loweryadj = ( lowerboundadj - this.lblStartY) * height / (this.lblEndY - this.lblStartY);
              var upperyadj = ( upperboundadj - this.lblStartY) * height / (this.lblEndY - this.lblStartY);
              
              if( i===2  && this.isBlindnessSelected === true) {
               
              } else {
               
              this.adjBars.push({
                x: x+barWidth,
                y:(this.graphHeight-cHeight)<2.75?2.75:(this.graphHeight-cHeight),
                height: cHeight>231.8?231.8:cHeight,
                width: barWidth
                });
                
                if(!isNaN(this.BarValues[i][j])) {
                  this.adjconfidenceIntervals.push({
                    x1: x+barWidth+barWidth/2,
                    x2: x+barWidth+barWidth/2,
                    y1: this.graphHeight-loweryadj>234?234:this.graphHeight-loweryadj<4?4:this.graphHeight-loweryadj,
                    y2: this.graphHeight-upperyadj>234?234:this.graphHeight-upperyadj<4?4:this.graphHeight-upperyadj
                  });
                 
              }
            }
            
          }
          
          } //j loop ends
          x = x+2*barWidth+sectionSpace;
        }
    },
      
  }
};
</script>


<style lang="scss" scoped>
</style>
