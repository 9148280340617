class UserData {
  constructor() {
    this.namespaced = true;
    this.state = {
        userData: {
          isIntendedUse:false,
          isGoalsPage:false,
          isImpDiscussionClicked: false,
          isInfoClicked: false,
          isInfoClicked2: false,
          isWeightNormal: false,
          looseWeight: false,
          patientProfile: {
            age: '',
            diagnoseAge: '',
            heightFeet: '',
            heightInches: '',
            height: '',
            gender: '',
          },
          conditions: {
            heartDisease: false,
            heartFailure: false,
            artrialFibrillation: false,
            peripheralDisease: false,
            blindness: false,
            vasculardisease: false,
            hypoglycemia: false,
            depression: false,
          },
          risks: {
            smoking: '',
            weight: '',
            exercise: '',
            hdl: '',
            totalCholestrol: '',
            bloodPressure: '',
            bloodSugar: '',
          },

          goals: {
            goalestimate: 'ten', //dropdown v-model
            goalsHdl: '',
            goalsTotalCholestrol: '200',
            goalsBloodPressure: '140',
            goalsBloodSugar: '7',
            goalsSmoking: 'No',
          },
          barChart: {
            diabBarChartValues: [],
            diabConfIntervaValues: [],
            diabadjConfIntervaValues: [],
            riskfiveyearsValues: [],
            risktenyearsValues: [],
            adjrisktenyearsValues: [],
            adjriskfiveyearsValues: [],
            adjconfIntervalfiveyear: [],
            adjconfIntervaltenyear: [],
            confIntervaltenyear: [],
            confIntervalfiveyear: [],
          },
          lifestyle: {
            loseWeight: '',
            willingExercise: '',
            changebloodSugarW: 0,
            changebloodSugarE: 0,
          }

        }

      },
      this.getters = {
        userData: state => {
          return state.userData;
        },
      },
      this.mutations = {
        saveUserData: (state, {data, section}) => {
          
          switch (section) {
            case 'patientProfile':
              state.userData.patientProfile = data;
              break;
            case 'conditions':
              state.userData.conditions = data;
              break;
            case 'risks':
              state.userData.risks = data;
              break;
            case 'goals':
              state.userData.goals = data;
              break;
            case 'barChart':
              state.userData.barChart = data;
              break;
            case 'lifestyle':
              state.userData.lifestyle = data;
              break;
          }
        },
      },

      this.actions = {
        saveUserData: ({ commit }, {data, section}) => {
          commit('saveUserData', {data, section});

        },
      };
  }
}

export default UserData;