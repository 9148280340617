import Home from '@/components/home.vue';
import IntendedUse from '@/components/intendedUse.vue';
import AboutThisAssessment from '@/components/aboutThisAssessment.vue';
import Questionnaire from '@/components/questionnaire.vue';
import Goals from '@/components/goals.vue';
import Lifestyle from '@/components/lifestyle.vue';
import SummaryPage from '@/components/summary.vue';
import MoreInfo from '@/components/moreInfo.vue';
import References from '@/components/references.vue';
import Email from '@/components/email.vue';

export default [
    { path: '/', component: Home },
    { path: '/intendedUse', name: 'intendedUse', component: IntendedUse },
    { path: '/aboutThisAssessment', name: 'aboutThisAssessment', component: AboutThisAssessment },
    { path: '/questionnaire', name: 'questionnaire', component: Questionnaire },
    { path: '/goals', name: 'goals', component: Goals },
    { path: '/lifestyle', name: 'lifestyle', component: Lifestyle },
    { path: '/summary', name: 'summaryPage', component: SummaryPage },
    { path: '/moreInfo', name: 'moreInfo', component: MoreInfo },
    { path: '/references', name: 'references', component: References },
    { path: '/email', name: 'email', component: Email },
];
