<template>
<b-row class="welcome-page no-gutters">

  <b-row class="welcome-header-main no-gutters">

    <b-row class="welcome-header-row head-background-gradient no-gutters">

      <div class="welcome-heading">
        Complications of Type 2 Diabetes
      </div>

      <div class="welcome-subtitle">
        A Risk Assessment Tool
      </div>

    </b-row>

  </b-row>

  <b-row class="welcome-body page-background-gradient no-gutters">

    <b-row class="descreption-row no-gutters">

      <b-row class="page-desc no-gutters">
        Estimate your patient’s 5&#8209;year and 10&#8209;year risk of diabetes-related health complications.
      </b-row>

    </b-row>

    <b-row class="icons-row no-gutters">

      <b-col cols="12" class="reduce-padding-sm">
        <div class="images-main-div">
          <b-col lg="2" class="hearattack-img common-immg-margin">
          </b-col>

          <b-col lg="2" class="stroke-img common-immg-margin">
          </b-col>

          <b-col lg="2" class="blindness-img common-immg-margin">
          </b-col>

          <b-col lg="2" class="kidney-img common-immg-margin xs-row2-img">
          </b-col>

          <b-col lg="2" class="amputation-img common-immg-margin">
          </b-col>
        </div>

      </b-col>

    </b-row>

    <b-row class="body-text-row no-gutters">

      <b-row class="body-text no-gutters">
        Then, identify some of the factors that may impact their risk and explain how lifestyle changes may lower their A1C.
      </b-row>

    </b-row>

    <div class="button-row">

      <div class="tag-div" @click="tagNavigation('getStarted', 'welcome')" >
        <router-link  class="getstarted-btn" :to="{path: '/intendedUse'}" exact>
        </router-link>
      </div>

    </div>

    <div class="footer-row">
      <b-row class="no-gutters">
        <b-col lg="3" md="2" sm="12" class="reduce-padding">
          <div class="privacy-txt">
            <a href="https://www.novonordisk-us.com/disclaimerprivacy.html" @click="tagNavigation('privacy', 'welcome')" target="_blank">PRIVACY POLICY</a>
          </div>
        </b-col>
      
        <b-col lg="6" md="8" sm="12" class="reduce-padding">
          <div class="footer-mid-txt">
            © 2020 Novo Nordisk<span class="double-spacing"></span>All rights reserved. <span class="single-spacing"></span>US20DI00040<span class="double-spacing"></span>September 2020
          </div>
        </b-col>

        <b-col lg="3" md="2" sm="12" class="reduce-padding">
          <div class="novo-logo">
          </div>
        </b-col>

      </b-row>
    </div>


  </b-row>

</b-row>
  
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import scrollMixin from '../mixins/scrollMixin';
import taggingMixin from '../mixins/taggingMixin';

  export default {
    name: "home",
    mixins: [scrollMixin, taggingMixin],
     mounted: function() {
      this.scrollToTop();
    },
    components: {},

    data() {
      return {};
    },

    computed: {
    ...mapGetters('navigationStore', ['getCurrentPage'])
    },
    methods: {
      ...mapMutations('navigationStore', ['updateCurrentIndex']),
        
    }
  };
</script>


<style lang="scss" scoped>
.novo-logo {
  background-image: url("../images/icon-svg/welcome_logo_image.svg");

}

.hearattack-img {
  background-image: url("../images/icon-svg/heartattack_image.svg");
  display: inline-block;

}

.stroke-img {
  background-image: url("../images/icon-svg/stroke_image.svg");
  display: inline-block;

}

.blindness-img {
  background-image: url("../images/icon-svg/blindness_image.svg");
  display: inline-block;

}

.kidney-img {
  background-image: url("../images/icon-svg/kidneyfailure_image.svg");
  display: inline-block;

}

.amputation-img {
  background-image: url("../images/icon-svg/amputation_image.svg");
  display: inline-block;

}

.getstarted-btn {
  border: 0;
  background-repeat: no-repeat;
  background-image: url(../images/icon-svg/getstarted_image.svg);
  display: block;
  height: 57px;
  margin: 0 auto !important;
  padding: 0;
  width: 242px;
}
</style>
