<template>
 <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">

              
                      <b-row class="cross-row no-gutters">
          <div class="cross-img" @click="closeDiscussions"></div>
        </b-row>
              <div class="heading-row">
          <b-row class="no-gutters">

            <b-col class="xs-display" md="1">
              <div class="imp-discussions-logo"></div>

            </b-col>

            <b-col md="11">

              <div class="overlay-heading-txt">
                Important Discussions to Have with Your Patient:
              </div>

            </b-col>

          </b-row>

        </div>

        <b-row lclass="no-gutters overlay-body-row">

          <ol>
            <li>
              Most adults with type 2 diabetes should engage in 150 minutes or more of moderate-to-vigorous activity per week (spread out over at least 3 days per week with no more than 2 consecutive days without activity).
            </li>
            <li>
              Shorter durations of vigorous-intensity or interval training may be sufficient for younger and physically fit individuals (minimum of 75 min/week).
            </li>
            <li>
              Adults with type 2 diabetes should engage in 2-3 sessions/week of resistance exercise on nonconsecutive days.

            </li>
            <li>
              All adults should decrease the amont of time in sedentary behavior. Prolonged sitting should be interrupted every 30 minutes.
            </li>
          </ol>

        </b-row>
              
            </div>
          </div>
        </div>
      </transition>

</template>
<script>
/* eslint-disable */
 
  import scrollMixin from '../mixins/scrollMixin';
import taggingMixin from '../mixins/taggingMixin';
  import { mapGetters, mapMutations } from 'vuex';
  
export default {
  name: "modalImpDisc",
  components: {
    },
  mixins: [scrollMixin, taggingMixin],
   mounted: function() {
      this.scrollToTop();
    },
  
  data() {
    return {
     
    };
  },
  
  computed: {
      ...mapGetters('userData', ['userData']),
  },
  
  created() {

    
  },
    
  methods: {
     ...mapMutations('userData', ['saveUserData']),
        closeDiscussions: function() {
      this.userData.isImpDiscussionClicked = false;
      this.userData.isInfoClicked = false;
    },

}
};
</script>

<style lang="scss" scoped>
.imp-discussions-logo {
  background-image: url("../images/icon-svg/importantdissussion_header_logo.svg");
}

.cross-img {
  background-image: url("../images/icon-svg/x_image.svg");
}
  
</style>
